import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import { datadogRum } from '@datadog/browser-rum';
import Editor from '../views/Editor.vue'
// import helpscout from "@/helpscout"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    // redirect: { name: 'Dashboard' }
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/Success.vue')
    // redirect: { name: 'Dashboard' }
  },
  {
    path: '/dash/editor/new',
    name: 'EditorNew',
    props: true,
    component: () => import('../views/EditorNew.vue')

  },
  {
    path: '/dash/editor/:slug?',
    name: 'Editor',
    component: Editor
  },
  {
    path: '/signup',
    name: 'Signup',
    props: { action: 'signup'},
    component: () => import(/* webpackChunkName: "home" */ '../views/Login.vue')
  },
  {
    path: '/login',
    name: 'Login',
    props: { action: 'login'},
    query: true,
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/login/forgot',
    name: 'Forgotpassword',
    props: { action: 'forgot'},
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/login/reset',
    name: 'Resetpassword',
    props: { action: 'reset'},
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/login/first',
    name: 'FirstLogin',
    props: { action: 'first'},
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/dash/',
    name: 'Dashboard',
    meta: { requireAuth: true },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/dash/editor/answers/:slug',
    name: 'EditorAnswers',
    component: () => import('../views/EditorAnswers.vue'),
    props: true
  },
  {
    path: '/dash/editor/settings/:slug',
    name: 'EditorSettings',
    props: true,
    component: () => import('../views/EditorSettings.vue')

  },
  {
    path: '/dash/editor/share/:slug',
    name: 'EditorShare',
    props: true,
    component: () => import('../views/EditorShare.vue')

  },
  {
    path: '/dash/preview/:slug',
    name: 'FormPreview',
    props: true,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/FormPreview.vue')

  },
  {
    path: '/dash/settings',
    name: 'userSettings',
    props: true,
    component: () => import('../views/userSettings.vue')
  },
  {
    path: '/dash/settings/upgrade',
    name: 'Upgrade',
    props: true,
    component: () => import('../views/Upgrade.vue')
  },
  {
    path: '/dash/settings/teams',
    name: 'UserTeams',
    props: true,
    component: () => import('../views/Teams.vue')
  },
  {
    path: '/dash/settings/team/:id',
    name: 'TeamsEdit',
    props: true,
    component: () => import('../views/TeamEdit.vue')
  },
  {
    path: '/dash/integrations/:slug',
    name: 'EditorIntegrations',
    props: true,
    component: () => import('../views/EditorIntegrations.vue')
  },
  {
    path: '/dash/export/:slug',
    name: 'ExportAnswers',
    props: true,
    component: () => import('../views/ExportAnswers.vue')
  },
  {
    path: '/admin',
    name: 'Users',
    props: true,
    component: () => import('../views/admin/Users.vue')
  },
  {
    path: '/admin/subscriptions',
    name: 'UsersSubscriptions',
    props: true,
    component: () => import('../views/admin/UserSubscriptions.vue')
  },
  {
    path: '/admin/:user_id',
    name: 'UserForms',
    props: true,
    component: () => import('../views/admin/UserForms.vue')

  },
  {
    path: '/404',
    name: '404',
    props: true,
    component: () => import('../views/404.vue')

  },
  {
    path: '/typeform-ou-respondi',
    name: 'typeformVsRespondi',
    props: true,
    component: () => import('../views/Page.vue')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    props: true,
    component: () => import('../views/MaintenanceMode.vue')
  },
  {
    path: '/:slug',
    name: 'FormView',
    props: true,
    component: () => import(/* webpackChunkName: "formView" */ '../views/FormView.vue')
    // component: () => import(/* webpackChunkName: "dashboard" */ '../views/FormView.vue')
    
  },
  {
    path: '*',
    name: 'not-found',
    props: true,
    component: () => import('../views/404.vue')

  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




router.beforeEach((to, from, next) => {

  if(process.env.VUE_APP_MAINTENANCE_MODE != "false" && to.name != "Maintenance" && to.name != "Home") next({ name: 'Maintenance', query: {redirect: to.fullPath} })
  
  if(isPublicRoute(to.name)){
    userMetrics(5)
    // trackPageview(to)
    next()
  } 

  else{
    isUserAuth()    
      .then((response) => {          
        const block = user.subscriptions?.status == "locked" ? true : false
        const allowedRoutes = ["userSettings", "Upgrade"]

        if(!response) return next({ name: 'Login'})
        if(response && block && !allowedRoutes.includes(to.name)) return next({ name: 'userSettings'})
        
        if(response){          
          trackPageview(to, Vue.prototype.$user)
          userMetrics(100)
          next()
        }
        
      })

      .catch((error) =>{
        next({ name: 'Login', query: {redirect: to.fullPath} })
      })
  }

  // next()

  
})


router.afterEach((to, from) => {
  if(!startClicksCountInt && user?.onboarding.first_day){
    startFirstDayMetrics()
    startClicksCountInt = true
  }

  if(!user?.onboarding.first_day){
    clearFirstDayMetric()
  }

  if(isCustomerRoute(to.name)){
    if(process.env.NODE_ENV != 'local') DD_RUM.startSessionReplayRecording()
    require('@/helpscout').default;
  }

  if(!isCustomerRoute(to.name)){
    require('@/fb-pixel').default; 
  }

  if(isPublicRoute(to.name)){
    trackPageview(to)
  } 

})

function userMetrics(sample = 100){
  datadogRum.init({
    applicationId: 'a0422641-63cf-4680-b66a-25ce1566144d',
    clientToken: process.env.VUE_APP_DD_TOKEN,
    site: 'datadoghq.com',
    service: 'Respondi.app',
    env: process.env.NODE_ENV,
  //  version: '1.0.0',
    sampleRate: sample,
    trackInteractions: true,
    silentMultipleInit: true,
    trackSessionAcrossSubdomains: true
  });
}


function trackPageview(to, user){
  if(user?.id){
    Vue.prototype.$trackEvent.mixpanel("Pageview", {auth: true, distinct_id: user.id, $email: user.email, path: to.fullPath, name: to.name});
    Vue.prototype.$mixpanel.people.set({ "$email": user.email, "$name": user.name, "role": user.role, "plan": user.plan })
    Vue.prototype.$mixpanel.identify(user.id)
    Vue.prototype.$trackUser.amplitude()
    if(to.name != 'FormView') Vue.prototype.$trackEvent.fb("244147437773313", "Pageview", {auth: true, distinct_id: user.id, $email: user.email, path: to.fullPath, name: to.name});
  }
  
  else{
    Vue.prototype.$trackUser.amplitude()
    Vue.prototype.$trackEvent.mixpanel("Pageview", {auth: false, distinct_id: null, path: to.fullPath, name: to.name});
    if(to.name != 'FormView') Vue.prototype.$trackEvent.fb("244147437773313", "Pageview", {auth: false, distinct_id: null, path: to.fullPath, name: to.name});
  }
}


function isUserAuth(){
    return whoAmI()
      .then((response) => {
        if(response.id){          
          setUserToken()
          setUserData(response)
          user = response
          return true
        }        
        return false
      })    
}


function whoAmI(){
  return axios.post(process.env.VUE_APP_API_URL + "auth/me")
  .then((response) => {
    if(response.data.id) return response.data
    else return null
  })
}


function setUserData(data){
  if(!data.subscriptions){
    data.subscriptions = {}
    data.subscriptions.plan_name = null
  } 

  Vue.prototype.$user = data;
}


function setUserToken(){
}


function isPublicRoute(routeName){  
  const publicRoutes = ['Home', 'Signup', 'Login', 'FirstLogin', 'Resetpassword', 'Forgotpassword', 'Success', 'FormView', 'typeformVsRespondi', '404'];
  return publicRoutes.includes(routeName);
}

function isCustomerRoute(routeName){  
  const customerRoutes = ['FormView', 'FormPreview'];
  if(customerRoutes.includes(routeName)) return false
  return true
}



function checkToken(){
  const token = localStorage.getItem('access_token')
  if (token) return token
  else return false 
}


/**
 * Start the clicks count if is the first day
 * for current user
 */

 let user
 let startClicksCountInt = false
 let currentFirstDayclicks = localStorage.user_clicks
 

 function startFirstDayMetrics(){  
   startClicksCount()
   startUpdateInterval()  
}


function startUpdateInterval(){

  setInterval(() => {

    if(currentFirstDayclicks == parseInt(localStorage.user_clicks)) return

    axios.put(process.env.VUE_APP_API_URL + "user/onboarding/" + user.id, {clicks: localStorage.user_clicks})
    currentFirstDayclicks = parseInt(localStorage.user_clicks)    

  }, 30000)
  
}

function startClicksCount(){
  document.addEventListener("click", function() {
    let clicks = localStorage.user_clicks ? localStorage.user_clicks : 0
    const newClicksNumber = parseInt(clicks) + 1
    localStorage.setItem('user_clicks', newClicksNumber)    
  });
}

function clearFirstDayMetric(){
  localStorage.removeItem('user_clicks')   
  currentFirstDayclicks = 0 
}

export default router
