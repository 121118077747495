<script>
import ActionRow from '../ActionRow.vue'
import Btn from '../form/Btn.vue'
import TagLabel from '../TagLabel.vue'
import TextField from '../form/TextField.vue'
import SwitchField from '../form/SwitchField.vue'
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';


    export default {
        components: { TextField, TagLabel, Btn, SwitchField, ActionRow },
        mixins: [UtilitiesMixin],
        name: "QuizModal",
        props: {
            form: Object,
            working: Boolean
        },

        data() {
            return {
                fields: {},
                rules: null,
            }
        },

        mounted () {
            this.fields = this.form.fields.filter(this.hasQuiz)
            this.rules = this.buildRulesObject(this.fields, this.form.scores)            
        },

        methods: {
            getMinMax(fieldSlug){                
                const items = Object.values(this.rules[fieldSlug]);
                if(items.length == 0) return {min: 0, max: 0}                
                return {min: Math.min(...items), max: Math.max(...items)}
            },

            hasQuiz(value) {
                // if(this.form.options.scores.active != true) return false
                // const hasQuiz = ["radio", "select"]
                if(this.questionHasOptions(value.type)) return true
                return false                
            },

            openItem(event){
                const element = event.srcElement.parentElement                
                let status = element.getAttribute("data-open")   
                status = (String(status).toLowerCase() == "true")             
                element.setAttribute("data-open", !status)
            },

            buildRulesObject(items, rules){
                const allRules = rules ?? {}
                let keys = {}
                items.forEach(item => {
                    keys[item.slug] = allRules[item.slug] ?? {}
                })
                return keys
            },


            updateScores(){                
                this.$emit("update-scores", this.rules)
                this.$trackEvent.mixpanel("Feature: Quiz", {distinct_id: this.form.cus, form_id: this.form.slug})
            }
        },
    }
</script>

<template>
    <div class="quiz-modal-comp">
        <ActionRow title="Habilitar pontuação" description="Atribua uma pontuação para cada resposta enviada.">
            <SwitchField v-model="form.options.scores.active" :active="form.options.scores.active" />
        </ActionRow>
        
        <div v-if="rules && form.options.scores.active" class="fields-list">

            <!-- Question -->
            <div v-for="field in fields" :key="field.key" class="field-item" data-open="false">
                <div @click="openItem($event)" class="field-header">
                    <h3 class="field-title">{{field.value}}</h3>
                    <TagLabel type="beta">{{getMinMax(field.slug).min + ' → ' + getMinMax(field.slug).max}} pontos</TagLabel>
                </div>
                
                <!-- Options -->
                <div class="field-options-list">
                    <div v-for="option in field.items" :key="option.key" class="field-option-item">
                        <div class="field-option-label">{{option.label}}</div>
                        <div class="field-option-score"><TextField v-model="rules[field.slug][option.key]" type="number" :data="{}" size="sm" placeholder="0" /></div>
                    </div>
                </div>

            </div>
        </div>
        <Btn @click.native="updateScores()" size="sm" class="mt32" :loading="working">Salvar</Btn>
    </div>
</template>


<style lang="sass" scoped>
.quiz-modal-comp
    color: $cBody
    overflow: auto
    max-height: 500px


    .fields-list
        display: flex
        flex-direction: column

        .field-item
            border-bottom: 1px solid $cG2

            &[data-open="true"]
                .field-options-list
                    display: block

        .field-header
            padding: $mgSm 0
            display: flex
            justify-content: space-between

            &:hover
                cursor: pointer
                background: $cG1

        .field-title
            font-weight: normal


    .field-options-list
        display: none
        background: $cG1
        border-bottom: 1px solid $cG3
        
        .field-option-item
            display: flex
            justify-content: space-between
            align-items: center
            padding: $mgXxs $mgSm

            &:hover
                background: $cG2

            .field-option-label
                width: 300px

            .field-option-score
                width: 65px


    


</style>