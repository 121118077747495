const axios = require('axios').default;


export const FormMixin = {

    data: function() {
        return {
            form: null,
            slug: this.$route.params.slug,
            formLoaded: false,
            sending: false,
            working: {
              updateform: false,
              storeform: false,
              getform: false,
              moveform: false,
              export: {
                xlsx: false,
                csv: false,
              },
              getanswers: false,
              deleteAnswer: false,
              progress: {formBackground: 0},
              uploadProgress: null,
            },
        };
    },

    mounted: function() {      
      
        // Get form data
        if(this.slug){
            this.getForm(this.slug);
        }
    },


    computed: {
        cssVars() {
          const bgImage = this.fixImagePath(this.form?.style?.background)
          const logoImage = this.fixImagePath(this.form?.style?.logo)         
        
          return {
            '--formBgImage': 'url(' + bgImage + ')',
            '--formLogoImage': 'url(' +  logoImage + ')',
            '--formColor': this.form.style.color,
            '--formColorAlpha': this.hexToRGB(this.form.style.color),
            '--formColorText': this.hexToRGB(this.form.style.textColor),
            '--formColorQuestion': this.hexToRGB(this.form.style.questionColor),
            '--formColorBackground': this.form.style.bgColor,
          }
        }
      },

    methods: { 

        fixImagePath(src){
          // :::: Corrige primeira implementação de imagem sem upload
          if(typeof src === 'string') return src
          if(!src.hasOwnProperty("path") || src.path == null ) return null
          if(src.path.includes("http")) return src.path
          return src ? JSON.stringify(this.$bucket + src.path) : null
        },

        hexToRGB(h) {

          if(!h) return null

          let r = 0, g = 0, b = 0;
        
          // 3 digits
          if (h.length == 4) {
            r = "0x" + h[1] + h[1];
            g = "0x" + h[2] + h[2];
            b = "0x" + h[3] + h[3];
        
          // 6 digits
          } else if (h.length == 7) {
            r = "0x" + h[1] + h[2];
            g = "0x" + h[3] + h[4];
            b = "0x" + h[5] + h[6];
          }
          
          return +r + "," + +g + "," + +b;
        },



        getForm(slug, redirectOnFail = false){
            this.working.getform = true;
            return axios
            .get(process.env.VUE_APP_API_URL + 'form/' + slug)
            .then(response => {   
                // this.form = response.data;
                const form = this.addDefaultValues(response.data)
                this.form = form
                this.formLoaded = true;
                this.working.getform = false;
                return form
            })            
            .catch((error) => {
              if(redirectOnFail) this.$router.push({name: "404"})
              else console.log(error)
            })

        },

        addDefaultValues(form){

          // Corrige antigo formato de logotipo somente com a url
          if(typeof form.style.logo === 'string'){
            const data = {
              path: form.style.logo,
              position: "left"
            }
            form.style.logo = data
          } 
          if(!form.style.logo) form.style.logo = {}

          // Corrige antigo formato de background somente com a url
          if(typeof form.style.background === 'string'){     
            form.style.background = {path: form.style.background}
          } 
          if(!form.style.background) form.style.background = {}

          // Adiciona opção de posição no logo
          if(!form.style.hasOwnProperty('logo')) form.style.logo = {}
          if(!form.style.logo.hasOwnProperty('position')) form.style.logo.position = "left"

          // Adiciona novas opções de cores no formulário
          if(!form.style.hasOwnProperty('color') || form.style.color == null) form.style.color = "#2979FF"
          if(!form.style.hasOwnProperty('questionColor')) form.style.questionColor = form.style.color
          if(!form.style.hasOwnProperty('textColor')) form.style.textColor = "#263238"
          if(!form.style.hasOwnProperty('bgColor')) form.style.bgColor = "#ffffff"
          
          
          // Adiciona opção de remover o logo da Respondi
          if(!form.style.hasOwnProperty('removeBrand')) form.style.removeBrand = false


          // Adiciona opções de notificação
          if(!form.notifications) form.notifications = {owner: true, respondent: false}
          if(!form.notifications.hasOwnProperty('webhook')) form.notifications.webhook = {active: false, urls: []}
          if(!form.notifications.hasOwnProperty('emailForward')) form.notifications.emailForward = {active: false, value: null}
          
          // Adiciona opções de integração FB + GA
          if(!form.apps) form.apps = {fbPixel: {active: false, value: null}, gAnalytics: {active: false, value: null}}
          if(!form.apps.hasOwnProperty('pluga')) form.apps.pluga = {active: false, value: null}

          // Adiciona opções de form fechado
          if(!form.style.hasOwnProperty('closed')) form.style.closed = {title: "Opa, este formulário não está recebendo respostas no momento", description: "Você pode tentar novamente mais tarde, ou entrar em contato com o dono do formulário", attachment: null}

          // Adiciona opções gerais do form
          // if(!form.options) form.options = { scores: {active: false }}
          
          form.fields.forEach(item => {

            // Adiciona opção de lógica
            if(!item.hasOwnProperty('logic')) item.logic = {active: false, actions: []}            
            
            // Adiciona as options
            if(!item.hasOwnProperty('options')) item.options = {}            

            // Adiciona opção de minmax
            if(!item.options.hasOwnProperty('minmax')) item.options.minmax = {active: false, min: null, max: null}            
            
            // Adiciona opção de somente inteiros
            if(!item.options.hasOwnProperty('onlyInteger')) item.options.onlyInteger = {active: false}            
            
            // Adiciona opções de final de formulário (redirect e upload)
            if(!item.options.hasOwnProperty('endForm')) item.options.endForm = {type: ["message"], file: null, redirect: null, showScore: false}
            if(!item.options.endForm.hasOwnProperty('showScore')) item.options.endForm.showScore = false // 2022/03/08

            // Adiciona opções de "outros" em múltipla escolha
            if(!item.options.hasOwnProperty('allowOtherValue')) item.options.allowOtherValue = false

            // Adiciona maxCharacters
            if(!item.options.hasOwnProperty('maxCharacters')) item.options.maxCharacters = {active: false, value: null}

          })

          return form
        },

        
        // Update data
        updateForm(formData){
            this.working.updateform = true;
            this.sending = true;
            const validFields = this.validateFormUpdate(formData)
            return axios
                .put(process.env.VUE_APP_API_URL + 'form/' + formData.slug, validFields)                
                .then(response => {
                    this.status = "Formulário atualizado.";
                    this.sending = false;
                    this.working.updateform = false;
                    return response
                })            
                .catch(error => console.log(error))
        },


        // Transfer Form Team
        moveFormToTeam(form, newTeamId){
            return axios
                .put(process.env.VUE_APP_API_URL + 'form/transfer/' + form.slug, { team_id: newTeamId })                
                .then(response => {
                    return response
                })            
                .catch(error => console.log(error))
        },




        // Duplicate Form
        duplicateForm(form){
            return axios
                .post(process.env.VUE_APP_API_URL + 'form/duplicate/' + form.slug)                
                .then(response => {
                    return response
                })            
                .catch(error => console.log(error))
        },



        validateFormUpdate(formData){   
          formData.fields = this.addDefaultValueWhenEmpty(formData.fields)
          return formData
        },



        addDefaultValueWhenEmpty(fields){
          let validFields = []
          fields.forEach((field) => {
            if(field.value == "" || field.value == null) field.value = "Adicione uma pergunta..."
            validFields.push(field)
          })
          return validFields
        },


        uploadAsset(file, name){
          this.uploadFile(file, name)
          .then(response => {
            if(name == "formBackground"){
              const fileData = {path: response.data.path, id: response.data.id, size: response.data.size}
              this.form.style.background = fileData
            }
            this.updateForm(this.form)
            .then(response => {
              return response
            })
          })
        },


        // Create form
        storeForm(formData){
          this.working.storeform = true;
            axios
                .post(process.env.VUE_APP_API_URL + 'form', formData)
                .then(response => {
                    this.id = response.data.slug;
                    this.status = "Formulário criado.";
                    this.working.storeform = false;
                    this.$trackEvent.mixpanel("Create form", {slug: response.data.slug})
                    this.$router.push({name: 'Editor', params: {slug: response.data.slug}}).catch(err => {})
                })            
                .catch(error => console.log(error))
        },

        destroyForm(formData){
          axios
            .delete(process.env.VUE_APP_API_URL + 'form/' + formData.slug)
            .then(response => {
                this.$trackEvent.mixpanel("Delete form", {slug: formData.slug})
                this.$router.push({name: 'Dashboard'})
            })
      },

        // Create a default form
        createForm(name){
            let formData = {
                "name": name,
                "style": {
                  "background": null,
                  "logo": null,
                  "color": "#2979FF",
                  "textColor": "#263238",
                  "bgColor": "#ffffff",
                },
                "notifications": {
                  "owner": true,
                  "respondent": false,
                  "webhook": {"active": false, "urls": []}
                },
                // "options": null,
                "scores": null,
                "fields": [
                    {
                      "type": "text",
                      "value": null,
                      "description": [
                        null
                      ],
                      "items": [],
                      "position": 1,
                      "validation": {
                        "required": false
                      }
                    },
                    {
                      "type": "thankyou",
                      "value": "Obrigado por participar!",
                      "description": [
                        "Sua opinião é muito importante pra gente."
                      ],
                      "items": [],
                      "position": 2,
                      "validation": {
                        "required": false
                      }
                    },
 
                  ]
            }

            this.storeForm(formData);
        },

        saveFormFromEditor(){
          this.updateForm(this.form)
        },

        showPreview(form){
          this.updateForm(form)
          .then((response) => {
              this.$router.push({ name: 'FormPreview', params: { slug: form.slug, isInternal: true }})
          })
      },

      

    }

}