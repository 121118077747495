<template>
  <div class="field-container field-comp" :key="data.slug">

        <div v-if="isQuestion(data.type)" class="position">{{data.position}}. </div>

        <label v-if="isQuestion(data.type)" class="field-label" :for="data.id">           
            <TextFieldEditor v-if="editable" :mentionTip="true" class="field-label editable" type="text" v-model="data.value" :value="data.value" :slug="data.slug" :key="'title-' + data.slug + data.type" :form="form" placeholder="Faça sua pergunta aqui..." />
            <QuestionTitle v-else :value="data.value" :variables="variables" :key="'title-' + data.slug" class="mb8" />
        </label>


        <div v-if="!isQuestion(data.type) && data.type != 'thankyou'" class="field-label" :for="data.id">            
            <TextFieldEditor v-if="editable" :mentionTip="true" class="field-label editable mt32" type="text" v-model="data.value" :value="data.value" :slug="data.slug" :form="form" placeholder="Faça sua pergunta aqui..." />
            <QuestionTitle v-else :value="data.value" :variables="variables" :key="'title-' + data.slug" size="lg" class="title-xl form-title mb16" />
            <TextareaField v-if="data.description && editable" v-model="data.description[0]" :data="{slug: 'main-description-field'}" :contentMode="true" :placeholder="customPlaceholder(data.type)" class="description description-message end-description editable" :editable="true" />            
            <p v-else v-for="paragraph in data.description" :key="paragraph" class="description description-message" >{{paragraph}}</p>
        </div>

        <div v-if="isQuestion(data.type)" class="description-container">
            <TextareaField v-if="editable" :data="{ slug: 'description-field'}" :placeholder="customPlaceholder(data.type)" v-model="data.description[0]" :editable="true" class="editable mb8" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" :key="'title-' + data.slug + data.type" />
            <p v-if="data.description[0] && editable != true" class="description mb16">{{data.description[0]}}</p>
        </div>

        <!-- Text field -->
        <div class="field-item" v-if="data.type == 'text'">
            <TextField v-if="editable" :data="data" :noResize="true" layout="big" placeholder="Uma resposta simples..." class="editor-field" />
            <TextField v-else :data="data" :value="value" layout="big" v-on:input="updateValue" v-on:input-focus="isActive" :isFocus="isCurrent" :error="validateMessage" />
        </div>

        <!-- Number field -->
        <div class="field-item" v-if="data.type == 'number'">
            <NumberField v-if="editable" :data="data" layout="big" placeholder="Um número..." class="editor-field" />
            <NumberField v-else :data="data" :value="value" layout="big" v-on:update:value="updateValue" v-on:input-focus="isActive" :isFocus="isCurrent" :error="validateMessage" />
        </div>

        <!-- Url field -->
        <div class="field-item" v-if="data.type == 'url'">
            <TextField v-if="editable" :data="data" layout="big" placeholder="https://exemplo.com" class="editor-field" />
            <TextField v-else :data="data" :value="value" placeholder="https://exemplo.com" layout="big" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" />
        </div>

        <!-- Email Email -->
        <div class="field-item" v-if="data.type == 'email'">
            <TextField v-if="editable" :data="data" layout="big" placeholder="exemplo@exemplo.com" class="editor-field"/>
            <TextField v-else :data="data" :value="value" layout="big" v-on:update:value="updateValue" v-on:input-focus="isActive" placeholder="exemplo@exemplo.com" :error="validateMessage" />
        </div>

        <!-- CPF field -->
        <div class="field-item" v-if="data.type == 'cpf'">
            <TextField v-if="editable" :data="data" layout="big" placeholder="000.000.000-00" class="editor-field" />
            <DocumentField v-else v-model.trim="value" format="cpf" layout="big" :data="data" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" /> 
        </div>

        <!-- CNPJ field -->
        <div class="field-item" v-if="data.type == 'cnpj'">
            <TextField v-if="editable" :data="data" layout="big" placeholder="00.000.000/0000-00" class="editor-field" />
            <DocumentField v-else v-model.trim="value" format="cnpj" layout="big" :data="data" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" /> 
        </div>

        <!-- Date field -->
        <div class="field-item" v-if="data.type == 'date'">
            <DateField :data="data" :value="value" layout="big" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" />
        </div>

        <!-- Phone field -->
        <div class="field-item" v-if="data.type == 'phone'">
            <PhoneField :data="data" :value="value" layout="big" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" />
        </div>

        <!-- Textarea field -->
        <div class="field-item" v-if="data.type == 'textarea'">            
            <TextareaField v-if="editable" :data="data" layout="big" :error="validateMessage" placeholder="Uma resposta longa..." />
            <TextareaField v-else :data="data" :value="value" layout="big" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" />
        </div>

        <!-- Upload field -->
        <div class="field-item" v-if="data.type == 'upload'">
            <FilesListFieldEditor v-if="editable" layout="big" />
            <FilesListField v-else :form="form" layout="big" :error="validateMessage" v-on:update:value="updateValue" v-on:input-focus="isActive" />
        </div>

        <!-- Select field -->
        <div class="field-item" v-if="data.type == 'select'">
            <SelectField v-if="!editable" :data="data" layout="big" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" />
            <SelectFieldEditor v-if="editable" :data="data.items" layout="big" />
        </div>

        <!-- Radio button -->
        <div class="field-item" v-if="data.type == 'radio'">
            <RadioFieldEditor v-if="editable" :data="data.items" :inline="data.options.multiplesPerLine" :showOther="data.options.allowOtherValue" :selected="optionSelected" :multi="data.options.multipleAnswer" :edit="editable" />
            <RadioField v-else :data="data" :inline="data.options.multiplesPerLine" :selected="optionSelected" :showOther="data.options.allowOtherValue" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" />
        </div>

        <!-- Image Radio button -->
        <div class="field-item" v-if="data.type == 'imageradio'">
            <ImageRadioFieldEditor v-if="editable" :form="form" v-model="data.items" :data="data.items" @save-form="saveForm" :edit="editable"/>
            <ImageRadioField v-else :data="data.items" :multi="data.options.multipleAnswer" :showOther="data.options.allowOtherValue" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" />
        </div>

        <!-- Address Field -->
        <div class="field-item" v-if="data.type == 'address'">
            <AddressField v-if="editable" :edit="editable" :data="data" />
            <AddressField v-else :data="data" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" />
        </div>

        <!-- Rating button -->
        <div class="field-item" v-if="data.type == 'rating'">
            <RatingField :data="data" :selected="optionSelected" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" />
        </div>

        <!-- Legaltext -->
        <div class="field-item" v-if="data.type == 'legaltext'">
            <div class="submit-button">
                <div v-if="editable" class="row">
                    <Btn :formStyle="form.style" layout="big"  class="mr16"><Icon name="check"/> Eu aceito</Btn>
                    <Btn type="secondary" layout="big" :formStyle="form.style"><Icon name="times"/> Não aceito</Btn>
                </div>
                <div v-else class="row">
                    <LegalTextField v-on:update:value="updateValue" :formStyle="form.style" :error="validateMessage" :loading="working.submitAnswer" />          
                </div>
            </div>  
        </div>

        <!-- Message -->
        <div class="field-item" v-if="data.type == 'message'">
            <div class="submit-button">
                <BtnEditor v-if="editable" v-model="fieldCta" :label="fieldCta" :formStyle="form.style" icon="start" />
                <Btn v-else @click.native="submit()" layout="big" :formStyle="form.style">{{fieldCta}} →</Btn>
            </div>            
        </div>

        <!-- Welcome -->
        <div class="field-item" v-if="data.type == 'welcome'">
            <div class="submit-button">
                <BtnEditor v-if="editable" v-model="fieldCtaStart" :label="fieldCtaStart" :formStyle="form.style" key="start" icon="start" />
                <Btn v-else @click.native="submit()" layout="big" :formStyle="form.style">{{fieldCtaStart}} →</Btn>
            </div>            
        </div>

        <!-- Thankyou Page -->
        <div class="field-item" data-type="thankyou" v-if="data.type == 'thankyou'">


            <!-- Endform Message -->
            <div v-if="editable" class="endform endform-message">
                <div v-if="data.options.endForm.type == 'redirect_first'" class="redirect mt64">
                    <p class="redirect-label">O visitante será redirecionado <br>automaticamente para o link:</p>
                    <a :href="data.options.endForm.redirect" target="_blank" class="redirect-url mt16">{{data.options.endForm.redirect}}</a>
                </div>
                <div v-else class="end-message">
                    <div v-if="data.options.endForm.showScore" class="score-block">
                        <div class="score-value mb4">0</div>
                        <div class="score-label">pontos</div>
                    </div>
                    <div v-else class="end-icon"><Icon type="solid" name="paper-plane" /></div>
                    <TextFieldEditor v-if="editable" class="field-label editable mt32" type="text" v-model="data.value" :value="data.value" :slug="data.slug" :form="form" align="center" placeholder="Faça sua pergunta aqui..." />
                    <TextareaField v-if="data.description && editable" v-model="data.description[0]" :data="{slug: 'main-description-field'}" placeholder="Se desejar, adicione uma descrição..." class="description description-message end-description editable" :editable="true" />                
                    <div class="submit-button">
                        <BtnEditor v-if="editable && data.options.endForm.type != 'message'" v-model="fieldCtaEnd" :label="fieldCtaEnd" :formStyle="form.style" key="end" icon="end" />
                    </div>
                </div>
            </div>


        </div>



        <!-- Submit button -->
        <div v-if="editable != true && data.type != 'thankyou'" :class="{ noActive: !active }">            
            <Btn v-if="isLast" @click.native="submit()" layout="big" :formStyle="form.style" :loading="working.submitAnswer"><Icon name="check"/> Enviar respostas</Btn>
            <Btn v-else @click.native="submit()" layout="big" :formStyle="form.style" :loading="working.submitAnswer">Responder</Btn>
        </div>


  </div>

</template>


<script>
import LegalTextField from './form/LegalTextField.vue'
import ImageRadioFieldEditor from './form/ImageRadioFieldEditor.vue'
import ImageRadioField from './form/ImageRadioField.vue'
import DocumentField from './form/DocumentField.vue'
import PhoneField from './form/PhoneField.vue'
import AddressField from './form/AddressField.vue'
import NumberField from './form/NumberField.vue'
import DateField from './form/DateField.vue'
import QuestionTitle from './preview/QuestionTitle.vue'
import TextFieldEditor from './form/TextFieldEditor.vue'
import FilesListFieldEditor from './form/FilesListFieldEditor.vue'
import FilesListField from './form/FilesListField.vue'
import BtnEditor from './form/BtnEditor.vue'
import RadioFieldEditor from './form/RadioFieldEditor.vue'
import TextField from '@/components/form/TextField.vue';
import SelectField from '@/components/form/SelectField.vue';
import SelectFieldEditor from '@/components/form/SelectFieldEditor.vue';
import TextareaField from '@/components/form/TextareaField.vue';
import RadioField from '@/components/form/RadioField.vue';
import RatingField from '@/components/form/RatingField.vue';
import Btn from '@/components/form/Btn.vue';
import Icon from '@/components/Icon.vue';
import { ValidateMixin } from '@/mixins/ValidateMixin.js';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin.js';
import { datadogLogs } from '@datadog/browser-logs'
import score from './preview/helpers/scoreHelper'

const axios = require('axios').default;


export default {
  name: 'Field',
    components: {
      TextField,
      SelectField,
      SelectFieldEditor,
      TextareaField,
      RadioField,
      RatingField,
      Btn,
      Icon, RadioFieldEditor, BtnEditor, FilesListField, FilesListFieldEditor, TextFieldEditor, QuestionTitle, DateField, NumberField, AddressField, PhoneField, DocumentField, ImageRadioField, ImageRadioFieldEditor, LegalTextField
  },
  mixins: [ValidateMixin, UtilitiesMixin],
  props: {
    data: Object,
    variables: Object,
    type: String,
    editable: Boolean,
    isCurrent: {
        type: Boolean, 
        default: false
    }
  },

  data: function(){
      return {
          value: '',
          valueKey: '',
          active: false,
          isUpdate: false, 
          isValid: null, 
          isLast: false,
          optionSelected: null,
          validateMessage: null,
          working: {
              submitAnswer: false
            },

          answer: {
              "form_id": this.$route.params.slug,
              "field_title": this.data.value,
              "field_slug": this.data.slug,
              "field_type": this.data.type,
              "public_user_id": localStorage.uuid,
              "value": this.value,
              "value_key": this.valueKey
          }
      }
  },

  computed: {
      fieldCta: {
          get(){
              if(this.data?.options?.cta) return this.data.options.cta
                else return "Continuar"
          },
          set(value){
              this.data.options.cta = value
          }
      },
      fieldCtaStart: {
          get(){
              if(this.data?.options?.cta_start) return this.data.options.cta_start
                else return "Começar"
          },
          set(value){
              this.data.options.cta_start = value
          }
      },
      fieldCtaEnd: {
          get(){
            if(this.data?.options?.cta_end) return this.data.options.cta_end
            if(this.data?.options?.endForm.type == 'redirect') return "Acessar"
            if(this.data?.options?.endForm.type == 'file') return "Baixar arquivo"
            else return "Enviar"
          },
          set(value){
              this.data.options.cta_end = value
          }
      },

      form(){
          return this.$parent.form
      },

      hasScore(){
          return this.form.options.scores.active
      }

  },


  watch: {
      isCurrent(value) {
          if(value) this.isLast = this.isLastQuestion()          
      }
  },

  methods: {    
      
      customPlaceholder(type){
          if(type == "legaltext") return "Adicione os termos de uso ou outro texto legal aqui...\n.......\n.....\n..."
          return "Se desejar, adicione uma descrição..."
      },

      saveForm(){
        this.$emit('save-form')
      },

      isLastQuestion(){
          if(this.editable) return false
          if(this.$parent.form.fields.length == this.$parent.current_field + 1) return true
          const next = this.$parent.findNextQuestion(this.value)
          if(this.$parent.form.fields[next].type == "thankyou") return true
          return false
      },

      updateValue(answerValue, answerKey, submitOnUpdate){
          this.value = answerValue;
          this.answer.value = answerValue;
          this.valueKey = answerKey;
          this.answer.value_key = answerKey;
          this.isLast = this.isLastQuestion()
          this.$emit('new-variable', {slug: this.data.slug, value: answerValue, type: this.data.type})
          
          if(submitOnUpdate) this.submit()
      },


      
      /**
       * If is not a question, go to next field.
       * Else, validate and Submit the answer to API
       * or move to next in preview mode.
       */
      async submit(){        

        if(!this.isCurrent || this.working.submitAnswer) return null        

        if(!this.isQuestion(this.data.type)){
            this.nextAction(this.data.type);
            return false;
        }               

        let validate = this.validate(this.data.options)
        this.addValidationMessage(validate);        
        
        if(validate){

            if(!this.$parent.demo && !this.value && this.isLast) await this.goNextIfIsLastAndEmpty()
            if(this.hasScore) this.updateCurrentScore()
            if(this.value) this.answer.value = this.format(this.answer.value, this.data.type)
            if(this.$parent.demo || !this.value) return this.goNext()

            return this.submitQuestion()
        }  

        this.optionSelected = null;
        return false;
          
      },



    /**
     * if is the last question, but there is no answer, make a request
     * to force to complete the form and get the final score.
     * See: readme.md/#score
     */

      async goNextIfIsLastAndEmpty(){
            const complete = await this.forceCompleteForm(this.hasScore)
            
            if(this.hasScore){
                this.updateFinalScore(complete.data.data.total_score)
                this.updateCurrentScore()
            }

            return this.goNext()
      },



    /**
     * If the last question is empty,
     * force the form to be completed
     */
    async forceCompleteForm(hasScore){
        this.working.submitAnswer = true
        const respondent = await this.$axios.put(process.env.VUE_APP_API_URL + 'respondent/complete/' + localStorage.uuid, {request_score: hasScore})
        this.working.submitAnswer = false
        return respondent
    },


    /** 
     * Check if current question has a score rule, and $emit
     * an event with corret points to add to currentScore.
     */
    updateCurrentScore(){
        const scoreToAdd = score.getAnswerScore(this.data.slug, this.valueKey, this.form.scores)
        if(scoreToAdd) this.$emit("update-score", {points: scoreToAdd, questionSlug: this.data.slug})          
    },


    /**
     * :: getCurrentUtms ::
     * .........................
     * Get all utms params from the current url and return an array     * 
     */
    getCurrentUtms(){
        const query = this.$route.query
        return {
            utm_source: query.utm_source ?? null,
            utm_medium: query.utm_medium ?? null,
            utm_campaign: query.utm_campaign ?? null,
            utm_term: query.utm_term ?? null,
            utm_content: query.utm_content ?? null,
        }
    },


      submitQuestion(){  
        
        this.working.submitAnswer = true  
        
        // Se for a última questão, informa a API pra gerar o score
        if(this.isLast) this.answer.is_last = true        

        // If form scores is active, request total from API
        if(this.form.options.scores.active) this.answer.request_score = true        
          
        if(!this.isUpdate){

            // Check if should add the utms, then add to payload
            if(this.$parent.isFirst && this.form.options.utms.active){
                const utms = this.getCurrentUtms()
                this.answer = {...this.answer, ...utms}
            }

            // Se for o primeiro envio, informa a API para salvar o ID
            if(this.$parent.isFirst){
                this.answer.is_first = true
                this.$parent.isFirst = false
            }

            axios
            .post(process.env.VUE_APP_API_URL + 'answer', this.answer)
            .then(response => {
                
                if(response.data){
                    this.working.submitAnswer = false
                    this.$emit('submit:value', this.answer)
                    this.answer = response.data
                    this.isUpdate = true     
                    this.$trackEvent.mixpanel("New Answer", {distinct_id: this.form.cus, form_id: this.form.slug, type: this.answer.field_type});               
                    this.updateFinalScore(response.data.total_score)
                }

            })            
            .catch(error => {
                this.working.submitAnswer = false
                this.$trackEvent.mixpanel("ERROR: New Answer", {distinct_id: this.form.cus, form_id: this.form.slug, type: this.answer.field_type, error: error});
                const errorData = {
                    title: "Error: unable to submit an answer to form " + this.form.slug,
                    context:{ user: this.form.cus, type: "new", stack: error.stack, error: error}
                }
                datadogLogs.logger.error(errorData.title, errorData)
            })
        }

        if(this.isUpdate){
            axios
            .put(process.env.VUE_APP_API_URL + 'answer/' + this.answer.id, this.answer)
            .then(response => {                
                if(response.data){
                    this.working.submitAnswer = false
                    this.$emit('submit:value')
                    this.answer = response.data
                    this.updateFinalScore(response.data.total_score)
                }

            })            
            .catch(error => {
                this.working.submitAnswer = false
                this.$trackEvent.mixpanel("ERROR: New Answer", {distinct_id: this.form.cus, form_id: this.form.slug, type: this.answer.field_type, error: error, is_update: true});
                const errorData = {
                    title: "Error: unable to submit an answer to form " + this.form.slug,
                    context:{ user: this.form.cus, type: "update", stack: error.stack, error: error}
                }
                datadogLogs.logger.error(errorData.title, errorData)
            })
        }
      },

      updateFinalScore(score){
          if(score) this.$emit("final-score", score)
      },

      getFirstQuestion(){
          let firstSlug = null
          let i = 0;

          while(!firstSlug && i < this.form.fields.length){
              if(this.isQuestionType(this.form.fields[i].type)) firstSlug = this.form.fields[i].slug
              i++
          }

          return firstSlug         
          
      },

      addValidationMessage(valid){      
        if(!valid){
            this.isValid = false;            
            return;
        }

        this.validateMessage = null;
        this.isValid = true;
        return;
      },

      removeValidationMessage(){
        this.validateMessage = null;
      },

      nextAction(type){

        const messagesFields = ["message", "welcome"]
        
        // if(this.data.type == "message" || this.data.type == "welcome"){
        if(messagesFields.includes(this.data.type)){
            return this.goNext();            
        }

        if(this.data.type == "thankyou"){
            this.endForm();
        }        

      },

      goNext(){
        this.$emit('submit:value');
      },

      endForm(){
        this.$emit('endForm', this.data)
        this.answer = null
      },

      isActive(){
        this.active = true;
      },

      format(value, type){
          if(type == "cpf" || type == "cnpj") return value.replace(/[^\w\s]/gi, '')
          return value
      },

      validate(rules){

        if(!rules) return true

        // is Required
        if(this.isQuestion(this.data.type) && rules.required) 
            // if(!this.notEmpty(this.value)){
            if(this.isEmpty(this.value)){
                this.validateMessage = "Essa resposta é obrigatória.";
                return false
            }

        // is Email
        if(this.isQuestion(this.data.type) && this.data.type == "email") {

            if(!this.notEmpty(this.value)) return true
            if(!this.isEmail(this.value)){
                this.validateMessage = "Informe um E-mail válido.";
                return false
            }

        }

        // is Url
        if(this.isQuestion(this.data.type) && this.data.type == "url") {

            if(!this.notEmpty(this.value)) return true
            if(!this.isUrl(this.value)){
                this.validateMessage = "Informe um endereço válido.";
                return false
            }

        }


        // is CPF
        if(this.isQuestion(this.data.type) && this.data.type == "cpf") {

            if(!this.notEmpty(this.value)) return true
            if(!this.isCpf(this.value)){
                this.validateMessage = "Informe um CPF válido.";
                return false
            }
        }

        // is CNPJ
        if(this.isQuestion(this.data.type) && this.data.type == "cnpj"){
            if(!this.notEmpty(this.value)) return true
            if(!this.isCnpj(this.value) || !this.notEmpty(this.value)){
                this.validateMessage = "Informe um CNPJ válido.";
                return false
            }
        }

        // is Date
        if(this.isQuestion(this.data.type) && this.data.type == "date"){
            if(!this.notEmpty(this.value)) return true
            if(this.value == "incomplete" || !this.notEmpty(this.value)){
                this.validateMessage = "Informe uma data válida.";
                return false
            }
        }

        // is Phone
        if(this.isQuestion(this.data.type) && this.data.type == "phone"){
            if(!this.notEmpty(this.value)) return true
            if(this.value == "incomplete" || !this.notEmpty(this.value)){
                this.validateMessage = "Informe um telefone válido.";
                return false
            }
        }


        // is legaltext
        if(this.data.type == "legaltext"){
            if(this.value != "yes"){
                this.validateMessage = "Você precisa aceitar para continuar.";
                return false
            }
        }

        // Max Characteres
        if(this.hasText(this.data.type) && rules.maxCharacters.active) 
            if(!this.maxCharacters(this.value, rules.maxCharacters.value)){
                this.validateMessage = `Sua resposta deve ter no máximo ${rules.maxCharacters.value} caracteres.`;                
                return false
            }
        
        // Min Max Values 
        if(rules.minmax.active && !this.isEmpty(this.value)){

            if(rules.minmax.max && this.isBiggerThan(this.value, rules.minmax.max)){
                this.validateMessage = `O número deve ser menor ou igual a ${rules.minmax.max}.`;   
                return false
            }
            
            if(rules.minmax.min && !this.isBiggerOrEqualThan(this.value, rules.minmax.min)){
                this.validateMessage = `O número deve ser maior ou igual a ${rules.minmax.min}.`;                
                return false
            }
        }
        
        return true;
      },

      cicleOptions(moveTo){
          
          if(moveTo == "up" && this.optionSelected > 0){
              this.optionSelected = this.optionSelected - 1;
          }

          if(moveTo == "down"){
              if(this.optionSelected === null){
                  this.optionSelected = 0;
              }

              else if(this.optionSelected >= 0 && this.optionSelected < (this.data.items.length - 1)){
                  this.optionSelected = this.optionSelected + 1;
              }
          }

      }
  }


}
</script>


<style lang="sass">

    .endform

        &.endform-message            
            text-align: center

            .end-title .field-text
                font-size: $fzLg
                font-weight: normal
                text-align: center

            .end-description .field-text
                font-size: $fzSm
                text-align: center
            
            .end-icon
                font-size: $fzXxl
                color: rgba($cFormText, 1)

        &.endform-redirect
            text-align: center
            font-size: $fzLg

        .icon
            font-size: $fzXl   

        .link
            color: $cBody   

        .redirect-label 
            font-size: $fzSm
            color: $cG5

        .redirect-url 
            +glassStyle($cPrimary)
            display: inline-block
            border-radius: 4px
            padding: $mgXs
    
            

    .editor-field
        pointer-events: none


    input.editable
        box-sizing: border-box
        padding-bottom: 4px
        border: 0
        width: 100%

        &:hover
            background: $cG2

        &::placeholder
            font-style: italic
            color: rgba(0,0,0,.3)

        &:focus
            color: $cPrimary
            outline: 0

    .field-container.field-comp
        margin: 15px auto 15px auto
        width: 100%
        height: 100%
        max-width: 700px
        margin: auto
        // background: red

        .form-title
            white-space: pre-line
            margin-top: 0



        .field-label
            font-size: 24px
            display: block
            margin-bottom: 0
            text-align: left

        .submit-button
            margin-top: 16px
            transition: all .5s ease-out

        .field-item
            margin-bottom: 16px
            //height: 100%
            display: flex;
            flex-direction: column
            justify-content: flex-start


        .description
            font-size: 16px
            margin: 0
            margin-bottom: 4px
            opacity: .85
            white-space: pre-line
            line-height: 150%

            &.description-message
                font-size: $fzSm

        // .description-container        
        //     margin-bottom: 16px

        .position
            position: absolute
            right: 100%
            margin: 3px 8px 0 0
            font-size: $fzSm    

        .editable
            &.title-xl
                font-weight: bold

    .score-block
        +glassStyle($cFormQuestion)
        color: rgba($cFormQuestion, 1)    
        padding: $mgSm $mg
        text-align: center
        border-radius: 5px
        width: 200px
        margin: 0 auto

        .score-icon
            font-size: $fzSm
            opacity: .7

        .score-value
            font-size: $fzXxl
            
        .score-label
            font-weight: bold
            font-size: $fzXs
            text-transform: uppercase




</style>
