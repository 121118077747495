<script>
import Icon from '../Icon.vue'
    import Btn from './Btn.vue'
    export default {
    components: { Btn, Icon },
        name: "LegalTextField",
        props: {
            formStyle: Object,
            error: String,
            loading: Boolean
        },        

        data() {
            return {
                accept: null
            }
        },

        methods: {
            submit(value) {
                const label = value ? "yes" : "no"
                this.accept = label == "yes" ? true : false
                this.$emit("update:value", label, null, true)
            }
        },
    }
</script>

<template>
    <div class="legal-text-field-comp">
        <div v-if="error" class="error-message mb16 mt8">{{error}}</div>
        <div class="actions row">
            <Btn @click.native="submit(true)" layout="big" :formStyle="formStyle" :loading="loading" class="mr16"><Icon name="check" /> Eu aceito</Btn>
            <Btn @click.native="submit(false)" layout="big" type="secondary" :formStyle="formStyle" :class="{accept}"><Icon name="times" /> Não aceito</Btn>
        </div>
    </div>
</template>

<style lang="sass" scoped>
.error-message
  color: $cNegative
  font-size: $fzXs

.accept
    opacity: .5

</style>