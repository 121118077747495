<template>
    <div class="modal" :data-active="open" :data-size="size">
        <div class="container">
            <div v-if="title" class="header">
                <div class="title">{{title}}</div>
                <div @click="closeModal" class="close-btn"><Icon name="times" /></div>
            </div>
            <div v-if="title == null" @click="closeModal" class="close-btn no-title"><Icon name="times" /></div>

            <!-- Nody Slot -->
            <div class="body">
                <slot></slot>
            </div>
            
            <!-- Footer -->
            <!-- <div class="footer">                
            </div> -->

        </div>
    </div>
</template>

<script>

    import Icon from '@/components/Icon.vue';

    export default {
        name: "Modal",
        props: {
            title: String,
            active: Boolean,
            size: String,
            
        },
        components: {
            Icon,
        },

        mounted () {
            if(this.active) this.openModal()
        },

        data() {
            return {
                open: false
            }
        },

        watch: {
            active(newValue) {
                if(newValue) this.openModal();                
                else this.closeModal();
            }
        },

        methods: {
            closeModal() {
                const body = document.querySelector('body');
                body.removeAttribute('data-modal')
                this.open = false;
                this.$emit('modal-close', this)                
            },

            openModal() {
                const body = document.querySelector('body');
                body.setAttribute('data-modal', 'open')
                this.$emit('modal-open', this)  
                this.open = true;              
            }
        },
        
    }
</script>

<style lang="sass" scoped>

    .modal
        background: rgba(#263238, .8)
        width: 100%
        height: 100%
        position: fixed
        top: 0
        left: 0
        z-index: 200
        display: none
        align-items: center
        justify-content: center

        &[data-active='true']
            display: flex

        .container
            background: #fff
            border-radius: 5px
            width: 90%
            max-width: 600px
            box-shadow: 0 0 20px rgba(0,0,0, .2)
            position: relative

        &[data-size='sm']
            .container
                max-width: 400px

        &[data-size='lg']
            .container
                max-width: 70%
                height: 90%

        &[data-size='xl']
            .container
                max-width: 85%
                height: 90%



    .header, .body, .footer
        padding: $mgSm $mg

    .header
        display: flex
        justify-content: space-between
        align-items: center
        font-size: $fzXs
        border-bottom: 1px solid $cG2
        // padding: $mgXs $mg


    .close-btn
        font-size: $fzMd
        cursor: pointer
        padding: 4px 6px
        border-radius: 4px
        margin-right: -6px

        &:hover
            background: rgba(#000, .15)

        &.no-title
            position: absolute
            right: $mgSm
            top: $mgSm
            z-index: 10

        

    .body
        padding: $mg
        

</style>