<template>
    <font-awesome-icon :icon="[fill, name]" />
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTrophy, faInfoCircle, faCaretDown, faSadTear, faLock, faFileAlt, faEnvelope, faImage, faCloudUploadAlt, faUserSecret, faAngleDown, faSmile, faLink, faCheck, faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faCommentDots, faFile, faPlus, faPaperPlane, faTimes, faCog, faCode, faStar, faFileDownload, faHandPointRight, faSearch, faGlobeAmericas, faTrashAlt, faHandPointer, faEllipsisV, faList, faTag, faArrowCircleUp, faEye, faShareSquare, faCloudDownloadAlt, faDownload, faShare } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faFileAlt as faFileAnt2, faHandPointer as faHandPointerR, faCopy, faHandPeace } from '@fortawesome/free-regular-svg-icons'
library.add([faTrophy, faInfoCircle, faHandPointerR, faFileAnt2, faCopy, faCaretDown, faHandPeace, faSadTear,faLock, faWhatsapp, faFileAlt, faEnvelope, faImage, faCloudUploadAlt, faUserSecret, faAngleDown, faSmile, faLink, faCheck, faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faCommentDots, faFile, faPlus, faPaperPlane, faTimes, faCog, faCode, faStar, faFileDownload, faHandPointRight, faSearch, faGlobeAmericas, faTrashAlt, faHandPointer, faEllipsisV, faList, faTag, faArrowCircleUp, faEye, faShareSquare, faCloudDownloadAlt, faDownload, faShare])

export default {
    name: "Icon",
    components: {
        FontAwesomeIcon,
    },
    props: {
        "name": String,
        type: String
    },

    computed: {
        fill() {
            if(this.type == 'brand') return 'fab'
            if(this.type == 'light') return 'fal'
            if(this.type == 'regular') return 'far'
            return 'fas'
        }
    },

}
</script>