const slugify = require('slugify')

export const EditorMixin = {

    methods: {

        geFieldtIndex(slug){
            return this.items.map(e => e.slug).indexOf(slug);
        },

        setForm(){
            this.info = this.form;
            this.editorCode = this.jsonToEditor(this.form.fields);
            
        },
    
        setEditor(){
            if(this.id){
                this.editorCode = '';
                this.setForm();
                this.editorToJson();                
            }
    
        },

        // Editor
        jsonToEditor(data){
            const language = this.form.language
    
            let textForEditor = '';
    
            for (var i = 0; i < data.length; i++) {
                // Add new row
                if(i > 0){
                    textForEditor = textForEditor + "\n";
                }
                
                // Type text
                if(data[i].type == "text"){
                    textForEditor = textForEditor + "\n" + this.translateField("[text]", language) + " " + data[i].value;
                }
    
                // Type textarea
                if(data[i].type == "textarea"){
                    textForEditor = textForEditor + "\n" + this.translateField("[textarea]", language) + " " + data[i].value;
                }

                // Type textarea
                if(data[i].type == "select"){
                    textForEditor = textForEditor + "\n" + this.translateField("[select]", language) + " " + data[i].value;
                }
    
                // Type radio
                if(data[i].type == "radio"){
                    textForEditor = textForEditor + "\n" + this.translateField("[radio]", language) + " " + data[i].value;
                }             

                // Type rating
                if(data[i].type == "rating"){
                    textForEditor = textForEditor + "\n" + this.translateField("[rating]", language) + " " + data[i].value;
                }             
    
                // Thankyou page
                if(data[i].type == "thankyou"){
                    textForEditor = textForEditor + "\n" + this.translateField("[thankyou]", language) + " " + data[i].value;
                }             
    
                // Welcome page
                if(data[i].type == "welcome"){
                    textForEditor = textForEditor + this.translateField("[welcome]", language) + " " + data[i].value;
                }             
    
                // Message
                if(data[i].type == "message"){
                    textForEditor = textForEditor + "\n" + this.translateField("[message]", language) + " " + data[i].value;
                }             
    
                // Link
                if(data[i].type == "link"){
                    textForEditor = textForEditor + "@ " + data[i].value + "\n";
                }             
    
                // Add description
                if(data[i].description && data[i].description !== ""){
                    for (let d = 0; d < data[i].description.length; d++) {
                        textForEditor = textForEditor + "\n" + "+ " + data[i].description[d];
                    }
                }
    
                // Add items
                if(data[i].items){
                    for (let d = 0; d < data[i].items.length; d++) {
                        textForEditor = textForEditor + "\n" + "- " + data[i].items[d];                    
                    }
                }
    
                // Add Link
                if(data[i].link && data[i].link.url){                
                    textForEditor = textForEditor + "\n" + "@ " + data[i].link.url;                
                }
            }
    
            return textForEditor;
    
        },
    
        editorToJson() {    
            const content = this.editorCode;
            let result = [];
    
            let count = 0;
            let lines = content.split('\n');
            let types = ["text", "textarea", "select", "radio", "rating", "item", "description", "checkbox", "thankyou", "message", "welcome", "link"];
            let mainType = ["text", "textarea", "select", "radio", "rating", "checkbox", "thankyou", "message", "welcome"]
            let lastLine = null;
    
            for (var i = 0; i < lines.length; i++) {
                let line = this.checkLineType(lines[i], lastLine);
    
                if(mainType.includes(line.type)){
                    lastLine = line.type
                }                
    
                if(types.indexOf(line.type) > -1){
    
                    if(line.type == "item"){
                        result[count - 1].items.push(line.value);
                        continue;
                    }
    
                    if(line.type == "description"){
                        result[count - 1].description.push(line.value);
                        continue;
                    }
    
                    if(line.type == "link"){
                        result[count - 1].link.url = line.value;
                        continue;
                    }
    
                    result[count] = line;
                    result[count].position = count;
                    result[count].validation = this.getValidations(lines[i]);
                    result[count].slug = slugify(line.value);
                    count++;
                }
    
            }
    
            this.form.fields = result;
            return result;
    
        },

        translateField(field, language){

            let names;

            if(language == "en"){
                names = {
                    "[text]": "[text]",
                    "[textarea]": "[textoarea]",
                    "[select]": "[select]",
                    "[radio]": "[radio]",
                    "[rating]": "[rating]",
                    "[message]": "[message]",
                    "[welcome]": "[welcome]",
                    "[thankyou]": "[thankyou]",
                }
            }
            
            if(language == "pt"){
                names = {
                    "[text]": "[texto]",
                    "[textarea]": "[textolongo]",
                    "[select]": "[lista]",
                    "[radio]": "[multipla]",
                    "[rating]": "[nota]",
                    "[message]": "[mensagem]",
                    "[welcome]": "[ola]",
                    "[thankyou]": "[obrigado]",
                }
            }

            return names[field];
        },
    
    
        checkLineType(string, lastLineType){
            
            string = string.trim();
            const language = this.form.language

    
            if(string.startsWith(this.translateField("[text]", language))){
                return {
                    "type": "text",
                    "value": string.replace(this.translateField('[text]', language), '').trim(),
                    "description": [],
                };
            }
    
            if(string.startsWith(this.translateField("[textarea]", language))){
                return {
                    "type": "textarea",
                    "value": string.replace(this.translateField('[textarea]', language), '').trim(),
                    "description": []
                };
            }

            if(string.startsWith(this.translateField("[select]", language))){
                return {
                    "type": "select",
                    "value": string.replace(this.translateField('[select]', language), '').trim(),
                    "items": [],
                    "description": []
                };
            }
    
            if(string.startsWith(this.translateField("[radio]", language))){
                return {
                    "type": "radio",
                    "value": string.replace(this.translateField('[radio]', language), '').trim(),
                    "items": [],
                    "description": []
                };
            }

            if(string.startsWith(this.translateField("[rating]", language))){
                return {
                    "type": "rating",
                    "value": string.replace(this.translateField('[rating]', language), '').trim(),
                    "description": []
                };
            }
    
            if(string.startsWith(this.translateField("[checkbox]", language))){
                return {
                    "type": "checkbox",
                    "value": string.replace(this.translateField('[checkbox]', language), '').trim(),
                    "items": [],
                    "description": []
                };
            }
    
            if(string.startsWith(this.translateField("[thankyou]", language))){
                return {
                    "type": "thankyou",
                    "value": string.replace(this.translateField('[thankyou]', language), '').trim(),
                    "description": [],
                    "link": {
                        "url": null
                    }
                };
            }
    
            if(string.startsWith(this.translateField("[welcome]", language))){
                return {
                    "type": "welcome",
                    "value": string.replace(this.translateField('[welcome]', language), '').trim(),
                    "description": []
                };
            }
    
            if(string.startsWith(this.translateField("[message]", language))){
                return {
                    "type": "message",
                    "value": string.replace(this.translateField('[message]', language), '').trim(),
                    "description": []
                };
            }
    
            if(string.startsWith("- ")){
                return {
                    "type": "item",
                    "value": string.replace('- ','').trim()
                };
            }
    
            if(string.startsWith("+ ")){
                return {
                    "type": "description",
                    "value": string.replace('+ ','').trim()
                };
            }
    
            if(string.startsWith("@ ") && lastLineType === "thankyou"){
                return {
                    "type": "link",
                    "value": string.replace('@ ','').trim()
                };
            }
    
            return {
                "type": null,
                "value": string.trim()
            };;
        },
    
        getValidations(string){   
            return {
                "required" : this.isRequired(string)
            }
        },
    
        isRequired(string){        
            if(string.endsWith("*")){
                return true;
            };
    
            return false;
        }
    
    
    }



}