<template>
    <div class="upload-field" :data-demo="demo" :data-layout="layout" :data-file="hasFile">
        
        <!-- LAyout box -->
        <label v-if="layout == 'box'" class="upload-box" :data-working="progress > 0">
            <div class="upload-box-button">
                <Icon class="icon" name="cloud-upload-alt" />
                <span class="label mt4">{{buttonLabel}}</span>
                <input class="input-hide" type="file" name="background" @change="uploadFile">
            </div>
        </label>

        <!-- Layout Button -->
        <label v-else class="input-label layout-button" :data-working="progress > 0">
            <div class="upload-button">
                <div :style="{width: progress + '%'}" class="button-loading"></div>
                <div class="button-content">
                    <Icon :name="buttonIcon" /> {{buttonLabel}}
                </div>
            </div>
            <input class="input-hide" type="file" name="background" @change="uploadFile">
        </label>
        <Btn @click.native="removeFile()" v-if="hasFile" class="ml8" layout="remove"></Btn>
    </div>
</template>

<script>
import Btn from './Btn.vue'
import Icon from '../Icon.vue'
import { readAndCompressImage } from 'browser-image-resizer';

    export default {
        name: 'UploadField',
        components: { Icon, Btn },
        props: {
            form: Object,                
            file: [Object, Array],  
            demo: Boolean,             
            layout: String
        },
        data() {
            return {
                progress: null,
                working: false,
            }
        },

        computed: {    

            buttonLabel() {
                if(this.fileData?.filename){
                    if(this.fileData.filename.length < 25) return this.fileData.filename
                    return '...' + this.fileData.filename.substring(this.fileData.filename.length - 25)
                }                
                if(this.progress > 95) return 'Processando...'
                if(this.progress > 0) return this.progress + '%'
                else return "Selecione um arquivo"
            },

            buttonIcon(){
                if(this.fileData?.filename) return "check"
                return "arrow-circle-up"
            },

            hasFile(){
                if(this.fileData?.filename) return true
                return false
            },

            fileData: {
                get(){
                    if(this.file) return this.file
                    else return null
                },
                set(value){
                    return value
                } 
            }
        },


        methods: {  

            isImage(file){
                return file['type'].includes('image')
            },


            resizeImage(file){

                const config = {
                    quality: 0.9,
                    maxWidth: 3840,
                    maxHeight: 2160,
                    autoRotate: true,
                    mimeType: file['type']
                }

                return readAndCompressImage(file, config).then(resizedImage => { return resizedImage })
            },


            setUploadProgress(loaded, total){
                const currentProgress = parseInt( Math.round( ( loaded / total ) * 100 ))
                this.progress = currentProgress
            },



            uploadFile(event){ 
                if(this.demo) return
                
                const fileObj = event.target.files[0]
                if(this.isImage(fileObj) && fileObj['type'] != "image/gif" ) this.uploadImage(fileObj)
                else this.uploadRawFile(fileObj)
                this.$trackEvent.mixpanel("File Upload", {distinct_id: this.$parent?.form?.cus, form_id: this.$parent.form.slug, file_size: fileObj.size, file_type: fileObj.type})
            },



            uploadImage(fileObj){
                return new Promise((resolve, reject) => {
                    let formData = new FormData()

                    this.resizeImage(fileObj)                
                    .then(file => {
                        file = new File([file], fileObj.name);
                        formData.append('file', file)
                        if(this.form) formData.append('form_slug', this.form.slug)
                        this.postFileToApi(formData)
                    })
                })
            },



            uploadRawFile(fileObj){
                return new Promise((resolve, reject) => {
                    let formData = new FormData()

                    formData.append('file', fileObj)
                    if(this.form) formData.append('form_slug', this.form.slug)
                    this.postFileToApi(formData)
                })
            },



            postFileToApi(formData){
                const config = {
                    onUploadProgress: progressEvent => {
                        this.setUploadProgress(progressEvent.loaded, progressEvent.total)
                    }}            
                
                this.$axios
                    .post(process.env.VUE_APP_API_URL + 'file/upload', formData, config)
                    .then(response => {
                        this.fileData = response.data
                        this.progress = 0
                        this.$emit('input', response.data)                
                        this.$emit('upload', response.data)                
                })
            },



            removeFile(){
                this.$axios
                    .delete(process.env.VUE_APP_API_URL + 'file/' + this.fileData.id)
                    .then(response => {
                        this.fileData = null
                        this.$emit('input', null)  
                        this.$emit('removed', this)                
                    })            
                    .catch(error =>{
                        if(error.response.status){
                            this.fileData = null
                            this.$emit('input', null)
                            this.$emit('removed', this)                
                        }
                    })              
            },

        },

    }
</script>

<style lang="sass" scoped>

.input-hide
    display: none

[data-file="true"],
[data-working="true"]
    .input-label
        pointer-events: none

.upload-button
    font-size: $fzSm
    color: $cBrand
    border: 1px solid $cBrand
    border-radius: 4px
    padding: $mgXs $mgSm
    display: inline-block
    position: relative
    cursor: pointer
    min-width: 200px
    text-align: center    

    [data-file="true"] &
        color: $cPositive
        border-color: $cPositive


.button-content
    position: relative
    z-index: 10

.button-loading
    background: rgba($cBrandA, .2)
    width: 0%
    height: 100%
    position: absolute
    top: 0
    left: 0
    z-index: 0
    border-bottom: 4px solid $cBrand

[data-layout="big"]
    .upload-button
        color: rgba($cFormQuestion, 1)
        border: 1px solid rgba($cFormQuestion, 1)

        &:hover
            background-color: rgba($cFormQuestion, .2)


    &[data-file="true"]
        .upload-button
            background-color: rgba($cFormQuestion, 1)
            color: $cFormBackground
            border-color: rgba($cFormQuestion, 1)


    .button-loading
        background: rgba($cFormQuestion, .2)
        border-bottom: 4px solid rgba($cFormQuestion, 1)


[data-layout="box"]
    width: 100%
    height: 100%

    .upload-box-button
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        background: rgba($cFormQuestion, .2)
        border-radius: 4px
        width: 100%
        height: 100%
        cursor: pointer
        opacity: .5

        &:hover
            opacity: 1

        .icon
            color: rgba($cFormQuestion, 1)

        .label
            max-width: 100px
            text-align: center
            font-size: $fzXxs
            color: rgba($cFormQuestion, 1)





</style>