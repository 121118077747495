<template>
    <div class="radio-feild-2" :data-layout="layout">
        <div v-for="(option, index) in options" @click='setValue(option.value)' :data-active="option.value == currentValue ? true : false" :key="'option-'+index" class="option-item" :data-disabled="!canEdit(option)">
            <div class="option-icon">
                <div v-if="layout == 'special' && option.icon" class="icon custom-icon"><Icon :name="option.icon.name" :type="option.value == currentValue ? 'solid': null" /></div>
                <div v-else class="icon default-icon"></div>
            </div>
            <div class="option-label">{{option.label}}</div>
            <TagLabel v-if="!canEdit(option)" type="new" class="plan-label">Upgrade</TagLabel>
        </div>
    </div>
</template>

<script>
    import TagLabel from '../TagLabel.vue'
    import Icon from '../Icon.vue'
    export default {
    components: { Icon, TagLabel },
        nmae: "RadioField2",
        props: {
            options: [Object, Array],
            value: Array,
            layout: String
        },

        mounted () {
            this.currentValue = this.value[0]
        },

        data() {
            return {
                currentValue: null,
                currentOptions: []
            }
        },

        methods: {
            setValue(value) {
                this.currentValue = value                  
                this.currentOptions = [this.currentValue]
                this.$emit('input', this.currentOptions)
            },


            canEdit(option){
                if(option.plan == "solo" || option.plan == "pro" || option.plan == "business") return this.userCan('custom-endform', this.$user)
                return true
            },

            userCan(role, user){
                if(role == 'custom-endform') return this.userCanEndForm(user)
                return false
            },

            userCanEndForm(user){
                if(user.plan_level > 1) return true
                if(user.plan == "solo" || user.plan == "pro") return true
                return false
            }


        },
    }
</script>

<style lang="sass" scoped>
.radio-feild-2
    width: 100%

    .option-item
        display: flex
        align-items: center
        margin-bottom: $mgXs
        cursor: pointer
    
        .plan-label
            margin-left: auto

        &:last-child
            margin-bottom: 0

        &[data-disabled="true"]
            pointer-events: none
            
            > div
                opacity: .5

            
    
    .option-icon
        margin-right: $mgXxs

    .default-icon   
        border: $cBody 2px solid
        width: 15px
        height: 15px
        border-radius: 15px

    [data-active="true"]
        .option-label
            color: $cBody

        .icon
            color: $cPrimary

        .default-icon
            border: $cPrimary 5px solid

    
    &[data-layout="special"]
        .option-item
            border: 2px solid $cG3
            padding: $mgXs
            border-radius: 4px
            width: 100%

            .option-icon
                margin-right: $mgXs


            &[data-active="true"]
                border-color: $cPrimary
                color: $cPrimary
                background: rgba($cPrimary, .1)

                .option-label
                    color: $cPrimary


    

</style>