<template>
    <div class="files-list-editor">
        <UploadField :form="form" v-on:upload="uploadFile($event)" :demo="true" :layout="layout" class="demo" />
        <div class="legend mt16"><Icon name="hand-pointer" /> Este botão é demonstrativo.</div>
    </div>
</template>

<script>
    import UploadField from './UploadField.vue'
    
    export default {
        name: "FilesListFieldEditor",
        components: { UploadField },
        props: {
            form: Object,
            layout: String,
        }, 

        
    }
</script>

<style lang="sass" scoped>
    .files-list-editor
        .legend
            transition: opacity .3s
            opacity: 0
            font-size: $fzXs
            color: $cG3

        &:hover
            .legend
                opacity: 1


    .demo
        pointer-events: none

</style>