import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br'
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.locale('pt-br')

export const UtilitiesMixin = {
    methods: {

        getTimeBetween(date1, date2, time = 'days'){
            const first = dayjs(date1)
            const second = dayjs(date2)
            return first.diff(second, time)
        },


        getNow() {
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;
            return dateTime;        
        },

        openModal(name){
            this.openModalAction(this.$refs[name]);
        },

        openModalAction(target){
            target.open = true;
        },

        hideAndShowByName(hide, show){
            const hideElement = document.querySelector(`[data-item="${hide}"]`)
            const showElement = document.querySelector(`[data-item="${show}"]`)
            hideElement.classList.add('hide')
            hideElement.classList.remove('show')
            showElement.classList.add('show')
            showElement.classList.remove('hide')
        },

        getFirstName(name){
            let first = name.split(' ');
            return first[0].charAt(0).toUpperCase() + first[0].substr(1).toLowerCase()
        },

        limit(string, limit){
            if(!string || !limit) return null
            let value = string.substring(0,limit)
            if(string.length > limit) value = value + "..."
            return value;
        },

        stringIsJson(str) {
            if (typeof str !== 'string') return false;
            try {
                const result = JSON.parse(str);
                const type = Object.prototype.toString.call(result);
                return type === '[object Object]' 
                    || type === '[object Array]';
            } catch (err) {
                return false;
          }
        },

        showTimeAgo(date){
            return dayjs.utc(date).local().fromNow()
        },

        formatDate(date, format = null){
            if(format == "ago") return this.showTimeAgo(date)                
            return this.regularDate(date)
        },

        regularDate(date){
            return dayjs.utc(date).local().format('DD/MM/YYYY - HH:mm')
        },


        toObject(value){
            const json = JSON.parse(value)
            return json
        },

        toString(value) {
            if(this.stringIsJson(value)) return JSON.parse(value).join(', ')
            else return value 
        },

        formatAnswerText(answer){
            // if(answer.value == null) return null
            if(answer.field_type == "upload" || answer.type == "upload" ) return this.formatAnswerAsUpload(answer.value)
            if(answer.field_type == "radio" || answer.type == "radio") return this.formatAnswerAsRadio(answer.value)
            if(answer.field_type == "date" || answer.type == "date") return this.formatAnswerAsDate(answer.value)
            if(answer.field_type == "address" || answer.type == "address") return this.formatAnswerAsAddress(answer.value)
            if(answer.field_type == "phone" || answer.type == "phone") return this.formatAnswerAsPhone(answer.value)
            if(answer.field_type == "cpf" || answer.type == "cpf") return this.formatAnswerAsCpf(answer.value)
            if(answer.field_type == "cnpj" || answer.type == "cnpj") return this.formatAnswerAsCnpj(answer.value)
            if(answer.field_type == "imageradio" || answer.type == "imageradio") return this.formatAnswerAsImageradio(answer.value)
            if(answer.field_type == "legaltext" || answer.type == "legaltext") return this.formatAnswerAsLegaltext(answer.value)
            return answer.value           
        },

        formatAnswerAsImageradio(values){
            if(!this.stringIsJson(values)) return values
            const valuesArr = JSON.parse(values)
            let result = ''
            valuesArr.forEach((element, index) => {
                let optionLabel = element.label ? element.label : "Imagem"
                result = result + optionLabel
                if(index != valuesArr.length - 1) result = result + ', '                
            });

            return result
        },

        formatAnswerAsLegaltext(value){
            return value == "yes" ? "Aceito" : "Não aceito"
        },

        formatAnswerAsCpf(value){
            return value.substring(0, 3) + '.' + value.substring(3, 6) + '.' + value.substring(6, 9) + '-' + value.substring(9, 11)
        },

        formatAnswerAsCnpj(value){
            return value.substring(0, 2) + '.' + value.substring(2, 5) + '.' + value.substring(5, 8) + '/' + value.substring(8, 12) + '-' + value.substring(12, 14)
        },

        //01.234.567/8901-23

        formatAnswerAsPhone(value){
            if(!this.stringIsJson(value)) return value            
            
            const number = JSON.parse(value)
            if(number.country == 55) return "+" + number.country + ' (' + number.phone.substring(0, 2) + ') ' + number.phone.substring(2, 7) + '-' + number.phone.substring(7, 20)
            return "+" + number.country + ' ' + number.phone
                
            
        },

        formatAnswerAsFullPhone(value){
            if(!this.stringIsJson(value)) return value
            const number = JSON.parse(value)
            return number.country + number.phone
        },

        formatAnswerAsAddress(value){
            if(!this.stringIsJson(value)) return value //corrige um bug na funcionalidade de "Mentions" no título da pergunta

            const address = JSON.parse(value)
            if(address.country == "BR"){
                let result = `${address.street}, ${address.number}`
                if(address.addressComp) result = result + "/" + address.addressComp
                result = result + `. ${address.city}/${address.state}/${address.country} - CEP ${address.cep}`
                return result
            }
            
            else{
                let result = address.line1
                if(address.line2) result = address.line1 + ' ' + address.line2
                result = result + ' ' + address.country
                return result
            }
        },

        formatAnswerAsUpload(value){
            const parse = JSON.parse(value)
            if(parse.length = 1) return "Arquivo anexo"
            if(parse.length > 1) return parse.length + " arquivos anexo"
            else return "Não respondeu..."
        },

        formatAnswerAsRadio(value){
            return this.toString(value)
        },

        formatAnswerAsDate(value){            
            return value
        },

        formatAnswerAsDateWithTimezone(value){
            const date = new Date(value)
            const formatDate = this.addZeroToDate(date.getDate()) + "/" + this.addZeroToDate(date.getMonth() + 1) + "/" + date.getFullYear()
            return formatDate
        },

        addZeroToDate(number){
         if(number < 10) return "0" + number
         return number
        },

        createRandId() {
            return 'x' + Math.random().toString(36).substr(2, 12)
       },

       replaceVariables(str, variablesList) {
            return str.split(/\{\{|\}\}/).map((t, i) => !(i % 2) ? t : variablesList[t]).join('')
        },

        isQuestionType(type){
            const notQuestions = ["welcome", "message", "thankyou"]
            if(notQuestions.includes(type)) return false
            return true
        },

        questionHasOptions(type){
            const hasOptions = ["radio", "select", "imageradio"]
            return hasOptions.includes(type) ? true : false
        },


        isNumber(n){
            if(Number(n) === 0) return true
            return !isNaN(parseFloat(n)) && isFinite(n)
        },

        formatBytes(bytes, decimals = 2) {
            if (bytes === 0 || bytes === null) return '0 Bytes';
        
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        
            const i = Math.floor(Math.log(bytes) / Math.log(k));        
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },

        hexToRgb(h) {
            let r = 0, g = 0, b = 0;
          
            // 3 digits
            if (h.length == 4) {
              r = "0x" + h[1] + h[1];
              g = "0x" + h[2] + h[2];
              b = "0x" + h[3] + h[3];
          
            // 6 digits
            } else if (h.length == 7) {
              r = "0x" + h[1] + h[2];
              g = "0x" + h[3] + h[4];
              b = "0x" + h[5] + h[6];
            }

           
            return [+r, +g, +b];
        },
        
        getContrastColor(hexcolor){
            
            if(!hexcolor) return null
            
            // If a leading # is provided, remove it
            if (hexcolor.slice(0, 1) === '#') {
                hexcolor = hexcolor.slice(1);
            }

            // If a three-character hexcode, make six-character
            if (hexcolor.length === 3) {
                hexcolor = hexcolor.split('').map(function (hex) {
                    return hex + hex;
                }).join('');
            }

            // Convert to RGB value
            var r = parseInt(hexcolor.substr(0,2),16);
            var g = parseInt(hexcolor.substr(2,2),16);
            var b = parseInt(hexcolor.substr(4,2),16);

            // Get YIQ ratio
            var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

            // Check contrast
            return (yiq >= 128) ? 'black' : 'white';
        
        },

        questionTitleWithVariables(value) {
            if(!value) return null
            return value.replace(/\{\{[^\}]+\}\}/g, `____`)
        },


        /**
         * Check if the field type should have logic options
         */

        fieldHasLogic(type){
            const remove = ["message", "thankyou", "welcome", "legaltext"]
            if(remove.includes(type)) return false
            return true
        },
        

        translateFieldType(name){
            if(name == "text") return "Resposta curta"
            if(name == "textarea") return "Resposta longa"
            if(name == "email") return "E-mail"
            if(name == "phone") return "Telefone"
            if(name == "date") return "Data"
            if(name == "url") return "Link"
            if(name == "cpf") return "CPF"
            if(name == "cnpj") return "CNPJ"
            if(name == "number") return "Número"
            if(name == "address") return "Endereço"
            if(name == "select") return "Lista"
            if(name == "radio") return "Múltipla escolha"
            if(name == "imageradio") return "Imagens"
            if(name == "upload") return "Arquivo"
            if(name == "rating") return "Nota"
            if(name == "message") return "Mensagem"
            if(name == "legaltext") return "Termos de Uso"
            if(name == "welcome") return "Boas-vindas"
            if(name == "thankyou") return "Agradecimento"
            return "Campo"
        },

        getListOfCountries(){
            return [
                {label:"Afghanistan", value:"AF"},
                {label:"Åland Islands", value:"AX"},
                {label:"Albania", value:"AL"},
                {label:"Algeria", value:"DZ"},
                {label:"American Samoa", value:"AS"},
                {label:"Andorra", value:"AD"},
                {label:"Angola", value:"AO"},
                {label:"Anguilla", value:"AI"},
                {label:"Antarctica", value:"AQ"},
                {label:"Antigua and Barbuda", value:"AG"},
                {label:"Argentina", value:"AR"},
                {label:"Armenia", value:"AM"},
                {label:"Aruba", value:"AW"},
                {label:"Australia", value:"AU"},
                {label:"Austria", value:"AT"},
                {label:"Azerbaijan", value:"AZ"},
                {label:"Bahamas (the)", value:"BS"},
                {label:"Bahrain", value:"BH"},
                {label:"Bangladesh", value:"BD"},
                {label:"Barbados", value:"BB"},
                {label:"Belarus", value:"BY"},
                {label:"Belgium", value:"BE"},
                {label:"Belize", value:"BZ"},
                {label:"Benin", value:"BJ"},
                {label:"Bermuda", value:"BM"},
                {label:"Bhutan", value:"BT"},
                {label:"Bolivia (Plurinational State of)", value:"BO"},
                {label:"Bosnia and Herzegovina", value:"BA"},
                {label:"Botswana", value:"BW"},
                {label:"Bouvet Island", value:"BV"},
                {label:"Brazil", value:"BR"},
                {label:"British Indian Ocean Territory (the)", value:"IO"},
                {label:"Brunei Darussalam", value:"BN"},
                {label:"Bulgaria", value:"BG"},
                {label:"Burkina Faso", value:"BF"},
                {label:"Burundi", value:"BI"},
                {label:"Cabo Verde", value:"CV"},
                {label:"Cambodia", value:"KH"},
                {label:"Cameroon", value:"CM"},
                {label:"Canada", value:"CA"},
                {label:"Cayman Islands (the)", value:"KY"},
                {label:"Central African Republic (the)", value:"CF"},
                {label:"Chad", value:"TD"},
                {label:"Chile", value:"CL"},
                {label:"China", value:"CN"},
                {label:"Christmas Island", value:"CX"},
                {label:"Cocos (Keeling) Islands (the)", value:"CC"},
                {label:"Colombia", value:"CO"},
                {label:"Comoros (the)", value:"KM"},
                {label:"Congo (the Democratic Republic of the)", value:"CD"},
                {label:"Cook Islands (the)", value:"CK"},
                {label:"Costa Rica", value:"CR"},
                {label:"Côte d'Ivoire", value:"CI"},
                {label:"Croatia", value:"HR"},
                {label:"Cuba", value:"CU"},
                {label:"Curaçao", value:"CW"},
                {label:"Cyprus", value:"CY"},
                {label:"Czechia", value:"CZ"},
                {label:"Denmark", value:"DK"},
                {label:"Djibouti", value:"DJ"},
                {label:"Dominica", value:"DM"},
                {label:"Dominican Republic (the)", value:"DO"},
                {label:"Ecuador", value:"EC"},
                {label:"Egypt", value:"EG"},
                {label:"El Salvador", value:"SV"},
                {label:"Equatorial Guinea", value:"GQ"},
                {label:"Eritrea", value:"ER"},
                {label:"Estonia", value:"EE"},
                {label:"Eswatini", value:"SZ"},
                {label:"Ethiopia", value:"ET"},
                {label:"Falkland Islands (the) [Malvinas]", value:"FK"},
                {label:"Faroe Islands (the)", value:"FO"},
                {label:"Fiji", value:"FJ"},
                {label:"Finland", value:"FI"},
                {label:"France", value:"FR"},
                {label:"French Guiana", value:"GF"},
                {label:"French Polynesia", value:"PF"},
                {label:"French Southern Territories (the)", value:"TF"},
                {label:"Gabon", value:"GA"},
                {label:"Gambia (the)", value:"GM"},
                {label:"Georgia", value:"GE"},
                {label:"Germany", value:"DE"},
                {label:"Ghana", value:"GH"},
                {label:"Gibraltar", value:"GI"},
                {label:"Greece", value:"GR"},
                {label:"Greenland", value:"GL"},
                {label:"Grenada", value:"GD"},
                {label:"Guadeloupe", value:"GP"},
                {label:"Guam", value:"GU"},
                {label:"Guatemala", value:"GT"},
                {label:"Guernsey", value:"GG"},
                {label:"Guinea", value:"GN"},
                {label:"Guinea-Bissau", value:"GW"},
                {label:"Guyana", value:"GY"},
                {label:"Haiti", value:"HT"},
                {label:"Heard Island and McDonald Islands", value:"HM"},
                {label:"Holy See (the)", value:"VA"},
                {label:"Honduras", value:"HN"},
                {label:"Hong Kong", value:"HK"},
                {label:"Hungary", value:"HU"},
                {label:"Iceland", value:"IS"},
                {label:"India", value:"IN"},
                {label:"Indonesia", value:"ID"},
                {label:"Iran (Islamic Republic of)", value:"IR"},
                {label:"Iraq", value:"IQ"},
                {label:"Ireland", value:"IE"},
                {label:"Isle of Man", value:"IM"},
                {label:"Israel", value:"IL"},
                {label:"Italy", value:"IT"},
                {label:"Jamaica", value:"JM"},
                {label:"Japan", value:"JP"},
                {label:"Jersey", value:"JE"},
                {label:"Jordan", value:"JO"},
                {label:"Kazakhstan", value:"KZ"},
                {label:"Kenya", value:"KE"},
                {label:"Kiribati", value:"KI"},
                {label:"Korea (the Democratic People's Republic of)", value:"KP"},
                {label:"Korea (the Republic of)", value:"KR"},
                {label:"Kuwait", value:"KW"},
                {label:"Kyrgyzstan", value:"KG"},
                {label:"Lao People's Democratic Republic (the)", value:"LA"},
                {label:"Latvia", value:"LV"},
                {label:"Lebanon", value:"LB"},
                {label:"Lesotho", value:"LS"},
                {label:"Liberia", value:"LR"},
                {label:"Libya", value:"LY"},
                {label:"Liechtenstein", value:"LI"},
                {label:"Lithuania", value:"LT"},
                {label:"Luxembourg", value:"LU"},
                {label:"Macao", value:"MO"},
                {label:"Madagascar", value:"MG"},
                {label:"Malawi", value:"MW"},
                {label:"Malaysia", value:"MY"},
                {label:"Maldives", value:"MV"},
                {label:"Mali", value:"ML"},
                {label:"Malta", value:"MT"},
                {label:"Marshall Islands (the)", value:"MH"},
                {label:"Martinique", value:"MQ"},
                {label:"Mauritania", value:"MR"},
                {label:"Mauritius", value:"MU"},
                {label:"Mayotte", value:"YT"},
                {label:"Mexico", value:"MX"},
                {label:"Micronesia (Federated States of)", value:"FM"},
                {label:"Moldova (the Republic of)", value:"MD"},
                {label:"Monaco", value:"MC"},
                {label:"Mongolia", value:"MN"},
                {label:"Montenegro", value:"ME"},
                {label:"Montserrat", value:"MS"},
                {label:"Morocco", value:"MA"},
                {label:"Mozambique", value:"MZ"},
                {label:"Myanmar", value:"MM"},
                {label:"Namibia", value:"NA"},
                {label:"Nauru", value:"NR"},
                {label:"Nepal", value:"NP"},
                {label:"Netherlands (the)", value:"NL"},
                {label:"New Caledonia", value:"NC"},
                {label:"New Zealand", value:"NZ"},
                {label:"Nicaragua", value:"NI"},
                {label:"Niger (the)", value:"NE"},
                {label:"Nigeria", value:"NG"},
                {label:"Niue", value:"NU"},
                {label:"Norfolk Island", value:"NF"},
                {label:"North Macedonia", value:"MK"},
                {label:"Northern Mariana Islands (the)", value:"MP"},
                {label:"Norway", value:"NO"},
                {label:"Oman", value:"OM"},
                {label:"Pakistan", value:"PK"},
                {label:"Palau", value:"PW"},
                {label:"Palestine, State of", value:"PS"},
                {label:"Panama", value:"PA"},
                {label:"Papua New Guinea", value:"PG"},
                {label:"Paraguay", value:"PY"},
                {label:"Peru", value:"PE"},
                {label:"Philippines (the)", value:"PH"},
                {label:"Pitcairn", value:"PN"},
                {label:"Poland", value:"PL"},
                {label:"Portugal", value:"PT"},
                {label:"Puerto Rico", value:"PR"},
                {label:"Qatar", value:"QA"},
                {label:"Réunion", value:"RE"},
                {label:"Romania", value:"RO"},
                {label:"Russian Federation (the)", value:"RU"},
                {label:"Rwanda", value:"RW"},
                {label:"Saint Barthélemy", value:"BL"},
                {label:"Saint Helena", value:"SH"},
                {label:"Saint Kitts and Nevis", value:"KN"},
                {label:"Saint Lucia", value:"LC"},
                {label:"Saint Martin (French part)", value:"MF"},
                {label:"Saint Pierre and Miquelon", value:"PM"},
                {label:"Saint Vincent and the Grenadines", value:"VC"},
                {label:"Samoa", value:"WS"},
                {label:"San Marino", value:"SM"},
                {label:"Sao Tome and Principe", value:"ST"},
                {label:"Saudi Arabia", value:"SA"},
                {label:"Senegal", value:"SN"},
                {label:"Serbia", value:"RS"},
                {label:"Seychelles", value:"SC"},
                {label:"Sierra Leone", value:"SL"},
                {label:"Singapore", value:"SG"},
                {label:"Sint Maarten (Dutch part)", value:"SX"},
                {label:"Slovakia", value:"SK"},
                {label:"Slovenia", value:"SI"},
                {label:"Solomon Islands", value:"SB"},
                {label:"Somalia", value:"SO"},
                {label:"South Africa", value:"ZA"},
                {label:"South Georgia and the South Sandwich Islands", value:"GS"},
                {label:"South Sudan", value:"SS"},
                {label:"Spain", value:"ES"},
                {label:"Sri Lanka", value:"LK"},
                {label:"Sudan (the)", value:"SD"},
                {label:"Suriname", value:"SR"},
                {label:"Svalbard", value:"SJ"},
                {label:"Sweden", value:"SE"},
                {label:"Switzerland", value:"CH"},
                {label:"Syrian Arab Republic (the)", value:"SY"},
                {label:"Taiwan (Province of China)", value:"TW"},
                {label:"Tajikistan", value:"TJ"},
                {label:"Tanzania, the United Republic of", value:"TZ"},
                {label:"Thailand", value:"TH"},
                {label:"Timor-Leste", value:"TL"},
                {label:"Togo", value:"TG"},
                {label:"Tokelau", value:"TK"},
                {label:"Tonga", value:"TO"},
                {label:"Trinidad and Tobago", value:"TT"},
                {label:"Tunisia", value:"TN"},
                {label:"Turkey", value:"TR"},
                {label:"Turkmenistan", value:"TM"},
                {label:"Turks and Caicos Islands (the)", value:"TC"},
                {label:"Tuvalu", value:"TV"},
                {label:"Uganda", value:"UG"},
                {label:"Ukraine", value:"UA"},
                {label:"United Arab Emirates (the)", value:"AE"},
                {label:"United Kingdom of Great Britain and Northern Ireland (the)", value:"GB"},
                {label:"United States Minor Outlying Islands (the)", value:"UM"},
                {label:"United States of America (the)", value:"US"},
                {label:"Uruguay", value:"UY"},
                {label:"Uzbekistan", value:"UZ"},
                {label:"Vanuatu", value:"VU"},
                {label:"Venezuela (Bolivarian Republic of)", value:"VE"},
                {label:"Viet Nam", value:"VN"},
                {label:"Virgin Islands (British)", value:"VG"},
                {label:"Virgin Islands (U.S.)", value:"VI"},
                {label:"Wallis and Futuna", value:"WF"},
                {label:"Western Sahara", value:"EH"},
                {label:"Yemen", value:"YE"},
                {label:"Zambia", value:"ZM"},
                {label:"Zimbabwe", value:"ZW"}
            ]
        }
    },

}