<script>
    import Icon from './Icon.vue'
    export default {
        components: { Icon },
        name: "Help",
        props: {
            link: String,
            description: String,
            width: {
                type: String,
                default: 'auto'
            },
        },
        
    }
</script>

<template>
    <div class="help-comp">
        <Icon name="info-circle" />
        <div class="content" :style="{width: width}">
            <div class="description" v-html="description"></div>
            <a v-if="link" :href="link" target="_blank" class="mt8 link">Saiba mais</a>
        </div>
    </div>
</template>


<style lang="sass" scoped>
.help-comp
    display: inline-block
    position: relative
    color: $cG5
    cursor: help

    &:hover
        .content
            display: block

.content
    display: none
    color: $cG5
    font-size: $fzXxs
    line-height: 120%
    max-width: 300px
    position: fixed
    border-radius: 4px
    // top: 15px
    // left: 0
    background: #fff
    box-shadow: 0 0 10px rgba(0,0,0,.15)
    padding: $mgSm 
    z-index: 100   

    .link
        display: inline-block


</style>