import axios from "axios"

export default {
    name: "scoreHelper",

    questionHasScore(questionSlug, scores){
        if(!scores) return false
        const scoresKeys = Object.keys(scores)
        if(scoresKeys.length === 0) return false
        if(!scoresKeys.includes(questionSlug)) return false

        // Fix: Ainda existe a possibilidade de ter options com valor "0"
        // Essa função irá retortar true, mesmo que todos os valores sejam zero.
        return true
    },


    getPointsToAdd(questionSlug, answerKeys, scores){
        let points = 0
        
        answerKeys.forEach(key => {
            if(scores[questionSlug][key]) points = Number(points) + Number(scores[questionSlug][key])            
        });

        return Number(points) ?? 0
    },


    getAnswerScore(answerSlug, answerValue, scores){
        const hasScore = this.questionHasScore(answerSlug, scores)
        
        if(hasScore){
            const pointsToAdd = this.getPointsToAdd(answerSlug, answerValue, scores)
            return pointsToAdd
        }

        return 0

    },


    sumAllPoints(scoreList){
        return Object.values(scoreList).reduce((a, b) => a + b)
    },

    async getRespondentScore(respondetId){
        const score = await axios.get(process.env.VUE_APP_API_URL + "respondent/final_score/" + respondetId)
        return score.data.data
    }


}