<template>
    <!-- <div v-on-clickaway="closeOptions" @keydown.esc="closeOptions" :data-active="active" :data-layout="layout" class="select-field field-container"> -->
    <div @keydown.esc="closeOptions" :data-active="active" :data-layout="layout" class="select-field field-container">
        <div @click="toggleStatus" class="select-container">
            <div class="select-label">{{currentLabel}}</div>
            <div class="select-icon"><Icon name="angle-down" /></div>            
        </div>
        <div v-if="items" class="select-options">
            <div                
                v-for="(option, index) in items"                
                @click="clickCurrentOption(index)"                                
                :key="option.value"
                :data-unavaible="!fieldAvaible(option.plan)"
                :class="{'active': index == current}"
                class="select-item">
                    <span class="item-value">{{option.label}}</span>
                    <router-link :to="{name: 'userSettings'}" v-if="!fieldAvaible(option.plan)" v-tooltip="'Faça upgrade para o plano PRO'" class="plan-label">PRO</router-link>
                </div>
        </div>
        <div v-if="error" class="error-message">{{error}}</div>

    </div>
</template>

<script>
import Icon from '../Icon.vue'
    import { mixin as clickaway } from 'vue-clickaway';
    
    export default {
    components: { Icon },
        name: "SelectFieldType",   
        mixins: [ clickaway ],     
        props: {
            options: {
                type: Array,
                default: ["Adicione"]
            },
            value: String,
            error: String,
            layout: String,
            data: Object,
            open: Boolean
            
        },
        data() {
            return {
                currentValue: null,
                startValue: null,
                current: null,
                active: false
            }
        },

        mounted () {
            this.addScrollifNeed()
            this.startValue = this.selectDefault()
        },

        computed: {            

            currentLabel() {
                if(this.current === null) return "Selecione"
                else return this.items[this.current].label
            },

            items(){
                if(this.data) return this.convertObjToOptions(this.data.items)
                else if(this.options[0]) return this.addValueAndLabel(this.options)
                else return null
            },


        },

        watch: {
            active() {
                if(this.active) this.openOptions()
                else this.closeOptions()                
            },

            value(){
                this.selectDefault()
            },

            open(value){
                this.active = value
            }

        },

        methods: {

            fieldAvaible(fieldPlan){
                if(fieldPlan == null || fieldPlan == undefined) return true
                return this.$user.limits.feature.pro_fields
            },
            // fieldAvaible(fieldPlan){
            //     if(fieldPlan == null) return false
            //     if(fieldPlan == this.$user?.plan) return false
            //     if(fieldPlan == this.$user?.subscriptions?.plan_name) return false
            //     return true
            // },

            addValueAndLabel(array){
                if(!array[0]['label']) return this.convertObjToOptions(array)
                else return array
            },

            convertObjToOptions(data){
                let optionsList = [];
                data.forEach(item => {
                    optionsList.push({
                        label: item,
                        value: item
                    })
                });

                return optionsList;
            },

            toggleStatus() {
                if(this.active) this.active = false
                else this.active = true
            },

            openOptions(){
                this.active = true;
                this.startValue = this.options[this.current]
                // this.$el.setAttribute('data-active', true)
            },
            closeOptions(){
                const options = this.$el.querySelector('.select-options')
                options.scrollTop = 0
                this.active = false;
                this.$emit('close-field-options')

                // this.$el.setAttribute('data-active', false)
            },

            clickCurrentOption(index){   
                // const oldValue = this.startValue.value
                // const toConver = ["imageradio", "radio", "select"]
                // console.log("old", oldValue)

                // if(toConver.includes(oldValue)) this.$emit("convertFields", oldValue)
                // if(oldValue == 'radio' || oldValue == 'select') this.$emit("convertFields", oldValue)
                this.setCurrentOption(index)
                this.active = false;
            },
            setCurrentOption(index){
                // if(this.items[index]?.plan != null && this.items[index]?.plan != this.$user?.plan) return false
                if(!this.fieldAvaible(this.items[index]?.plan)) return false
                this.current = index;
                this.currentValue = this.items[index].value;
                // this.active = false;
                this.$emit('input', this.currentValue)
                this.$emit('update:value', this.currentValue)
                this.$emit('input-focus')
            },
            
            addScrollifNeed(){
                if(this.items && this.items.length > 6)
                    this.$el.setAttribute("data-scroll", true)
            },

            selectDefault(){
                if(this.items){
                    for (const [index, option] of this.items.entries()) {
                        if(option.value == this.value) this.current = index;                    
                    }
                }
            }
        },
    }
</script>

<style lang="sass" scoped>
.error-message
  margin-top: $mgXs
  color: $cNegative
  font-size: $fzXs

.field-container
    position: relative
    user-select: none

.select-container
    cursor: pointer
    border: 1px solid $cG3
    border-radius: 4px
    padding: $mgXs $mgSm
    display: flex
    justify-content: space-between;
    align-items: center;


    &:hover
        border-color: rgba($cPrimary, .3)
        background: rgba($cPrimary, .1)

    .select-icon
        display: flex
        align-items: center
        justify-content: center

.select-options
    background: #fff
    display: none
    margin-top: 4px
    width: 100%
    border: 1px solid $cG3
    border-radius: 4px
    box-shadow: 0 0 10px rgba(0,0,0, .15)
    z-index: 100

    [data-active="true"] &
        display: block
        position: absolute
    
    [data-scroll="true"] &
        max-height: 200px
        overflow-y: scroll

    .select-item
        cursor: pointer
        padding: 10px 12px
        display: flex
        align-items: center
        justify-content: space-between

        &:hover
            background: $cG1
            color: $cPrimary

        &.active
            color: $cPrimary
            font-weight: bold
    
    .plan-label
        display: inline-block
        background: $cPositive
        color: #fff
        font-size: 9px
        border-radius: 4px
        padding: 3px 6px
        margin-left: 6px
        cursor: pointer


[data-layout='big']
    .select-container
        border-color: $cPrimary
        // padding: $mgSm $mgSm
        font-size: $fzMd
        color: $cPrimary
        height: 42px

[data-layout^='question-type-']
    font-size: $fzXs
    background: #fff!important

[data-layout='question-type-legaltext'],
[data-layout='question-type-welcome'],
[data-layout='question-type-thankyou'],
[data-layout='question-type-message']
    .select-container
        @include glassStyle($cMessage)

[data-layout='question-type-text']
    .select-container
        @include glassStyle($cText)
[data-layout='question-type-email']
    .select-container
        @include glassStyle($cEmail)

[data-layout='question-type-url']
    .select-container
        @include glassStyle($cUrl)

[data-layout='question-type-cpf']
    .select-container
        @include glassStyle($cDocument)
[data-layout='question-type-cnpj']
    .select-container
        @include glassStyle($cDocument)

[data-layout='question-type-textarea']
    .select-container
        @include glassStyle($cTextarea)
        
[data-layout='question-type-radio']
    .select-container
        @include glassStyle($cRadio)

[data-layout='question-type-select']
    .select-container
        @include glassStyle($cSelect)

[data-layout='question-type-rating']
    .select-container
        @include glassStyle($cRating)

[data-layout='question-type-upload']
    .select-container
        @include glassStyle($cUpload)

[data-layout='question-type-date']
    .select-container
        @include glassStyle($cDate)

[data-unavaible='true']
    background-color: $cG2
    color: $cG5
    cursor: not-allowed!important
    // pointer-events: none











</style>