<template>
    <div @click="clickEvent" class="btn-container" :class="{fullwidth}" :data-type="type" :data-brand-color="brandColor" :data-layout="layout" :data-size="size" :data-loading="loading" :data-darkLabel="darkLabel" :data-theme="getTheme" :data-soft-disabled="softDisabled">

        <!-- For links -->
        <router-link v-if='link' :to="this.link" class="btn" :target="target">
            <slot></slot>
        </router-link>

        <a v-else-if='href' :href="this.href" :target="target" class="btn">
            <slot></slot>
        </a>
        
        <!-- For delete items -->
        <button v-else-if="layout == 'remove'" class="btn" >
            <Icon name="trash-alt" /> <slot></slot>
        </button>

        <!-- For actions  -->
        <button v-else class="btn" >
            <slot v-if="!loading">
            </slot>

            <div v-if="loading" class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </button>
    </div>
</template>

<script lang="js">
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

    export default {
        name: 'Btn',
        mixins: [UtilitiesMixin],
        props: {
            label: String,
            size: String,
            type: String,
            link: Object,
            href: String,
            layout: String,
            fullwidth: Boolean,
            loading: Boolean,
            brandColor: Boolean,
            formStyle: Object,
            softDisabled: Boolean,
            target: {
                type: String,
                default: "_self"
            }
        },

        computed: {
            darkLabel() {
                const colorsList = ["#ffffff", "#FDD835", "#ECEFF1"]
                if(colorsList.includes(this.formStyle?.color) && this.type != "secondary") return true 
                return false
            },

            getTheme(){
                return this.getContrastColor(this.formStyle?.color)
            }
        },

        methods: {
            clickEvent() {
                if(!this.loading && !this.softDisabled) this.$emit("clicked")
            }
        },
    }
</script>


<style scoped lang="sass">    


.btn-container
    display: inline-block

    &.fullwidth
        display: flex
        justify-content: center
        width: 100%

    &[data-soft-disabled="true"]
        pointer-events: none!important

    &[data-disabled="true"]
        opacity: .7
        pointer-events: none!important
    
.btn
    background: $cPrimary
    color: #ffffff
    border: 1px solid $cPrimary
    padding: 8px 16px
    font-size: $fzSm
    border-radius: 5px    
    display: inline

    &:hover
        cursor: pointer
        background: darken($cPrimary, 10%)

    &:focus
        outline: 0

    [data-darkLabel='true'] &,
    [data-theme='black'] &
        color: #000000

    [data-type='secondary'] &
        background: transparent
        color: $cG5
        border-color: $cG5

        &:hover
            background: rgba($cG5, .15)


    [data-type='positive'] &
        background: $cPositive
        color: #fff
        border-color: $cPositive

        &:hover
            background: darken($cPositive, 10%)

    [data-type='outline'] &
        background: transparent
        color: $cPrimary
        border-color: $cPrimary

        &:hover
            background: rgba($cBrandA, .15)

    [data-type='boxlink'] &
        background: transparent
        color: $cBrand
        border: 0

        &:hover
            background: rgba($cBrandA, .1)

    [data-type='danger'] &    
        background: transparent
        color: $cNegative
        border-color: $cNegative

        &:hover
            background-color: $cNegative
            color: #fff

    [data-type='warning'] &    
        background: transparent
        color: $cWarning
        border-color: $cWarning

        &:hover
            background-color: $cWarning
            color: #fff

    [data-size='lg'] &
        font-size: 18px
        padding: 12px 14px
        font-weight: bold

    [data-size='sm'] &
        font-size: 14px
        padding: 6px 12px

    [data-size='xs'] &
        font-size: 13px
        padding: 4px 8px

    [data-loading=true] &
        opacity: .85
        pointer-events: none

    .fullwidth &
        width: 100%
        justify-content: center

    [data-layout='icon'] &,
    [data-layout='remove'] &
        color: $cG5
        box-shadow: none        
        background: none
        border: none

        &:hover
            background: rgba($cPrimary, .15)
            color: $cPrimary

            

    [data-type='danger'] &,
    [data-layout='remove'] &
        &:hover
            +glassStyle($cNegative)
            // color: red

    [data-layout='big'] &
        background-color: rgba($cBrandA, 1)
        border-color: rgba($cBrandA, 1)
        box-shadow: 0 0 6px rgba(0, 0, 0, .2)

        &[data-type="secondary"]
            background: transparent
            color: $cG5
            border-color: $cG5

            &:hover
                background: rgba($cG5, .15)
    
    [data-layout='big'][data-type="secondary"] &
        background-color: transparent
        border-color: rgba($cBrandA, 1)
        box-shadow: 0 0 6px rgba(0, 0, 0, .2)
        color: rgba($cBrandA, 1)



        &:hover
            background: rgba($cBrandA, .15)


    
    [data-layout='box'] &
        border-color: transparent
        background-color: trasnparent

        &:hover
            border-color: transparent




.sending
    .btn
        pointer-events: none
        opacity: .5


.spinner
    margin: auto
    //margin: 100px auto 0
    width: 70px
    text-align: center

    > div
        width: 12px
        height: 12px
        background-color: #fff
        border-radius: 100%
        display: inline-block
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both
        animation: sk-bouncedelay 1.4s infinite ease-in-out both

        [data-type='outline'] &
            background-color: $cPrimary
        
        [data-type='secondary'] &
            background-color: $cG5
        
        [data-type='danger'] &
            background-color: $cDanger


    .bounce1
        -webkit-animation-delay: -0.32s
        animation-delay: -0.32s

    .bounce2
        -webkit-animation-delay: -0.16s
        animation-delay: -0.16s

    @-webkit-keyframes sk-bouncedelay
        0%, 80%, 100%
            -webkit-transform: scale(0)

        40%
            -webkit-transform: scale(1)

    @keyframes sk-bouncedelay
        0%, 80%, 100%
            -webkit-transform: scale(0)
            transform: scale(0)

        40%
            -webkit-transform: scale(1)
            transform: scale(1)



</style>