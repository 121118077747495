<script>
import TagLabel from './TagLabel.vue'
import AvatarIcon from './AvatarIcon.vue'
import ContextMenu from './form/ContextMenu.vue'
import Icon from './Icon.vue'
    export default {
        components: { Icon, ContextMenu, AvatarIcon, TagLabel },
        name: "UserMenu",
        data() {
            return {
                showOptions: false
            }
        },
        
        computed: {
            currentTeam() {
                if(!this.$user.current_team_id) return this.$user
                const team = this.$user.teams.find(x => x.id === this.$user.current_team_id)
                return team
            }
        },

        methods: {
            toggleMenu() {
                this.showOptions = !this.showOptions
            },

            closeOptions(){
                this.showOptions = false
            },

            switchTeam(teamId = null){
                const teamIdRoute = teamId ? "/" + teamId : ''                
                this.$axios
                .put(this.$api + "team/switch" + teamIdRoute)
                .then(response => {
                    this.closeOptions()
                    if(this.$route.name == "Dashboard") window.location.reload()
                    else this.$router.push({name: "Dashboard"})
                })
            },

            logout(){
                this.$axios        
                .post(this.$api + 'auth/logout')
                .then(response => {
                    if(response.data.message === "Successfully logged out"){
                        localStorage.removeItem("access_token");
                        localStorage.removeItem("login_at");
                        this.$router.push({ name: 'Login' });                    
                    }
                })  
        }
        },
        
    }
</script>

<template>
    <div class="user-menu-comp">
        <div @click="toggleMenu" class="options-menu-icon">
            <div class="user-avatar"><AvatarIcon :name="currentTeam.name" :color="currentTeam.color" /></div>
            <div class="arrow ml8"><Icon name="angle-down" /></div>
        </div>

        <!-- Options -->
        <div v-if="showOptions" class="context-options">
            <ul class="organizations">
                <li @click="switchTeam()" class="organization-item"><AvatarIcon :name="$user.name" :color="$user.color" size="sm" /> <span class="organization-name ml8">{{$user.name}}</span> <TagLabel v-if="$user.current_team_id ==  null" type="beta" class="ml8">Ativo</TagLabel></li>
                <li v-for="team in $user.teams" :key="team.id" @click="switchTeam(team.id)" class="organization-item"><AvatarIcon :name="team.name" :color="team.color" size="sm" /> <span class="organization-name ml8">{{team.name}}</span> <TagLabel v-if="team.id == $user.current_team_id" type="beta" class="ml8">Ativo</TagLabel></li>
            </ul>
            <ul class="options">
                <li class="option-item"><router-link :to="{name: 'UserTeams'}" class="option-link">Seus times <TagLabel type="new" class="ml8">Novo</TagLabel></router-link></li>
                <li class="option-item"><router-link :to="{name: 'userSettings'}">Configurações</router-link></li>
                <li @click="logout" class="option-item">Sair</li>
            </ul>
        </div>

    </div>
</template>


<style lang="sass" scoped>
.user-menu-comp
    position: relative

    .options-menu-icon
        display: flex
        align-items: center
        cursor: pointer

    .arrow
        font-size: $fzSm
        color: $cG5

    .context-options
        width: 220px
        border-radius: 5px
        // padding: $mgSm
        background: $cWhite
        border: 1px solid $cG2
        font-size: $fzXs
        position: absolute
        right: 0
        top: 40px
        line-height: 120%
        box-shadow: 0 0 8px rgba(0,0,0,.15)
        font-size: $fzSm
        z-index: 100

        .organizations

            .organization-item
                font-size: $fzXs
                display: flex
                align-items: center
                padding: $mgXs $mgSm
                cursor: pointer                

                &:hover
                    background: $cG1


        .options
            font-size: $fzXs
            border-top: 1px solid $cG2
            padding-top: $mgSm
            margin-top: $mgSm

            .option-item
                cursor: pointer
                padding: $mgXxs $mgSm

                &:hover
                    background: $cG1

            .option-link
                display: flex
                align-items: center
        


</style>