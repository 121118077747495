<template>
    <div class="formLogo-comp">
        <img :src="logSrc" class="form-logo-image" alt="Logotipo">
    </div>
</template>

<script>
    export default {
        name: "FormLogo",
        props: {
            src: String,
            position: {
                type: String,
                default: "left"
            }
        },
        computed: {
            logSrc() {
                if(this.src.includes("http")) return this.src
                return this.$bucket + this.src
            }
        },
    }
</script>

<style lang="sass" scoped>
.formLogo-comp
    height: 100%

    .form-logo-image
        max-width: 100%
        max-height: 100%


</style>