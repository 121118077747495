import Vue from 'vue'
import App from './App.vue'
import router from './router'
import UUID from "vue-uuid"
import VueAnalytics from 'vue-analytics'
import VTooltip from 'v-tooltip'
import Icon from '@/components/Icon.vue'
import VueClipboard from 'vue-clipboard2' 
import VueMixpanel from 'vue-mixpanel'
import VueAmplitude from 'vue-amplitude';
import './axios'
import cep from 'cep-promise'
import VueMask from 'v-mask'
import LoadScript from 'vue-plugin-load-script';
import VueFacebookPixel from 'vue-facebook-pixel'
import { datadogLogs } from '@datadog/browser-logs';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Popover from 'vue-js-popover'
import VueMeta from 'vue-meta'


const axios = require('axios').default;
Object.defineProperty(Vue.prototype, '$cep', { value: cep });


// Global variables
Vue.prototype.$user = {};

Vue.prototype.$axios = axios;
Vue.prototype.$bucket = process.env.VUE_APP_BUCKET_URL;
Vue.prototype.$api = process.env.VUE_APP_API_URL;
Vue.prototype.$site = {url: process.env.VUE_APP_URL, name: "Respondi"};
Vue.prototype.$trackUser = {
  amplitude: function(){
    const currentUser = Vue.prototype.$user
    if(currentUser){
      const userProperties = {
        name: currentUser.name,
        email: currentUser.email
      }
      Vue.prototype.$amplitude.getInstance().setUserProperties(userProperties);
    }    
  }
}

Vue.prototype.$trackEvent = {  
  mixpanel: function (event, value = null){
    if(process.env.VUE_APP_CONTEXT == 'production'){  
       Vue.prototype.$mixpanel.track(event, value)
       Vue.prototype.$trackEvent.amplitude(event, value, value?.distinct_id);
      }
      else console.log("::STG:: Track Mixpanel event", {event: event, value: value})
  },

  gtm: function(event, value = null){
    const eventData = {'event': event}
    const data = {...eventData, ...value}     
    if(process.env.VUE_APP_CONTEXT == 'production') dataLayer.push(data)
    else console.log("::STG:: Track GTM event", {event: event, value: data})
  },

  gaCustom: function(account, category, action){
    if(process.env.VUE_APP_CONTEXT == 'production'){
      ga('create', account, 'auto', 'customerTracker');
      ga('customerTracker.send', 'pageview');
      if(category) ga('customerTracker.send', 'event', {eventCategory: category, eventAction: action});
    }
    else console.log("::STG:: Track Custom GA event", {account, category, action})

  },
  
  fb: function(pixel, event, value = null){
    const eventData = {'event': event}
    const data = {...eventData, ...value}     
    if(process.env.VUE_APP_CONTEXT == 'production'){
      Vue.prototype.$analytics.fbq.init(pixel);
      Vue.prototype.$analytics.fbq.event(event, value);      
    } 
    else console.log("::STG:: Track FB event", {event: event, value: data})
  },  
  
  amplitude: function(event, value = null, userId = null){
    let userToLog = null;
    if(userId) userToLog = userId
    else if(Vue.prototype.$user?.id) userToLog = Vue.prototype.$user?.id
    Vue.prototype.$amplitude.setUserId(userToLog)    
    Vue.prototype.$amplitude.getInstance().logEvent(event, value);
  }
}


datadogLogs.init({
  clientToken: process.env.VUE_APP_DD_TOKEN,
    site: 'datadoghq.com',
    env: process.env.NODE_ENV,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    silentMultipleInit: true,
    trackSessionAcrossSubdomains: true,
});



Vue.use(VueMeta)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueFacebookPixel)
Vue.use(LoadScript)
Vue.use(VueMask)
Vue.use(VueClipboard)
Vue.use(UUID)
Vue.use(VTooltip)
Vue.component('Icon', Icon )
Vue.use(VueMixpanel, {token: 'ed67cac2f4025f89e1bca5f007130192'})
Vue.use(VueAmplitude, {apiKey: 'd00395841ab1e71886a4b260c23b08fb'})
Vue.use(Popover)


Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueAnalytics, {
  id: 'UA-4782576-38',
  checkDuplicatedScript: true,
  router
})