<template>
    <span :data-type="type" class="tagLabel-comp" :style="{background: color}">
        <slot></slot>
    </span>
</template>

<script>
    export default {
        name: "TagLabel",
        props: {
            type: String,
            color: String,
        },
    }
</script>

<style lang="sass" scoped>
.tagLabel-comp
    display: inline-block
    background: $cPrimary
    color: #fff
    font-size: $fzXxs * 0.7
    text-transform: uppercase
    line-height: 100%
    padding: 3px 5px 3px 5px
    border-radius: 4px

    &[data-type="new"]
        background: $cPositive
    
    &[data-type="beta"]
        background: $cG4

    &[data-type="warning"]
        background: $cWarning

</style>