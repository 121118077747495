<template>
    <section :data-open="open" class="publish-link">
        <div class="image">
            <img src="@/assets/img/share-form.png" alt="Icone de compartilhar">
        </div>
        <div class="content">
            <Btn @click.native="close" class="btn-close" layout="icon" size="sm"><Icon name="times"/></Btn>
            <div class="title">
                <span>Link para divulgar <Btn class="embed-link" :link="{name: 'EditorShare', slug: this.slug}" layout="icon"><Icon name="code"/> Incorporar</Btn></span>
            </div>
            <div class="field mt8 mb8">
                <TextField :data="{}" :value="publicUrl" class="url-field" readonly />
                
                <Btn 
                    v-clipboard:copy="publicUrl"
                    @click.native='trackCopy'
                    v-tooltip="{ content: 'Copiado!', placement:'bottom', trigger: 'click', container: '.publish-link' }"
                    class="ml8 copy"                    
                    type="positive"                    
                    >Copiar
                </Btn>
            </div>
            <div class="legend">Envie este link para quem deve responder este formulário.</div>
        </div>
    </section>
</template>

<script>
// import VueClipboard from 'vue-clipboard2'
import Icon from './Icon.vue'
import Btn from './form/Btn.vue'
import TextField from './form/TextField.vue'
    export default {
    components: { TextField, Btn, Icon },
    name: "PublishLinkPopover",
    props: {
        slug: String,
        active: Boolean
        },
    
    data() {
        return {
            open: this.active
        }
    },

    watch: {
        active(newValue) {
            if(newValue) this.open = true;
            else this.open = false;                
        }
    },

    computed: {
        publicUrl(){
            const resolved = this.$router.resolve({
                name: 'FormView',
                params: { slug: this.slug }
                })
            return process.env.VUE_APP_URL + resolved.href
        }

    },

    methods: {
        close() {
            this.open = false
            this.$emit('publish-link-close', this)
        },
        trackCopy(){
            this.$trackEvent.mixpanel("Copy Form Link", {form: this.slug})
        }

    },
   
    }
</script>

<style lang="sass" scoped>
.embed-link
    font-weight: normal

.publish-link
    background: #fff
    // height: 100%
    width: 100%
    border-radius: 5px
    box-shadow: 0 0 20px rgba(0,0,0,.15)
    display: none
    position: absolute
    top: 60px
    right: 30px
    width: 470px
    z-index: 100

    &[data-open="true"]
        display: flex

    .image
        width: 120px
        background: #F7F6E9
        display: flex
        align-items: center
        justify-content: center

        img
            max-width: 70px

    .content
        padding: $mgSm
        display: flex
        justify-content: center
        flex-direction: column

    .title
        font-size: $fzSm
        font-weight: bold
        display: flex
        justify-content: space-between
        align-items: center

    .field
        display: flex

        .url-field
            input
                font-size: 18px !important

    .legend 
        font-size: $fzXxs
        line-height: 120%

    .copy
        font-size: 16px !important
    
    .btn-close
        position: absolute
        right: $mgXxs
        top: $mgXxs
        

</style>