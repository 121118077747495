<template>
  <div class="field-group mt16" :data-layout-type="layoutType" :data-layout-size="layoutSize" :data-multi="data.options.multipleAnswer">
    <div v-if="data.options.multipleAnswer" class="field-tip mb16"><Icon name="list" class="mr4" />Selecione quantos itens desejar.</div>

    <!-- <div v-for="(option, index) in data.items" :key="option.key" @click="select($event.target)" :data-active="currentItems.includes(getOptionValue(option)) ? true : false" class="option" :value="getOptionValue(option)" :data-key="option.key"> -->
    <div v-for="(option, index) in data.items" :key="option.key" @click="select($event.target)" :data-active="currentValueKeys.includes(option.key)" class="option" :value="getOptionValue(option)" :data-key="option.key">
        <span class="icon">
          <span class="letter">{{letters[index]}}</span>
        </span>
        {{ getOptionValue(option) }}
    </div>

    <div v-if="showOther" ref="customOption" :data-active="currentItems.includes(customValue) ? true : false" class="option other-field" @click="select($event.target, true)" :value="customValue">
      <span class="icon">
        <span class="letter">{{letters[data.items.length]}}</span>
      </span>
        <input ref="customOptionInput" @change="select($event.target, true)" class="customOptionInput" type="text" v-model="customValue" placeholder="Outro....">        
    </div>

    <div v-if="error" class="error-message">{{error}}</div>
        
  </div>

</template>

<script>
import Icon from '../Icon.vue'
export default {
  name: 'RadioField',
  components: {
    Icon,
  },
  
  data: function(){
      return {
          currentValue: null,
          currentValueKeys: [],
          currentItems: [],
          customValue: null,
          letters: ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az", "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz", "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz","aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az", "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz", "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz","aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az", "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz", "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz","aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az", "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz", "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz"]            
      }
  },

  props: {
    data: Object,
    value: String,
    inline: Boolean,
    showOther: Boolean,
    error: String,
    selected: Number,
  },


  watch: {
    selected: function(){
      this.selecttByArrowKey(this.selected)
    },

  },

  computed: {
    layoutSize() {
      if(this.data.items.length > 10) return 'sm'
      if(this.data.items.length > 5) return 'md'
      return null 
    },
    layoutType(){
      if(this.inline) return "inline"
      return null
    },
    submitOnUpdate(){
      return !this.data.options.multipleAnswer
    }

  },

  methods: {

      updateCurrentItems(item){
        this.currentValue = item.map(item => item.value)
        this.currentValueKeys = item.map(item => item.key)
      },

      getOptionValue(option){        
        if(option.label) return option.label // ::Fix:: Convert old array options to Object options
        return option
      },

      select(selectedOption, custom = false){

        if(custom) this.$refs.customOptionInput.focus()

        
        let value
        let valueKey
        if(!custom){
          value = selectedOption.getAttribute('value')
          valueKey = selectedOption.getAttribute('data-key')
        }

        else value = this.customValue
        
        if(this.data.options.multipleAnswer){
          this.toggleMultipleValues(value, valueKey)
          // this.selectMultipleOption(selectedOption)
        }

        else{
          this.addUniqueValue(value, valueKey)
          // this.selectUniqueValue(selectedOption, custom)
        } 

        this.updateCurrentItems(this.currentItems)
        this.submitValue()       

      },

      submitValue(){
        if(this.currentValue.length == 0) this.$emit('update:value', '', this.submitOnUpdate)
        else this.$emit('update:value', JSON.stringify(this.currentValue), this.currentValueKeys, this.submitOnUpdate)
        this.$emit('input-focus')        
      },


      addUniqueValue(value, key){
        if(value == null) this.currentItems = []
        else this.currentItems = [{value, key}]
      },

      toggleMultipleValues(value, key){    
        if(value == null || value == "") return null

        const currentValues = this.currentItems.map(item => item.value)
    
        if(currentValues.includes(value)){
          this.currentItems = this.currentItems.filter(item => item.value !== value)
        }

        else{
          const newItem = [{value, key}]
          this.currentItems = [...this.currentItems, ...newItem]
        }

      },

      updateCustomOption(){
        if(this.data.options.multipleAnswer) this.toggleMultipleValues(value, custom)
        else this.addUniqueValue(value)
        this.submitValue()
      },

      selecttByArrowKey(position){
        let options = this.$el.querySelectorAll(".option");
        this.select(options[position]);
      }

  }

}
</script>

<style scoped lang="sass">

.error-message
  margin-top: $mgXs
  color: $cNegative
  font-size: $fzXs

.field-group
  max-height: 400px
  // background: yellow
  overflow: auto
  padding-right: $mgSm

.field-tip
  color: $cG3
  font-size: $fzXs

.option
    display: flex
    align-items: center
    outline: 1px solid rgba($cFormQuestion, 1)
    margin-top: 2px //fix render issue
    margin-left: 1px //fix render issue
    // border-bottom: 1px solid rgba($cFormQuestion, 1)
    border-radius: 4px
    padding: $mgXs $mgXs
    color: rgba($cFormQuestion, 1)
    margin-bottom: $mgXs * 1.4
    font-size: $fzXs * 1.4

    .icon
        display: flex
        align-items: center
        justify-content: center
        height: 20px
        width: 20px
        border-radius: 20px
        margin-right: 8px
        pointer-events: none
        +glassStyle($cFormQuestion)
          

        [data-multi='true'] &
          border-radius: 3px

    [data-layout-size='md'] &
      padding: $mgXxs $mgXs
      font-size: $fzXs * 1.2
      margin-bottom: $mgXxs * 1.2

    [data-layout-size='sm'] &
      padding: $mgXxs $mgXs
      font-size: $fzXs
      margin-bottom: $mgXxs

    [data-layout-type='inline'] &
      display: inline-flex
      margin-right: $mgXs

    .letter
      text-transform: uppercase
      font-size: 12px


    &[data-active="true"]
      font-weight: bold
      background: rgba($cFormQuestion, .1)

      .icon
        color: $cFormBackground
        background: rgba($cFormQuestion, 1)
    
    &:hover
        cursor: pointer
        background: rgba($cFormQuestion, .1)
        
        .icon
          border: 1px solid rgba($cFormQuestion, 1)

.other-field
  .customOptionInput
    pointer-events: none
    border: 0
    background: transparent
    font-size: $fzXs * 1.4
    color: rgba($cFormQuestion, 1)
    width: 100%
    outline: 0
    padding: 0

    &::placeholder
      color: rgba($cFormQuestion, .7)

    &:focus
      border-bottom: 2px solid rgba($cFormQuestion, 1)


</style>
