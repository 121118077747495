<template>
  <div v-if="formLoaded" class="page-editor">
      
    <TopHeader nav="Editor" :data="form">
        <Btn @click.native="showPreview(form)" type="secondary" size="sm"><Icon name="eye" /> Ver</Btn>
        <Btn @click.native="openShareLink()" type="outline" size="sm"><Icon name="share-square" type="solid" /></Btn>
        <Btn v-if="id" @click.native="updateFormAndShowLink(form)" size="sm" :loading="working.updateform">Publicar</Btn>
        <Btn v-else @click.native="storeForm(form)" size="sm">Criar formulário</Btn>
    </TopHeader>

    <PublishLinkPopover ref="shareLink" :active="showShareLink" :slug="form.slug" v-on:publish-link-close="closeShareLink"/>

    <main class="page-content">      
    
        <!-- Editor  -->
        <div class="fields-editor" :style="cssVars">
            <NewEditorField :fields="form.fields" :form="form" v-on:update:value="editorUpdateValue" v-on:save-form="updateAnRefreshForm" />           
        </div>

    </main>
  </div>
</template>

<script>
import PublishLinkPopover from '../components/PublishLinkPopover.vue'
import Btn from '@/components/form/Btn.vue';
import Preview from '@/components/Preview.vue';
import NewEditorField from '@/components/NewEditorField.vue';
import TopHeader from '@/components/TopHeader.vue';
import {FormMixin} from '@/mixins/FormMixin.js';
import {EditorMixin} from '@/mixins/EditorMixin.js';
import Icon from '@/components/Icon.vue';
// import ShareFormModal from '@/components/modal/ShareFormModal.vue';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

const axios = require('axios').default;

export default {
  name: 'Editor',
  mixins: [FormMixin, EditorMixin, UtilitiesMixin],
  components: {
    Preview,
    TopHeader,
    NewEditorField,
    Btn,
    Icon,
    // ShareFormModal,
    PublishLinkPopover
  },

  data: function () {
    return {
      id: this.$route.params.slug,
      action: null,
      editorCode: '',
      preview: {},
      info: '',
      status: null,
      previewPosition: 0,
      before: {},
      showShareLink: false
    }
  },

  mounted: function(){
      if(this.id == null){
          this.formLoaded = true;
          this.form = {};          
      }

      document.addEventListener("keydown", this.watchKeyPress);

  },

  beforeDestroy: function(){
      document.removeEventListener("keydown", this.watchKeyPress);
  },

  computed: {
      fields() {
          return this.form.fields 
      }
  },

  watch: {
      form(value) {
          this.form = value
          
      }
  },


  methods: {

    openShareLink(){
        this.showShareLink = true
    },
    closeShareLink(){
        this.showShareLink = false
    },


    updateAnRefreshForm(){  
        this.updateForm(this.form)
    },

    updateFormAndShowLink(){
        this.updateForm(this.form)
        .then(data => {
            this.openShareLink()
        })

    },

    watchKeyPress: function(e){

        // Crtl + S
        if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
            this.updateAnRefreshForm(this.form);
            e.preventDefault();
        }               
        
    },

    editorUpdateValue: function(value){
          this.editorCode = value;
    },

    setPreviewPosition(after, before){
        if(before){
            for (let i = 0; i < after.length; i++) {
                if(JSON.stringify(after[i]) !==JSON.stringify(before[i])){
                    if(i !== this.previewPosition){
                        this.previewPosition = i;
                    }
                }                    
            }
        }
    }
  },

}

</script>

<style lang="sass">

.page-editor
    display: flex
    flex-direction: column
    height: 100%
    overflow: hidden

.page-content
    display: flex
    height: 100%

.json
    text-align: left

.alert
    display: block
    width: 200px
    height: 30px
    background: #00E676    
    color: white
    text-align: center
    padding: 8px
    position: fixed
    top: 30px
    right: 30px
    border-radius: 5px
    z-index: 100
    font-size: 14px

.preview
    overflow: hidden

.fields-editor
    width: 100%

.fields-preview
    text-align: left
    //width: 50%

    .preview
        display: block
        width: 100%
        height: 100%
        padding: 0

    .form-container
        padding: $mg

</style>
