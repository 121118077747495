<template>
    <div v-if="active" class="loadingIcon-comp spinner" :data-brandColor="brandColor">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</template>

<script>
    export default {
        name: "LoadingIcon",
        props: {
            active: {
                type: Boolean,
                default: true
            },
            brandColor: Boolean
        },
    }
</script>

<style lang="sass" scoped>

.spinner
    margin: auto
    width: 70px
    text-align: center

    > div
        width: 12px
        height: 12px
        background-color: $cPrimary
        border-radius: 100%
        display: inline-block
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both
        animation: sk-bouncedelay 1.4s infinite ease-in-out both        

        // [data-type='outline'] &
        //     background-color: $cPrimary

    &[data-brandColor='true']
        > div
            background-color: rgba($cFormQuestion, 1)

    .bounce1
        -webkit-animation-delay: -0.32s
        animation-delay: -0.32s

    .bounce2
        -webkit-animation-delay: -0.16s
        animation-delay: -0.16s

    @-webkit-keyframes sk-bouncedelay
        0%, 80%, 100%
            -webkit-transform: scale(0)

        40%
            -webkit-transform: scale(1)

    @keyframes sk-bouncedelay
        0%, 80%, 100%
            -webkit-transform: scale(0)
            transform: scale(0)

        40%
            -webkit-transform: scale(1)
            transform: scale(1)


</style>