<template>
    <div class="phone-field-comp field-group" :class="{internation: ddi != '55'}">
        <div class="phone-field-group">
            <div class="country-field">                
                <div v-if="showDdi" class="number">
                    <span class="divider">+</span>
                    <input type="text" :ref="slugValue + '-ddi'" @input="updateValue('ddi', $event.target)" @focus="$emit('input-focus', $event.target.value)" maxlength="3" v-model="ddi" class="field-text phone-field ddi" placeholder="DDI">
                </div>
                <div v-else @click="showDdiField" class="country-flag"><img class="brasil-icon mr8" src="@/assets/img/home/brasil-icon.svg" alt="Bandeira do Brasil"></div>
            </div>
            <div v-if="ddi == 55" class="state-field">
                <span class="divider">(</span>
                <input type="text" :ref="slugValue + '-ddd'" @input="updateValue('ddd', $event.target)" @focus="$emit('input-focus', $event.target.value)" maxlength="2" v-model="ddd" class="field-text phone-field ddd" placeholder="00">
                <span class="divider">)</span>
            </div>
            <input v-if="ddi == 55" type="text" :ref="slugValue + '-phone'" @input="updateValue('phone', $event.target)" @focus="$emit('input-focus', $event.target.value)" maxlength="10" v-model="phone" v-mask="'#####-####'" class="phone-field phone" placeholder="00000-0000">
            <input v-else type="text" :ref="slugValue + '-phone'" @input="updateValue('phone', $event.target)" @focus="$emit('input-focus', $event.target.value)" maxlength="15" v-model="phone" v-mask="'###############'" class="phone-field phone" placeholder="000000000">
        </div>
        <div v-if="error" class="error-message">{{error}}</div>
    </div>
</template>

<script>
    export default {
        name: "PhoneField",
        props: {
            data: Object,
            value: [String, Number],
            error: [String]
        },
        data() {
            return {
                ddi: "55",
                ddd: null,
                phone: null,
                showDdi: false,
                valid: {
                    ddi: false,
                    ddd: false,
                    phone: false,
                }
            }
        },

        computed: {
            slugValue() {
                if(this.slug) return this.slug
                else if(this.data.slug) return this.data.slug
                else return "x" + Math.random()
            },
            phoneValue() {  
                if(this.ddi && this.ddd && this.phone && this.ddi == "55") return {country: this.ddi, phone: this.ddd + this.phone.replace(/[^\d]/g,'')}
                if(this.ddi && this.phone && this.ddi != "55") return {country: this.ddi, phone: this.phone.replace(/[^\d]/g,'')}
            }
        },

        mounted () {
            this.valid.ddi = this.checkDdi(this.ddi)
            this.setFoucs("ddd")
        },

        methods: {   
            
            showDdiField(){
                this.showDdi = true
            },

            updateValue(type, target){
                if(this.testPhone(type, target.value)) this.$emit('update:value', JSON.stringify(this.phoneValue))
                else{
                    if(this.ddd || this.phone) this.$emit('update:value', "incomplete")
                    else this.$emit('update:value', "")
                }
                 
            },

            testPhone(type, value){ 
                if(type == "ddi") this.valid.ddi = this.checkDdi(this.ddi)               
                if(type == "ddd") this.valid.ddd = this.checkDdd(value)                
                if(type == "phone") this.valid.phone = this.checkPhone(value)
                if(this.valid.ddi && this.valid.ddd && this.valid.phone && this.ddi == "55") return true                
                if(this.valid.ddi && this.valid.phone && this.ddi != "55") return true                
                return false
            },

            checkDdi(value){
                const ddi = value.replace(/[^\d,]/g,'')
                this.ddi = ddi
                if(this.ddi.length > 3 ) return false
                if(this.ddi.length > 2) this.setFoucs("phone") 
                return true
            },

            checkDdd(value){
                const ddd = value.replace(/[^\d,]/g,'')
                this.ddd = ddd
                const validNumbers = ["11","12","13","14","15","16","17","18","19","21","22","24","27","28","31","32","33","34","35","37","38","41","42","43","44","45","46","47","48","49","51","53","54","55","61","62","63","64","65","66","67","68","69","71","73","74","75","77","79","81","82","83","84","85","86","87","88","89","91","92","93","94","95","96","97","98","99"]
                if(ddd.length != 2 || validNumbers.includes(ddd) == false) return false    
                if(ddd.length > 1) this.setFoucs("phone")     
                return true
            },

            checkPhone(value){                
                const phone = value.replace(/[^\d-]/g,'')                
                const numbers = value.replace(/[^\d]/g,'')      
                const invalidNumbers = ["123456789", "345678910", "345678911", "987654321", "112233445", "111111111", "222222222", "333333333", "44444444", "55555555", "66666666", "777777777", "888888888", "999999999", "000000000"]          
                this.phone = phone
                if(this.ddi == "55"){
                    if(numbers.length > 9 || numbers.length < 8) return false
                    if(invalidNumbers.includes(numbers)) return false
                }
                else {
                    if(numbers.length < 6) return false
                }
                return true
            },

            setFoucs(date){
                if(date == "ddd"){
                    const element = this.slugValue + '-ddd'
                    this.$refs[element].focus()
                    this.$refs[element].select()
                }
                if(date == "phone"){
                    const element = this.slugValue + '-phone'
                    this.$refs[element].focus()
                    this.$refs[element].select()
                }
            }
        },
        
        
    }
</script>

<style lang="sass" scoped>
.phone-field-group
    width: 220px
    display: flex
    padding-bottom: $mgXs
    border-bottom: 1px solid rgba($cFormQuestion, 1)
    align-items: center

    .internation &
        width: 300px

    .phone-field
        width: 100%
        max-width: 45px
        font-size: $fzMd
        padding: $mgXxs
        letter-spacing: 1px
        border: none
        border-radius: 4px
        text-align: center
        background-color: transparent
        color: rgba($cFormQuestion, 1)


        &.ddi,
            min-width: 50px
            max-width: 50px

        &.ddd,
            min-width: 35px
            max-width: 35px

        &.phone
            text-align: left
            min-width: 135px
            max-width: 135px

            .internation &
                width: 200px

        &:focus, &:hover
            outline: 0
            background: rgba($cFormQuestion, .15)

        &::placeholder
            color: rgba($cFormQuestion, .6)


    .divider
        font-size: $fzMd
        margin: 0 $mgXxs
        color: rgba($cFormQuestion, .6)
    
    .country-flag
        border-radius: 3px
        display: flex
        align-items: center

        &:hover
            cursor: pointer
            opacity: .8
        
        img
            border-radius: 3px
            max-width: 25px
    

    &.error
        .date-field
            border-color: $cNegative!important

    .state-field
            display: flex
            justify-content: center
            align-items: center

    .country-field
        .number
            display: flex
            justify-content: center
            align-items: center

.error-message
  margin-top: $mgXs
  color: $cNegative
  font-size: $fzXs

</style>