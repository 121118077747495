<template>
  <div class="preview-comp" :style="{backgroundColor: bgColor, backgroundImage: backgroundStyle, color: textColor}">
    <!-- Progress bar -->
    <div class="progressbar">
      <ProgressBar :position="current + 1" :total="fields.length"/>
    </div>

    <section class="form-body">
      
      <div class="form-brand">
        <div v-if="logoPath" class="logo">
          <img :src="logoPath" alt="" />
        </div>        
      </div>


      <main class="form-container">
          <div class="questions-container">
            <div class="question wait" v-for="field in fields" :key="field.position + '-' + field.position" :data-question-type="field.type" :data-question="field.position" >
              
              <!-- Select the field -->
              <Field :data="field" :form="form" :variables="variables" v-on:submit:value="next" @new-variable="setVariable" v-on:showSentMessage="showSentMessage" v-on:endForm="endForm" :key="'field-' + field.slug" ref="field" />
              
            </div>  

            <!-- Sent Message  -->
            <div class="endform wait" data-type="endform">
                <div class="end-form-container">
                    <div class="sent-icon"><Icon type="solid" name="paper-plane" /></div>
                    <div class="sent-message">Envio finalizado</div>
                </div>
            </div>

          </div>
      </main>

      <!-- Form nav -->
      <div class="form-nav" data-type="form-nav">
        <div class="nav-container">
          <div @click="prev" class="button-nav button-prev-question">
            <Icon name="arrow-up" />
          </div>
          <div @click="submitToPreview" class="button-nav button-next-question">
            <Icon name="arrow-down" />
          </div>
        </div>
      </div>

    </section>


  </div>
</template>

<script>
import Field from '@/components/Field.vue';
import Icon from '@/components/Icon.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import Btn from '@/components/form/Btn.vue';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin.js';



export default {
  name: 'Preview',
  components: {
    Field,
    ProgressBar,
    Btn,
    Icon  
  },
  mixins: [UtilitiesMixin],

  props: {
    fields: Array,
    form: Object,
    container: Boolean,
    demo: Boolean,
    position: {
      type: Number,
      default: 0
    },
  },

  data: function () {
    return {
      current: 0,
      variables: {}
    }
  },

  mounted: function(){
    this.watchKeyPress();
    this.forcePosition(this.position);
  },

  computed: {
    logoPath() {
      const logo = this.$parent.form.style?.logo
      if(logo){
        if(typeof logo == 'object') return "https://respondiassets.sfo3.digitaloceanspaces.com/" + logo.path
        else return logo
      }
    },
    
    backgroundStyle() {
      const background = this.$parent.form.style?.background
      let url
      if(background){
        if(typeof background == 'object') url = "https://respondiassets.sfo3.digitaloceanspaces.com/" + background.path
        else url = background
        return "url(" + url + ")"
      }
    },

    textColor(){
      const color = this.$parent.form.style?.textColor
      if(color) return color
      return null
    },

    bgColor(){
      const color = this.$parent.form.style?.bgColor
      if(color) return color
      return null
    }



  },


  watch: {
    position: function(after, before){
      this.forcePosition(after);
    }
  },


  methods: {

    setVariable(variable){
      this.$set(this.variables, variable.slug, this.formatAnswerText(variable))
    },

    endForm(){
      this.$trackEvent.mixpanel("New Respondent", {distinct_id: this.$parent.form.cus, form_id: this.$parent.form.slug});
    },

    watchKeyPress(){

      if(!this.demo){
          let self = this;
          
          window.addEventListener('keyup', function(event) {
            let currentComponentData = self.fields[self.current];
            let currentComponent = self.$refs.field[self.current];

            if(event.key === 'Enter' && event.shiftKey !== true){                          
              self.$refs.field[self.current].submit();
            }

          });

        }
      },

    submitToPreview(){
      this.$refs.field[this.current].submit();
    },

    forcePosition: function(position){
      const questions = document.querySelectorAll('.form-container .question');
      const showQuestion = document.querySelector(`[data-question="${position}"]`);
      this.hideAllQuestions()    
      this.showQuestion(showQuestion);
      this.current = position;
    },

    showQuestion(element){
      element.classList.remove('wait')
      element.classList.remove('get-out')
      element.classList.add('show')
    },

    hideQuestion(element, position){
      element.classList.remove('show')
      if(position == "down") element.classList.add('wait')
      else element.classList.add('get-out')
    },

    hideAllQuestions(){
      const questions = document.querySelectorAll("[data-question]")

      questions.forEach((element) => {
        this.hideQuestion(element, 'down')
      })
    },

    selectQuestionElement(target){
      let question;
      
      if(target == "current"){
        question = document.querySelector(`[data-question="${this.current}"]`)
      }
      
      if(target == "next"){
        let current = this.current + 1;
        question = document.querySelector(`[data-question="${current}"]`)
      }

      if(target == "prev"){
        let current = this.current - 1;
        question = document.querySelector(`[data-question="${current}"]`)
      }      

      return question;
    },

    next(current_question){
      
      if(this.current <= (this.fields.length - 2)){
        const current = this.selectQuestionElement("current");
        const next = this.selectQuestionElement("next");
        const nextFocus = this.selectQuestionElement("next").querySelector('.field-text');
        this.hideQuestion(current)
        this.showQuestion(next)
        this.setFocus(nextFocus);
        this.current = this.current + 1;
      }

    },

    prev(current_question){
      
      if(this.current > 0){
        const current = this.selectQuestionElement("current");
        const prev = this.selectQuestionElement("prev");
        const prevFocus = this.selectQuestionElement("prev").querySelector('.field-text');
        this.hideQuestion(current, 'down')
        this.showQuestion(prev)
        this.setFocus(prevFocus);
        this.current = this.current - 1;
      }
    },

    showSentMessage(){
      const current = this.selectQuestionElement("current");
      const endForm = document.querySelector('[data-type="endform"]');      
      const nav = document.querySelector('[data-type="form-nav"]');      
      this.hideQuestion(current)
      this.showQuestion(endForm)
      nav.classList.add('get-out')
    },


    setFocus(element){

      if(element){
        // Delay to fix bug on Android
        setTimeout(() => {
          element.focus({
            preventScroll: true
          })
        }, 500);
      } 

    }
  
  }

}


</script>

<style scoped lang="sass">

  .form-body
    display: flex

    @media (max-width: 800px)
      flex-direction: column
      min-height: 100%    


  .form-brand
    height: 10%
    padding: 10px
    display: flex
    justify-content: center

  .form-container
    flex: 1
    display: flex
    justify-content: center

  .form-nav
    background: rgba(0,0,0,.03)
    display: flex
    align-items: center
    justify-content: center
    z-index: 20

    @media (max-width: 800px)
      height: 60px


    @media (max-height: 400px)
      display: none

    &.get-out
      opacity: 0
      margin-top: 0
      // margin-right: -50px
      // margin-top: 50px

      // @media (max-width: 800px)
      //   margin-right: 0
      //   margin-bottom: -50px


    .nav-container
      font-size: $fzLg
      display: flex
      flex-direction: column

      @media (max-width: 800px)
        flex-direction: row


    .button-nav      
      padding: 14px
      border-radius: 50px
      transition: all .3s      

      &:hover
        background: rgba(0,0,0,.1)
        cursor: pointer
    
    .button-prev-question
      opacity: .5


  .preview-container
    width: 100%
    height: 100%
    max-width: 800px
    margin: auto
    padding-left: $mg
    padding-right: $mg

  .form-view-container
    .logo
      display: block


  .logo
    display: none
    display: block
    max-width: 120px
    height: 80px
    transition: all .3s

    @media(max-width: 800px)
      height: 100%
      max-width: 100px     

    img
      max-width: 100%
      max-height: 100%
      height: auto
      width: auto

  .progressbar
    width: 100%

  .actions-header
    display: flex
    align-items: center
    justify-content: space-between

  .back-button
    z-index: 10


  .preview-comp
    width: 100%
    height: 100%
    display: grid
    grid-template-columns: 100%
    grid-template-rows: 4px auto    
    background-size: cover


  .questions-container
    height: 100%
    width: 100%
    max-width: 700px
    // padding: 15px
    position: relative
    display: flex
    flex-direction: column
    justify-content: center

  .question
    max-height: 100%
    width: 100%
    display: flex
    flex-direction: column;
    justify-content: flex-start;
    align-items: center
    position: absolute
    //top: 0
    //left: 0
    padding: 10px 15px
    overflow: auto

    &[data-question-type="select"]
      overflow: unset

    // &:first-of-type
    //   opacity: 1
    //   margin-top: 0
    //   z-index: 10

  .wait
    margin-top: 150px
    opacity: 0
    z-index: 0
    transition: all .3s ease-out

  // .get-out, .question:first-of-type.get-out
  .get-out
    transition: all .2s ease-in
    margin-top: -150px
    opacity: 0
    z-index: 0
  
  .show
    transition: all .3s .3s ease-out
    opacity: 1
    z-index: 10
    margin-top: 0

  .fields-preview
    text-align: left
    width: 50%

    .preview
        display: block
        width: 100%
        height: 100%
        padding: 60px

  .endform    
    font-size: $fzXl
    display: flex
    height: 100%
    width: 100%
    color: $cBrand
    align-items: center
    justify-content: center

    &.show
        display: flex

    .end-form-container
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center


    .sent-icon
        font-size: $fzXxl
        margin-bottom: $mgSm            

    .sent-message
        font-size: $fzMd

</style>
