import axios from "axios";
import VueRouter from 'vue-router';



axios.interceptors.request.use(
    config => {
      if ( !config.headers.Authorization) {
        let token = localStorage.getItem('access_token');
  
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
        
      return config;
    },
    error => Promise.reject(error)
  );



axios.interceptors.response.use(function (response) {

    // if(response.data.status == "Token is Invalid" || response.data.status == "Token is Expired"){
    //   const path = window.location
    //     // window.location = "/login" + path;
    // }

    // else{
    //     return response;
    // }

  return response

}, function (error) {
    if (401 === error.response.status) {
    } else {
        return Promise.reject(error);
    }
});