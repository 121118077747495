<template>
<div>
  <div class="field-group rating-container">
    <div v-for="option in items" :key="option.value" @click="select($event.target)" class="option" :value="option">
        <span class="label">{{ option }}</span>
    </div>
  </div>
  <div v-if="error" class="error-message">{{error}}</div>        
</div>
</template>

<script>
export default {
  name: 'RatingField',
  
  data: function(){
      return {
          currentValue: null,
      }
  },

  props: {
    data: Object,
    value: String,
    error: String,
    selected: Number,
  },

  computed: {
      items() {
          return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      }
  },


  watch: {
    selected: function(){
      this.selecttByArrowKey(this.selected)
    },
  },

  methods: {

      select(selectedOption){

        this.currentValue = selectedOption.getAttribute('value');
        let options = this.$el.querySelectorAll(".option");        
        
        options.forEach(function(element){
            element.classList.remove('active');
        })        
        selectedOption.classList.add('active');

        this.$emit('update:value', this.currentValue)
        this.$emit('input-focus')

      },

      selecttByArrowKey(position){
        let options = this.$el.querySelectorAll(".option");
        this.select(options[position]);
      }

  }

}
</script>

<style scoped lang="sass">

.error-message
  margin-top: $mgXs
  color: $cNegative
  font-size: $fzXs

.rating-container
    width: 100%
    display: flex    
    gap: $mgSm

    @media (max-width: 800px)
        gap: 0


.option
    user-select: none
    box-sizing: border-box
    display: flex
    align-items: center
    justify-content: center
    flex: 1
    border: 1px solid rgba($cFormQuestion, 1)
    border-radius: 4px
    color: rgba($cFormQuestion, 1)
    margin-bottom: 16px
    font-size: 18px
    height: 42px

    @media (max-width: 500px)
        font-size: $fzXs


    @media (max-width: 800px)
        border-radius: 0
        border-right: 0
        font-size: $fzXs

        &:last-of-type
            border-right: 1px solid rgba($cFormQuestion, 1)

    .label
      pointer-events: none

    


    &.active
        font-weight: bold
        background: rgba($cFormQuestion, .1)
        border-right: 1px solid rgba($cFormQuestion, 1)
        border-width: 4px
        font-weight: bold

    
    &:hover
        cursor: pointer
        background: rgba($cFormQuestion, .1)



</style>
