<template>    
    <div v-if="items" :class="{logicTabOpen: current_tab == 'logic'}" class="editor-container">
        <!-- List -->
        <section class="questions-list">

            <div class="questions-blocks-list">
                <drop-list
                    :items="items"
                    class="list"
                    @insert="onInsert"
                    @reorder="$event.apply(items)"
                >
                    <template v-slot:item="{item}" >
                        <drag :class="{active: current == geFieldtIndex(item.slug)}" class="editor-block"  :key="item.slug" >                            
                            <QuestionBlock :data="item" :active="false" :position="geFieldtIndex(item.slug)" @click-block="setCurrent(item.slug)" @click-settings="openSettingsMenu" @click-duplicate="duplicateQuestion(geFieldtIndex(item.slug))" />
                        </drag>
                    </template>

                </drop-list>
                <button @click="addQuestion(fields.length)" class="btn add-new mt16 mb32">Adicionar campo</button>
            </div>

        </section>

        <!-- Settings -->
        <div class="question-settings" data-item="settings-menu">

            <!-- Required -->
            <div v-if="showRequired(items[current].type)" class="option-line">
                <div class="option-label">Obrigatória</div>
                <div class="option-action">
                    <SwitchField v-model="items[current].options.required" :active="items[current].options.required" />
                </div>
            </div>

            <!-- Multiple anwsors -->
            <div v-if="hasOptions(items[current].type)" class="option-line multi-select">
                <div class="option-label">Múltipla seleção</div>
                <div class="option-action">
                    <SwitchField v-model="items[current].options.multipleAnswer" :active="items[current].options.multipleAnswer" />
                </div>
            </div>

            <!-- Inline layout -->
            <div v-if="hasOptions(items[current].type) && items[current].type == 'radio'" class="option-line">
                <div class="option-label">Mesma linha</div>
                <div class="option-action">
                    <SwitchField v-model="items[current].options.multiplesPerLine" :active="items[current].options.multiplesPerLine" />
                </div>
            </div>

            <!-- Allow other option -->
            <div v-if="hasOptions(items[current].type) && items[current].type == 'radio'" class="option-line">
                <div class="option-label">Adicionar opção "outros"</div>
                <div class="option-action">
                    <SwitchField v-model="items[current].options.allowOtherValue" :active="items[current].options.allowOtherValue" />
                </div>
            </div>

            <!-- MAX character -->
            <div v-if="isQuestion(items[current].type) && hasText(items[current].type)" class="option-line" >
                <div class="option-label">Limite de caracteres</div>
                <div class="option-action">
                    <SwitchField v-model="items[current].options.maxCharacters.active" :active="items[current].options.maxCharacters.active" />
                </div>
                <div v-if="items[current].options.maxCharacters.active" class="option-data mt8">
                    <TextField v-model="items[current].options.maxCharacters.value" :data="{}" size="sm" placeholder="140"/>
                </div>
            </div>

            <!-- Only integer -->
            <div v-if="items[current].type == 'number'" class="option-line" :key="items[current].slug + 'onlyinteger-option'">
                <div class="option-label">Somente inteiros</div>
                <div class="option-action">
                    <SwitchField v-model="items[current].options.onlyInteger" :active="items[current].options.onlyInteger" />
                </div>
            </div>

            <!-- Number MinMax -->
            <div v-if="items[current].type == 'number'" class="option-line option-number" :key="items[current].slug + '-minmax-option'">
                <div class="option-label">Limite de valores</div>
                <div class="option-action">
                    <SwitchField v-model="items[current].options.minmax.active" :active="hasMinmax(items[current])" />
                </div>
                <div v-if="items[current].options.minmax.active" class="option-data mt8">
                    <div class="mt8 option-field-row">
                        <span class="option-field-row-label">Mínimo</span>                        
                        <NumberField v-model="items[current].options.minmax.min" :value="items[current].options.minmax.min" :data="{}"   size="sm" placeholder="Sem limite"/>

                    </div>

                    <div class="mt8 option-field-row">
                        <span class="option-field-row-label">Máximo</span>                        
                        <NumberField v-model="items[current].options.minmax.max" :data="{}" size="sm" placeholder="Sem limite"/>
                    </div>                    
                </div>
            </div>

            <!-- Logic -->
            <div v-if="fieldHasLogic(items[current].type)" class="option-line logic-options" :key="items[current].slug">
                <div class="option-label">Lógica <TagLabel type="beta">Beta</TagLabel></div>
                <div class="option-action">
                    <!-- <SwitchField v-model="items[current].logic.active" :active="items[current].logic.active" /> -->
                    <SwitchField v-model="items[current].logic.active" :active="hasLogic(items[current])" />
                </div>

               <div v-if="items[current].logic.active" class="option-data mt8">
                   <div v-if="items[current].logic.actions" class="action-apply mb8">
                       <span v-if="items[current].logic.actions.length == 1">Uma ação aplicada.</span>
                       <span v-if="items[current].logic.actions.length > 1">{{items[current].logic.actions.length}} ações aplicadas.</span>                    
                   </div>
                    <Btn @click.native="openTab('logic')" type="secondary" size="xs">Editar opções de lógica</Btn>
                </div>

                <div class="logic-tab">
                    <div class="logic-tab-header">
                        <Btn @click.native="closeTab('logic')" layout="icon" size="sm">Fechar <Icon name="arrow-right"/></Btn>
                        <SwitchField v-model="items[current].logic.active" :active="hasLogic(items[current])" />

                    </div>
                    <div class="logic-tab-body">
                        
                        <LogicItems v-if="isQuestion(items[current].type)" v-model="items[current].logic.actions" :item="items[current]" :actions="items[current].logic.actions" :actionsOptions="actionsOptions" @update-form="updateForm(form)" />
                        <div v-else class="no-logic">Este campo não permite lógica.</div>

                    </div>
                </div>

            </div>

            <!-- Quiz -->
            <div v-if="questionHasOptions(items[current].type)" class="option-line" >
                <div class="option-label">Pontuação <TagLabel type="new">Novo</TagLabel></div>
                <div class="option-action">
                    <Btn @click.native="modals.quiz = true" size="xs" type="secondary">Configurar</Btn>
                </div>
            </div>

            <!-- Quiz Modal -->
            <Modal v-if="modals.quiz" title="Pontuação" :active="modals.quiz" @modal-close="modals.quiz = false" >
                <QuizModal :form="form" @update-scores="updateScores($event)" :working="working.updateForm" />                
            </Modal>

            <!-- End Form -->
            <div v-if="items[current].type == 'thankyou'" class="option-line" :key="'endfield-'+items[current].slug">
                <div class="option-label">Após o envio...</div>
                <div class="option-action-fullwidth mt8">
                    <RadioField2 v-model="items[current].options.endForm.type" layout="special" :options="endFormOptions" />
                </div>
                <div class="option-data mt8">

                    <!-- Link -->
                    <div v-if="items[current].options.endForm.type == 'redirect' || items[current].options.endForm.type == 'redirect_first'" class="end-link">
                        <p class="legend mb4">Após concluir o envio, o respondente será direcionado para o link abaixo.</p>
                        <TextField v-model="items[current].options.endForm.redirect" layout="line" label="teste" :data="{}" placeholder="https://exemplo.com" />
                    </div>

                    <!-- Upload -->
                    <div v-if="items[current].options.endForm.type == 'file'" class="end-link">
                        <p class="legend mb4">Após concluir o envio, o respondente poderá baixar o arquivo selecionado.</p>
                        <UploadField v-model="items[current].options.endForm.file" :form="form" :file="items[current].options.endForm.file" @upload="uploadEndFile()" @removed="removeEndFile()" />
                    </div>

                </div>

            </div>

            <!-- show Score -->
            <div v-if="items[current].type == 'thankyou'" class="option-line show-score">
                <div class="option-label">Exibir pontuação final <Help description="Quando disponível, exibe somatório de todos os pontos obtidos (modo Quiz).<br><br>Você pode atribuir pontos nas opções de cada pergunta." width="300px" /></div>
                <div class="option-action">
                    <SwitchField v-model="items[current].options.endForm.showScore" :active="items[current].options.endForm.showScore" />
                </div>
                
            </div>

            <!-- Delete -->
            <div class="option-line">
                <div class="option-label">Deletar</div>
                <div class="option-action">
                    <Btn size="sm" type="danger" @click.native="removeQuestion(current)">Remover</Btn>
                </div>
            </div>

        </div>

        <!-- Question Feild -->
        <section class="questions-editor">
            <div class="question-options">
                <div class="question-options-header">
                    
                    <!-- Question Type -->
                    <div class="question-options-type">
                        <SelectFieldType v-model="items[current].type" :open="openSelectQuestion" :layout="`question-type-${items[current].type}`" :value="items[current].type" :options="questionTypes" @close-field-options="closeFieldOptions" @update:value="updateFieldType($event)" slug="field-type" />
                    </div>
                    
                    <!-- Question Actions -->
                    <div class="questions-options-actions">
                        <div v-if="showRequired(items[current].type)" class="option-line is-required mr16">
                            <div class="option-label mr4">Obrigatória</div>
                            <div class="option-action">
                                <SwitchField v-model="items[current].options.required" :active="items[current].options.required" />
                            </div>
                        </div>
                        <Btn @click.native="openSettingsMenu" type="secondary" size="sm"><Icon name="cog" /> Opções</Btn>                        
                    </div>
                </div>
            </div>
            <div class="editor-question-page">
                <div class="editor-logo-container" :data-logo-position="form.style.logo.position">
                    <div class="form-logo">                    
                        <FormLogo v-if="form.style.logo.path" :src="form.style.logo.path" />
                    </div>
                </div>
                <div class="editor-question-container">                
                    <Field :data="items[current]" :editable="true" v-on:save-form="saveForm" :key="items[current].slug" />
                </div>
            </div>
        </section>

        <!-- Form NAV -->
        <div class="form-nav" data-type="form-nav">
            <div class="nav-container">
                <div @click="prevQuestion" class="button-nav button-prev-question">
                    <Icon name="arrow-up" />
                </div>
                <div @click="addQuestion(current)" class="button-nav button-add-question">
                    <Icon name="plus" />
                </div>
                <div @click="nextQuestion" class="button-nav button-next-question">
                    <Icon name="arrow-down" />
                </div>
            </div>
        </div>



    </div>
</template>

<script>
import Help from './Help.vue'
import ActionRow from './ActionRow.vue'
import QuizModal from './modal/QuizModal.vue'
import Modal from './Modal.vue'
import UploadField from './form/UploadField.vue'
import RadioField2 from './form/RadioField2.vue'
import FormLogo from './FormLogo.vue'
import NumberField from './form/NumberField.vue'
import TagLabel from './TagLabel.vue'
import LogicItems from './editor/LogicItems.vue'
import SelectFieldType from './form/SelectFieldType.vue'
import Icon from './Icon.vue'
import TextField from './form/TextField.vue'
import SwitchField from './form/SwitchField.vue'
import Btn from './form/Btn.vue'
import SelectField from './form/SelectField.vue'
import { Drag, DropList } from "vue-easy-dnd";
import Field from '@/components/Field.vue';
import QuestionBlock from '@/components/editor/QuestionBlock.vue';
import { EditorMixin } from '@/mixins/EditorMixin';
import { ValidateMixin } from '@/mixins/ValidateMixin';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';
import Preview from './Preview.vue';
    export default {
        name: "NewEditorField",
        mixins: [EditorMixin, ValidateMixin, UtilitiesMixin],
        props: {
            fields: {
                type: Array,
                default: null
            },
            form: Object,
        },
        components: {
            Drag,
            DropList,
            Field,
            QuestionBlock, SelectField, Btn,
                Preview, SwitchField,
                TextField, Icon, SelectFieldType, LogicItems, TagLabel, NumberField, FormLogo, RadioField2, UploadField, Modal, QuizModal, ActionRow, Help
        },
        mounted () {
            this.items = this.fields
            // this.addSettingsAttr(this.items)
        },
        data: function() {
            return {
                questionTypes: [
                    {value: "text", label:"Resposta curta"},
                    {value: "textarea", label:"Texto longo"},
                    {value: "email", label:"E-mail"},
                    {value: "phone", label:"Telefone"},
                    {value: "date", label:"Data"},
                    {value: "url", label:"Link"},
                    {value: "cpf", label:"CPF", plan: "pro"},
                    {value: "cnpj", label:"CNPJ", plan: "pro"},
                    {value: "number", label:"Número"},
                    {value: "address", label:"Endereço", plan: null},
                    {value: "radio", label:"Multipla Escolha"},
                    {value: "imageradio", label:"Seleção de Imagem"},
                    {value: "select", label:"Seleção de lista"},
                    {value: "upload", label:"Arquivo anexo"},
                    {value: "rating", label:"Nota"},
                    {value: "message", label:"Mensagem"},
                    {value: "legaltext", label:"Termos de Uso"},
                    {value: "welcome", label:"Boas-vindas"},
                    {value: "thankyou", label:"Agradecimento"},
                ],                
                current: 0,
                items: null,
                current_tab: null,
                openSelectQuestion: false,
                modals: {
                    quiz: false
                },
                working: {
                    updateForm: false
                }
            }
        },   
        
        computed: {
            actionsOptions() {
                let options = []
                this.form.fields.forEach(field => {
                    const value = field.value ? field.value : "Adicione sua pergunta..."
                    const data = {value: field.slug, label: value}
                    options.push(data)
                })
                return options
            },

            endFormOptions(){
                return [
                    {label: 'Exibir uma mensagem', value: 'message', icon: {name: 'smile'}},
                    {label: 'Redirecionar para link', value: 'redirect_first', icon: {name: 'link'}, plan: 'solo'},
                    {label: 'Botão para link', value: 'redirect', icon: {name: 'hand-pointer'}, plan: 'solo'},
                    {label: 'Botão para arquivo', value: 'file', icon: {name: 'download'}, plan: 'solo'},
                ]
            }

        },

        watch: {
            fields(value) {
                this.items = value                
            }
        },

        methods: { 
            
            showRequired(type){
                const remove = ["message", "thankyou", "welcome", "legaltext"]
                if(remove.includes(type)) return false
                return true
            },

            updateFieldType(){
                if(this.items[this.current].type == "legaltext"){
                    this.items[this.current].value = this.items[this.current].value ? this.items[this.current].value : "Termos de uso"
                    this.items[this.current].options.required = true
                    this.items[this.current].logic = {active: false, actions: []}
                }
            },

            async updateScores(scores){
                this.form.scores = scores
                this.working.updateForm = true
                await this.updateForm()
                this.working.updateForm = false
            },

            async updateForm(){
              const form = await this.$parent.updateForm(this.form)
              return form
            },

            uploadEndFile(){
                this.$parent.updateForm(this.form)
            },

            removeEndFile(){
                this.$parent.updateForm(this.form)
            },

            closeFieldOptions(){
                this.openSelectQuestion = false
            },

            openTab(name){
                if(name == "logic") this.current_tab = "logic"
                this.saveForm()
            },

            closeTab(name){
                if(name == "logic") this.current_tab = null
                this.saveForm()
            },

            hasLogic(field){
                if(!field.logic.active) return null
                if(field.logic.actions.length == 0) return null
                return true
            },          

            hasMinmax(field){
                if(field.options.minmax.active) return true
                return false
            },          

            saveForm(){
                this.$emit('save-form')
            },

            onInsert(event) {
                this.items.splice(event.index, 0, event.data);
            },

            addQuestion(index){
                this.openSelectQuestion = true
                const newQuestion = {
                      "type": "text",
                      "value": "",
                      "description": [],
                      "items": [],
                      "position": this.fields.length,
                      "slug": this.createRandId(),
                      "options": {
                            required: null,
                            maxCharacters: {
                                active: null,
                                value: 140
                            },
                            minmax: {
                                active: false,
                                min: null,
                                max: null,  
                            },
                            onlyInteger: false,
                            format: null,
                            multipleAnswer: null,
                            multiplesPerLine: null,
                            endForm: {type: ["message"], redirect: null, file: null}                      
                      },
                      "logic": {
                          active: false,
                          actions: []
                      }
                    }

                this.pushQuestionAfterIndex(newQuestion, index)
                this.$trackEvent.mixpanel("Add Question", {form: this.form.slug})

            },

            pushQuestionAfterIndex(newQuestion, index){
                if(index == this.fields.length){
                    const isLastThankyouPage = this.isLastThankyouPage()
                    if(isLastThankyouPage) this.fields.splice(index - 1, 0, newQuestion)
                    else this.fields.splice(index, 0, newQuestion)
                    this.current = index -1                     
                }

                else{
                    this.fields.splice(index + 1, 0, newQuestion)
                    this.current = index + 1
                } 
            },


            duplicateQuestion(index){
                const question = this._.cloneDeep(this.items[index])
                question.slug = this.createRandId()
                question.items = this.generateRandSlugsToItems(question.items)
                this.pushQuestionAfterIndex(question, index)
                setTimeout(() => {this.current = index + 1}, 50) //// Evita que o clique do pai defina o current field
            },
            

            generateRandSlugsToItems(items){
                let newItems = []
                for (const index in items) {
                    newItems[index] = Object.assign({}, items[index])
                    newItems[index].key = this.createRandId()
                }
                return newItems
            },

            isLastThankyouPage(){
                return this.fields.at(-1).type == "thankyou" ? true : false
            },

            removeQuestion(index){
                if((index + 1) == this.items.length) this.current = this.items.length - 2 // Se for último da lista, pula pro anterior
                this.fields.splice(index, 1);
            },

            openSettingsMenu(){
                const settingsMenu = document.querySelector('[data-item="settings-menu"]')
                settingsMenu.classList.toggle('active')
            },

            closeSettingsMenu(){
                const settingsMenu = document.querySelector('[data-item="settings-menu"]')
                settingsMenu.classList.add('close')
            },

            setCurrent(item){
                const index = this.geFieldtIndex(item);
                this.current = index;
            },

            prevQuestion(){
                this.closeTab('logic')
                if(this.current > 0) this.current = this.current - 1
            },
            nextQuestion(){
                this.closeTab('logic')
                if(this.current < (this.items.length - 1)) this.current = this.current + 1
            },
        }        
    }
</script>

<style lang="sass" scoped>



.form-nav
    background: $cG2
    display: flex
    align-items: center
    justify-content: center
    z-index: 20

    @media (max-width: 800px)
        height: 60px


    @media (max-height: 400px)
        display: none

    &.get-out
        opacity: 0
        margin-top: 0


.nav-container
    font-size: $fzLg
    display: flex
    flex-direction: column

    @media (max-width: 800px)
        flex-direction: row


.button-nav      
    padding: 14px
    border-radius: 50px
    transition: all .3s      

    &:hover
        background: rgba(0,0,0,.1)
        cursor: pointer

.button-prev-question
    opacity: .5

.button-add-question
    color: $cPrimary




.editor-container
    width: 100%
    // height: calc(100% - 60px)
    height: calc(100vh - 60px)
    color: #000
    display: flex
    // position: relative

    &.logicTabOpen
        .questions-list
            // max-width: 0px!important
            // min-width: 0px!important
            // transition: all .3s
            // overflow: hidden
            // position: absolute
            margin-left: -400px
            transition: all .3s

        .question-settings
            width: 900px
            transition: all .3s
        
        .logic-tab
            display: block!important
            right: -50px!important
            padding-right: 50px
            transition: all .3s
            box-shadow: 0 0 60px rgba(0,0,0,.25)

    .questions-list
        background: $cG1
        width: 350px
        // min-width: 300px
        display: flex;
        flex-direction: column
        align-items: center
        justify-content: space-between
        max-height: 100%
        transition: all .3s .3s        
        // background: blue



        .questions-blocks-list
            width: 100%
            border-right: 2px solid $cG2
            overflow: auto
            scrollbar-color: $cG3 $cG1 //Fix for fireFox

            &::-webkit-scrollbar 
                width: 10px
                // background: blue

            &::-webkit-scrollbar-track
                background: $cG1
            
            &::-webkit-scrollbar-thumb 
                background-color: $cG3
                border-radius: 6px;
                // border: 3px solid $cG3


        .question-block-list
            overflow: auto
            width: 100%

        .list
            width: 100%            


        .thankyou-block
            margin-bottom: 0


    .editor-block
        &:hover                
            .addNewHere
                height: 25px
                

        .addNewHere
            display: flex
            align-items: center
            justify-content: center
            text-align: center
            font-size: $fzXxs
            color: $cPrimary
            height: 0
            overflow: hidden
            transition: all .5s .3s


    .question-settings
        padding: $mgSm
        display: none
        background: #fff
        border-right: 10px solid $cG2
        width: 20%
        min-width: 200px
        font-size: $fzXs
        color: $cG5
        position: relative
        overflow: hidden
        transition: all .3s .3s

        &.active
            display: block

        .option-line
            padding: $mgXs $mgXs
            display: flex
            justify-content: space-between
            align-items: center
            flex-wrap: wrap
            border-bottom: 1px solid $cG2
            // width: 100%


            .option-action-fullwidth
                width: 100%


        .option-field-row
            display: flex
            align-items: center

            .option-field-row-label
                min-width: 100px
            

        .option-data
            flex-basis: 100%

        &.logicTabOpenopen
            min-width: 900px
            overflow: auto


        .logic-tab
            display: block
            position: absolute
            right: -9999px
            top: 0
            width: 100%
            height: 100%
            background: #fff
            overflow: auto
            transition: all .6s

            .logic-tab-header
                display: flex
                align-items: center
                justify-content: space-between
                background: $cG1
                padding: $mgXs $mgSm                

            .logic-tab-body
                padding: $mgXs $mgSm


    .questions-editor
        flex: 1
        overflow: hidden
        display: flex
        align-items: center
        flex-direction: column        
        background: $cG2
        

        .editor-question-page
            margin: 0 $mg $mg $mg
            // margin: $mg
            border-radius: 0 0 10px 10px
            box-shadow: 0 6px 8px rgba(0,0,0,.15)
            padding: $mg            
            width: 95%
            height: calc(95% - 60px)
            align-items: center
            display: flex;
            flex-direction: column  
            // justify-content: center      
            background-size: cover
            background-color: $cFormBackground
            background-image: $formBgImage
            overflow: auto

            .editor-logo-container
                height: 60px
                width: 100%
                display: flex

                &[data-logo-position="center"]
                    justify-content: center

                &[data-logo-position="right"]
                    justify-content: flex-end
                
                .form-logo
                    max-width: 80px
                    max-height: 80px

                
        

        // border-left: 10px solid $cG2

        .question-options
            width: 95%
            background: $cG2
            // height: 60px
            display: flex
            // align-items: center
            // justify-content: center

            .question-options-header
                box-shadow: 0 6px 8px rgba(0,0,0,.15)
                margin-top: $mg
                padding: $mgSm
                width: 100%
                background: $cG1
                border-radius: 10px 10px 0 0
                border-bottom: 1px solid $cG2
                display: flex
                align-items: center
                justify-content: space-between

                .questions-options-actions
                    display: flex
                    align-items: center
                    justify-content: flex-end
                    flex: 1
                
                .option-line 
                    color: $cG5
                    font-size: $fzXxs   
                    display: flex
                    align-items: center

            .question-options-type
                width: 220px


        .editor-question-container            
            width: 100%
            max-width: 550px
            margin-top: 50px


    .add-new
        background: rgba($cPrimary, .15)
        color: $cPrimary
        border: 1px solid $cPrimary
        border-radius: 15px
        padding: $mgXxs $mgXs
        width: 80%
        cursor: pointer
        margin-left: auto
        margin-right: auto
        display: block

        &:hover
            background: rgba($cPrimary, .25)


        


</style>