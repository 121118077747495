<template>
    <div class="logicItem-comp">        

        <div class="actions-header">
            <div class="col-1"><SelectField firstAsDefault :options="targetList" v-model="item.logic.target" width="210px" ></SelectField></div>
            <div class="col-2">Pula para...</div>
        </div>


        <div v-for="(action, index) in actionsList" :key="'action-'+index" :ref="'logic-'"  class="action-row mb16">
            <div class="col-1 mr16">
                <div class="test-condition mr16">
                    <SelectField v-model="action.condition[0].type" :options="operationsList" />
                </div>
                <div v-if="showValue(action.condition[0].type)" class="test-value">
                    <TextField v-if="action.condition[0].type.includes('date_')" :data="{}" size="sm" type="date"  v-model="action.condition[0].data.enter" data-logic="enter" />
                    <div v-else-if="action.condition[0].type.includes('between')" class="row score-between">
                        <TextField :data="{}" type="number"  v-model="action.condition[0].data.enter" data-logic="enter" placeholder="0" />
                        <span class="mr8 ml8">e</span>
                        <TextField :data="{}" type="number"  v-model="action.condition[0].data.enter2" data-logic="enter2" placeholder="0"/>
                    </div>
                    <SelectField v-else-if="item.type == 'legaltext'" class="exit-select" v-model="action.condition[0].data.enter"  data-logic="enter" :options="customValuesOptions()" />
                    <TextField v-else :data="{}" size="sm"  placeholder="Valor" v-model="action.condition[0].data.enter" data-logic="enter" />
                </div>
                <div class="test-icon">
                    <Icon name="hand-point-right" />
                </div>
            </div>

            <div class="col-2">
                <div class="test-exit">
                    <SelectField class="exit-select" data-logic="exit" v-model="action.condition[0].data.exit" :options="actionsOptions" />
                </div>
                <div class="test-close">
                    <Btn @click.native="removeAction(index)" size="xs" layout="remove"></Btn>
                </div>
            </div>


        </div>

        <div class="label mb8">Adicionar nova regra:</div>
        <div v-if="item" class="new-action">
            <div class="col-1 mr16">
                <div class="test-condition mr16">
                    <SelectField v-if="item.type == 'legaltext'" value="is" disabled :options="operationsList" />
                    <SelectField v-else v-model="operation" :options="operationsList" />
                </div>
                <div class="test-value">
                    <TextField v-if="operation.includes('date_')" :data="{}" size="sm" type="date" v-model="enter" data-logic="enter" />
                    <div v-else-if="operation.includes('between')" class="score-between row">
                        <TextField :data="{}" size="sm" type="number" v-model="enter" data-logic="enter" placeholder="0" />
                        <span class="mr8 ml8">e</span>
                        <TextField :data="{}" size="sm" type="number" v-model="enter2" data-logic="enter2" placeholder="0" />
                    </div>
                    <SelectField v-else-if="item.type == 'legaltext'" firstAsDefault class="exit-select" v-model="enter" data-logic="enter" :options="customValuesOptions()" />
                    <TextField v-else :data="{}" size="sm" placeholder="Valor" v-model="enter" data-logic="enter" />
                </div>
                <div class="test-icon">
                    <Icon name="hand-point-right" />
                </div>
            </div>

            <div class="col-2">
                <div class="test-exit mr16">
                    <SelectField class="exit-select" data-logic="exit" v-model="exit" :options="actionsOptions" />
                </div>
                <div class="test-close">
                    <Btn @click.native="addAction($event)" type="outline" size="sm" >Adicionar</Btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Btn from '../form/Btn.vue'
import SelectField from '../form/SelectField.vue'
import TextField from '../form/TextField.vue'
    export default {
        components: { TextField, SelectField, Btn },
        name: "LogicItems",
        props: {
            item: Object,
            actions: Array,
            actionsOptions: Array
        },

        data() {
            return {
                enter: null,
                enter2: null,
                exit: null,
                actionsList: this.actions,
                operation: "contain",
                targetList: [
                    {label: "Se a resposta...", value: "answer_value"},
                    {label: "Se a pontuação atual...", value: "score_value"},
                ],
                defaultOperationsList:[
                    {label: "É exatamente", value: "is"},
                    {label: "É diferente de", value: "not_is"},
                    {label: "Contém", value: "contain"},
                    {label: "Não contém", value: "not_contain"},
                    {label: "Data é", value: "date_is"},
                    {label: "Data antes de", value: "date_before"},
                    {label: "Data depois de", value: "date_after"},
                    {label: "Número Maior que", value: "bigger_then"},
                    {label: "Maior ou igual que", value: "bigger_then_or_equal"},
                    {label: "Número menor que", value: "lower_then"},
                    {label: "Menor ou igual que", value: "lower_then_or_equal"},
                    {label: "Qualquer valor", value: "any"},
                    {label: "Nenhuma valor", value: "empty"},
                ], 
                scoreOperationsList:[
                    {label: "É entre", value: "score_between"},
                    // {label: "É maior que", value: "score_bigger_then"},
                ] 
            }
        },


        computed: {
            operationsList() {
                this.item.logic.target
                if(this.item.logic.target == "score_value") return this.scoreOperationsList
                return this.defaultOperationsList
            },
  
        },

        watch: {

            operationsList(newValue) {
                this.actionsList = []
                this.enter = null
                this.enter2 = null
                this.exit = null                
                this.operation = newValue[0].value
            }
        },

        methods: {

            /**
             * If the question has fixed options, like radio fields,
             * return the list of possible options to select
             */
            customValuesOptions(){
                if(this.item.type == "legaltext"){
                    return [
                        {label: "Eu aceito", value: "yes"},
                        {label: "Não aceito", value: "no"}
                    ]
                }

                return null
            },

            showValue(operation) {
                if(operation == "any" || operation == "empty") return false
                return true
            },

            addAction(){
                const action = {
                    type: "jump",
                    condition: [{
                        type: this.operation,
                        data: {
                            enter: this.enter,
                            enter2: this.enter2,
                            exit: this.exit
                        }
                    }]
                }
                this.actionsList.push(action)
                this.enter = null
                this.enter2 = null
                this.exit = null
                this.$emit('input', this.actionsList)
                this.$emit('update-form')
                this.$trackEvent.mixpanel("Add Logic Jump", {type:this.operation, distinct_id: this.$parent?.form?.cus, form_id: this.$parent.form.slug})

            },


            removeAction(actionIndex){
                this.actionsList.splice(actionIndex, 1)
            },
        },
        
    }
</script>

<style lang="sass" scoped>

.action-row,
.new-action
    display: flex    
    color: #000

    .close
        min-width: 30px        
        max-width: 30px        

    .content
        display: flex
        width: 100%

.actions-header
    display: flex
    padding: $mgSm 0
    color: $cPrimary

    .col-2
        padding-left: $mgSm

.col-1, .col-2
    width: 50%
    display: flex
    align-items: center   
    flex: 1.5

.col-2
    flex: 1

.action-row,
.new-action
    // border-bottom: 1px solid $cG2
    padding-bottom: $mgSm
    display: flex
    align-items: center
    max-width: 100%

    .field-tag
        font-size: 9px
        border: 1px solid $cPrimary
        border-radius: 3px
        height: 18px
        min-width: 18px
        max-width: 18px
        margin-right: $mgXs
        display: flex
        align-items: center
        justify-content: center
        color: $cPrimary
        font-weight: bold

    .test-index
        border-radius: 20px
        border: 1px solid $cG4
        color: $cG4
        padding: 6px
        min-width: 20px
        height: 20px        
        font-size: 10px
        display: flex
        align-items: center
        justify-content: center
        margin-right: $mgXs

    .test-condition
        width: 250px
    
    .test-value
        width: 200px
        margin-right: $mgXxs
        display: flex
        align-items: center

        .field-text
            border: 0
            border-bottom: 2px solid $cPrimary!important
            color: $cPrimary

    .test-icon
        width: 40px
        padding: 0 $mgSm
        font-size: $fzMd
    
    .test-exit
        display: flex
        align-items: center

        .exit-select
            width: 265px

.new-action
    padding-top: $mgSm
    border-top: 2px dashed $cG3

    .test-exit
        .exit-select
            width: 190px


    
    .test-close
        min-width: 30px
    // border-radius: 4px



.action-label
    display: flex
    // align-items: center
    flex-direction: column

.field-label
    font-size: $fzXs
    color: $cG4

.action-label-value
    min-width: 160px
    max-width: 160px

.action-label-select
    width: 100%

    .select-field
        background: #fff


</style>