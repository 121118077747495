import { render, staticRenderFns } from "./ImageRadioField.vue?vue&type=template&id=04c411fa&scoped=true&"
import script from "./ImageRadioField.vue?vue&type=script&lang=js&"
export * from "./ImageRadioField.vue?vue&type=script&lang=js&"
import style0 from "./ImageRadioField.vue?vue&type=style&index=0&id=04c411fa&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c411fa",
  null
  
)

export default component.exports