<template>
    <div class="options-editor-container" :data-layout-type='layoutType' :data-multi="multi">
        <div class="options-list" :data-scroll="scroll">
            <div v-for="(option, index) in items" :key="option.key" class="option" :value="option.value">
                <div class="letter mr8">{{letters[index]}}</div>
                <input v-if=(option) ref="input" class="input" v-on:input="changeValue($event.target, index)" :value="getOptionValue(option)">
                <Btn @click.native="removeItem(index)" class="btn-remove" layout="icon" type="danger" size="xs"><Icon name="trash-alt" /></Btn>
            </div>
            <div v-if="showOther" class="option other-option">
                <div class="letter mr8">{{letters[items.length]}}</div>
                <div class="input">Outro</div>
            </div>
        </div>

        <div class="new-option mt16">
            <TextField @keyup.enter.native="addItem" :data="{}" placeholder="Novo item..." v-model="newValue" class="mr16" />
            <Btn @click.native="addItem" size="sm">Adicionar</Btn>
        </div>

    </div>
</template>

<script>
    import TextField from '@/components/form/TextField.vue'
    import Icon from '@/components/Icon.vue'
    import Btn from '@/components/form/Btn.vue'
    import { UtilitiesMixin } from '@/mixins/UtilitiesMixin'

    export default {
        name: "RadioFieldEditor",
        mixins: [ UtilitiesMixin ],
        props: {
            data: Array,
            inline: Boolean,
            multi: Boolean,
            showOther: Boolean
        },
        components: {
            Btn, Btn, Icon, TextField
        },        
        computed: {
            items(){
                return this.data
                return this.data.filter(n => n)
            },
            letters(){
                return ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az", "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz", "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz","aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az", "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz", "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz","aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az", "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz", "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz","aa","ab","ac","ad","ae","af","ag","ah","ai","aj","ak","al","am","an","ao","ap","aq","ar","as","at","au","av","aw","ax","ay","az", "ba","bb","bc","bd","be","bf","bg","bh","bi","bj","bk","bl","bm","bn","bo","bp","bq","br","bs","bt","bu","bv","bw","bx","by","bz", "ca","cb","cc","cd","ce","cf","cg","ch","ci","cj","ck","cl","cm","cn","co","cp","cq","cr","cs","ct","cu","cv","cw","cx","cy","cz"]            
            },
            
            layoutType(){
                if(this.inline) return "inline"
                return null
            }      
        },

        data() {
            return {
                newValue: null,
                scroll: false
            }
        },

        watch: {
            items(value) {
                if(value.length > 5) this.scroll = true
                else this.scroll = false                
            }
        },

        mounted () {
            
            
        },

        methods: {

            getOptionValue(option){        
                if(option.label) return option.label // ::Fix:: Convert old array options to Object options
                return option
            },

            addItem(){
                if(this.newValue){
                    this.items.push({label: this.newValue, key: this.createRandId()})
                    this.newValue = null
                }
            },

            removeItem(index){
                this.items.splice(index, 1);
            },

            changeValue(target, index){
                if(!this.items[index].hasOwnProperty('label')) this.items[index] = {label: target.value, key: this.createRandId()}
                else this.items[index].label = target.value
                if(target.value == '' || target.value == null) this.removeItem(index)
                this.$emit('input', this.items)
                this.$emit('update:value', this.items)                
            }

        },
    }
</script>

<style lang="sass" scoped>

.option
    display: flex
    align-items: center
    border: 1px solid rgba($cFormQuestion, 1)
    border-radius: 4px
    padding: $mgXxs $mgXs
    padding-right: $mgXxs
    padding-left: $mgXs
    margin-bottom: $mgSm

    .input
        background: transparent
        color: rgba($cFormQuestion, 1)
        font-size: $fzSm
        border: none
        width: 100%
        min-height: 20px
        min-width: 20px

        &:hover, &:focus
            outline: none
            background: rgba($cFormQuestion, .1)        

    .letter
        text-transform: uppercase;
        font-size: $fzXs
        padding: 4px 6px
        +glassStyle($cFormQuestion)
        color: rgba($cFormQuestion, 1)
        border-radius: 16px
        transition: all 1s

        [data-multi="true"] &
            border-radius: 2px

    .icon
        display: inline-block
        height: 10px
        width: 10px
        border: rgba($cFormQuestion, 1) 2px solid
        border-radius: 14px
        margin-right: 8px

    .btn-remove
        opacity: 0


    &.active
        font-weight: bold
        background: rgba($cFormQuestion, .1)


        .icon
            background: rgba($cFormQuestion, 1)
    
    &:hover
        cursor: pointer
        //background: rgba($cBrandA, .1)
        
        .icon
            background: rgba($cFormQuestion, 1)

        .btn-remove
            opacity: 1

    [data-layout-type='inline'] &
        display: inline-flex
        margin-right: $mgSm

        .input
            display: inline-flex
            width: 100%
            // min-width: 70px
            // max-width: 100%



.new-option
    display: flex
    align-items: center

.other-option 
    .input
        opacity: .6
        &:hover
            background: transparent

</style>