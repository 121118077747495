<template>
    <div class="progressbar" :data-layout="layout" :data-type="type" :data-status="status">
        <div ref="bar" class="complete" :style="{backgroundColor: color}"></div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        position: Number,
        total: Number,
        layout: String,
        color: String,
        type: String
    },
    
    mounted: function () {
        this.setCompleteStatus();
    },

    data: function(){
        return {
            progress: this.getProgress()
        }
    },

    computed: {
        status() {
            if(this.progress > 90) return "danger"
            if(this.progress > 75) return "warning"
            return "ok"
        }
    },

    

    methods: {
        getProgress(){
            return (this.position / this.total) * 100;
        },

        setCompleteStatus(){
            const complete = this.$refs.bar
            complete.style.width = this.progress + '%';
        }
    },

    watch: {        

        position: function () {
            this.progress = this. getProgress();
            this.setCompleteStatus();            
        },

    }
}
</script>

<style scoped lang="sass">
    .progressbar
        display: block
        height: 4px
        width: 100%
        background: rgba(255,255,255,.3)
        border-radius: 10px
        overflow: hidden

        .complete
            height: 100%
            width: 0%
            background: var(--formColor, $cPrimary)
            transition: all 1s ease-out

        &[data-layout="clean"]
            background: $cG2
            height: 6px

            .complete
                background-color: $cPrimary

        &[data-type="scale"]
            
            &[data-status="danger"]
                .complete
                    background-color: $cNegative
            
            &[data-status="warning"]
                .complete
                    background-color: $cWarning

    

</style>