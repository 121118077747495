<template>
    <div class="question-block" :data-type="data.type" @click="$emit('click-block')">

        <div class="number">{{position + 1}} </div>
        <div class="content">
            <div class="title">{{limit(questionValue, 35)}}</div>
            <div v-if="data.description" class="description">
                <div class="question-type">{{translateFieldType(data.type)}}</div>
                <!-- {{limit(data.description[0], 25)}} -->
            </div>
        </div>
        <div class="actions">
            <div @click="$emit('click-duplicate')" class="icon icon-settings mr8"><Icon name="copy" type="regular" /></div>
            <div @click="openSettings" class="icon icon-settings"><Icon name="cog" /></div>
        </div>

    </div>
</template>

<script>
    import { EditorMixin } from '@/mixins/EditorMixin';
    import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

    export default {
        name: "QuestionBlock",
        mixins: [EditorMixin, UtilitiesMixin],
        props: {
            data: Object,
            position: Number,
        },
        computed: {
            questionValue() {
                if(this.data?.value) return this.data.value.replace(/\{\{[^\}]+\}\}/g, `____`)
            }
        },
        methods: {
            openSettings() {
                this.$emit('click-settings')
            }
        },
    }
</script>

<style lang="sass" scoped>
.question-block
    background: #fff
    width: 100%
    height: 65px
    display: flex
    color: #000
    display: flex
    align-items: center
    line-height: 110%
    border-bottom: 1px solid $cG2
    color: $cG5

    .active &
        background: #fff
        color: #000

        .number
            border-left: 4px solid $cG3

    &:hover
        background: #fff

        .actions .icon
            opacity: 1

.question-type
    font-size: 12px
    border-radius: 4px
    background: rgba(#F50057, .15)
    color: #F50057
    padding: 1px 8px
    display: inline-block

.number
    display: flex
    align-items: center
    justify-content: center
    width: 28px
    height: 100%
    padding-left: 4px
    border-left: 4px solid transparent    
    margin-right: $mgSm


.content
    flex: 1

    .title
        font-size: $fzXs

    .description
        font-size: $fzXxs
        color: rgba(#000000, .7)

.actions
    display: flex
    justify-content: center
    align-items: flex-end
    width: 50px
    color: $cG3

    .icon
        cursor: pointer
        opacity: 0

[data-type]
    .active & .icon
        opacity: 1


[data-type='text']
    .active & .number
        border-color: $cText
        color: $cText

    .question-type
        +glassStyle($cText)

[data-type='textarea']
    .active & .number
        border-color: $cTextarea
        color: $cTextarea

    .question-type
        +glassStyle($cTextarea)

[data-type='email']
    .active & .number
        border-color: $cEmail
        color: $cEmail

    .question-type
        +glassStyle($cEmail)

[data-type='url']
    .active & .number
        border-color: $cUrl
        color: $cUrl

    .question-type
        +glassStyle($cUrl)

[data-type='cpf'],
[data-type='cnpj']
    .active & .number
        border-color: $cDocument
        color: $cDocument

    .question-type
        +glassStyle($cDocument)

[data-type='select']
    .active & .number
        border-color: $cSelect
        color: $cSelect

    .question-type
        +glassStyle($cSelect)


[data-type='radio']
    .active & .number
        color: $cRadio
        border-color: $cRadio
    
    .question-type
        +glassStyle($cRadio)



[data-type='rating']
    .active & .number
        color: $cRating
        border-color: $cRating

    .question-type
        +glassStyle($cRating)

[data-type='upload']
    .active & .number
        color: $cUpload
        border-color: $cUpload

    .question-type
        +glassStyle($cUpload)

[data-type='date']
    .active & .number
        color: $cDate
        border-color: $cDate

    .question-type
        +glassStyle($cDate)


[data-type='legaltext'],
[data-type='welcome'],
[data-type='thankyou'],
[data-type='message']
    .active & .number
        color: $cMessage
        border-color: $cMessage

    .question-type
        +glassStyle($cMessage)




        

</style>