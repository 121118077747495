<template>
    <div>
        <div v-for="(item, index) in currentValue" :key="item.name" class="file-item mb16">
            <UploadField :form="form" :file="currentValue[index]" :layout="layout" v-on:upload="uploadFile($event)" v-on:removed="fileRemoved(index)" />
        </div>
        <UploadField :form="form" :layout="layout" v-on:upload="uploadFile($event)" />

        <div v-if="error" class="error-message mt16">{{error}}</div>

    </div>
</template>

<script>
    import UploadField from './UploadField.vue'
    
    export default {
        name: "FilesListField",
        components: { UploadField },
        props: {
            form: Object,
            error: String,
            layout: String,
        },
        data() {
            return {
                currentValue: []
            }
        },

        methods: {
            uploadFile(event) {
                const data = {
                    filename: event.filename,
                    type: event.type,
                    path: event.path,
                    size: event.size,
                    id: event.id,
                }
                this.currentValue.push(data)                    
                this.$emit('update:value', JSON.stringify(this.currentValue))   
                this.$emit('input-focus')
         
            },

            fileRemoved(index){
                this.currentValue.splice(index, 1)
                if(this.currentValue.length < 1) this.$emit('update:value', [])                             
                else this.$emit('update:value', JSON.stringify(this.currentValue))                
                this.$emit('input-focus')
           
            }
        },
        
    }
</script>

<style lang="sass" scoped>
.error-message
    font-size: $fzXs
    color: $cNegative

</style>