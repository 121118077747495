<template>
  <div class="field-group" :data-layout="layout" :data-size="size" :data-editable="editable" :data-content-mode="contentMode" :class="{error: error}">
      <textarea
        data-hj-allow
        rows="1"
        :ref="data.slug"
        :id="data.slug + '-input'"
        @keydown.enter.exact.prevent
        v-bind:value="value"
        v-on:input="changeValue($event.target)"        
        v-on:focus="$emit('input-focus', $event.target.value)" 
        class="field-text"
        :placeholder="placeholderValue">
      </textarea>  

      <div v-if="error" class="error-message">{{error}}</div>
      <div class="legend mt8">Aperte <strong>Shift</strong> + <strong>Enter</strong> para quebrar a linha.</div>

  </div>

</template>

<script>
export default {
  name: 'TextareaField',
  props: {
    data: Object,
    value: String,
    placeholder: String,
    layout: String,
    size: String,
    contentMode: Boolean,
    slug: String,
    error: String,
    editable: Boolean,
    noResize: Boolean
  },

  computed: {
    placeholderValue() {
      if(this.placeholder) return this.placeholder
      else if(this.data.placeholder) return this.data.placeholder
      else return 'Sua resposta...'
    },

  },

  mounted () {
    this.resizeTextarea(this.$refs[this.data.slug])
  },
  

  methods: {
    resizeTextarea: function(element) {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
        if(element.scrollHeight > 100 && this.noResize == false){
            element.style.fontSize = "16px";
        }
    },

    changeValue: function(element){
        this.$emit('input', element.value)
        this.$emit('update:value', element.value)
        this.resizeTextarea(element);
    }

  }

}
</script>

<style scoped lang="sass">


.field-group
  width: 100%

.field-text
  font-size: $fzSm
  padding: 8px 16px
  border: 1px solid rgba($cFormQuestion, 1)
  border-radius: 4px
  min-height: 42px
  width: 100%
  color: rgba($cFormQuestion, 1)
  resize: none
  overflow: hidden
  line-height: 150%
  // transition: all .3s

  .error &
    border-color: $cNegative

.error-message
  margin-top: $mgXs
  color: $cNegative
  font-size: $fzXs

.legend
    font-size: $fzXxs
    color: rgba($cFormQuestion, .5)



[data-editable='true']
  .field-text
    border: 0
    padding: $mgXxs 0
    font-family: $sans
    color: rgba($cFormText, 1)
    min-height: unset
    background: transparent

    &::placeholder
      color: rgba($cFormText, .5)
      font-style: italic
      

    &:focus
      outline: none

    &:hover
      background: rgba($cFormText, .1)
      

  .legend
    display: none

[data-size='lg']
  .field-text
    font-size: $fzLg
    font-weight: bold


[data-layout='big']

  .field-text
      font-size: 22px
      padding: 8px 0
      border: 0
      border-bottom: 1px solid
      border-color: rgba($cFormQuestion, 1)
      width: 100%
      color: rgba($cFormQuestion, 1)
      background: transparent
      border-radius: 0

      &:focus
          outline: 0
          border-color: rgba($cFormQuestion, 1)
          opacity: 1

      &::placeholder
          color: rgba($cFormQuestion, .6)


      .smallFont &
          font-size: $fzSm !important

  &.error
      .field-text
          border-color: $cNegative!important

[data-content-mode="true"]
  .field-text
    min-height: 120px
    // font-size: $fzXs !important







</style>
