<template>
  <div id="app" >
    <router-view/>
  </div>
</template>

<script>
import Vue from 'vue'


export default {

  mounted () {   
    
  },
  
}
</script>

<style lang="scss">

// *:focus{
//   border: 4px solid red!important
// }

.hide{

  &:not(.fade){
    display: none;
  }

  &.fade{
    opacity: 0;
    pointer-events: none;
  }
}

.show{  

  &:not(.fade){
    display: unset;
  }

  &.fade{
    opacity: 1;
    pointer-events: unset;    
  }
}

.fade{
    transition: all .3s
}


.noActive{
  pointer-events: none;
  opacity: 0;
  transition: all .3s
}





.click{
  cursor: pointer;
}

.light{
  color: $cG5;
}

*{
  box-sizing: border-box;
  // font-family: $sans;
}

html {
  scroll-behavior: smooth;
}

html, body{
  height: 100%;
  margin: 0;
  padding: 0;
  // overflow: hidden;
}

a, a:visited{
  color: $cBody;
}

p{
  line-height: 120%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.container{
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $mgSm;
  padding-right: $mgSm;

  &.container-sm{
    max-width: 600px;
  }
}

.container-fluid{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: $mgSm;
  padding-right: $mgSm;
}

.container-lg{
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: $mgSm;
  padding-right: $mgSm;

}

.title-xl{
    font-size: $fzXl
}

.title-lg{
    font-size: $fzLg

}

.title{

    font-size: $fzMd    
}

.title-sm{

    font-size: $fzSm
}

.title-xs{
    font-size: $fzXs

}

.text-sm{
    font-size: $fzXs

}

.link{
  color: $cPrimary;
  cursor: pointer;

  &:visited{
    color: $cPrimary;
  }
}

body[data-modal="open"]{
  overflow: hidden;
}

.row{
  display: flex;
  align-items: center;
}


</style>
