<template>
    <div class="btn-editor-container">
        <div class="btn-container" :data-size="size" :icon="icon" :data-theme="getTheme" >

            <div class="btn">
                <span v-if="icon == 'end'" class="arrow" contenteditable="false">✔&#xFE0E;</span>
                <span 
                    ref="btnLabel"
                    class="btn-label"
                    v-on:input="editContent"
                    contenteditable="true">
                </span>            
                <span v-if="icon == 'start'" class="arrow">→&#xFE0E;</span>
            </div>

        </div>
        <div v-if="icon == 'start'" class="legend mt16"><Icon name="hand-pointer" /> Este botão é demonstrativo, mas você pode alterar o texto. <br><strong>Crie perguntas</strong> pelo painel ao lado.</div>
        <div v-else class="legend mt16"><Icon name="hand-pointer" /> Este botão é demonstrativo, mas você pode alterar o texto. <br><strong>Clique em "ver"</strong> no topo para visualizar seu formulário.</div>
    </div>
</template>

<script lang="js">
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

    export default {
        name: 'BtnEditor',
         mixins: [UtilitiesMixin],
        props: {
            label: [String, Number],
            size: String,
            icon: String,
            formStyle: Object,
        },
        mounted() {
            this.$refs.btnLabel.innerText = this.label;
        },

        computed: {
            getTheme(){
                return this.getContrastColor(this.formStyle?.color)
            }
        },
        methods: {
            editContent(event) {
                this.$emit('input', event.target.innerText);                
            }
        },
    }
</script>


<style scoped lang="sass">

.btn-editor-container
    &:hover
        .legend
            opacity: 1

.btn-container
    display: inline-block

    &.fullwidth
        display: block
        width: 100%
    
.btn
    background: rgba($cBrandA, 1)
    color: #fff
    border: 1px solid rgba($cBrandA, 1)
    padding: 4px 12px
    font-size: 18px
    border-radius: 5px
    box-shadow: 1px 1px 2px rgba(0,0,0, .2)

    &:hover
        // cursor: pointer

    &:focus
        outline: 0

    [data-theme='black'] &
        color: #000000!important


    [data-type='secondary'] &
        background: transparent
        color: $cG5
        border-color: $cG5

        &:hover
            background: rgba(255,255,255, .25)


    [data-size='sm'] &
        font-size: 14px
        padding: 6px 12px

    [data-size='xs'] &
        font-size: 13px
        padding: 4px 8px


    .fullwidth &
        width: 100%

    .btn-label
        display: inline-block
        padding: 4px 8px
        border-radius: 4pa

        &:hover
            background: rgba(255,255,255,.4)
        &:focus
            outline: 0


.legend
    font-size: $fzXs
    color: rgba($cFormText, .6)
    opacity: 0
    transition: all .3s
    line-height: 130%

</style>