<template>
    <div v-on-clickaway="closeOptions" @keydown.esc="closeOptions" :data-layout="layout" :disabled="disabled" class="select-field field-container" :style="{width}">
        <div @click="toggleStatus" class="select-container">
            <div class="select-label">{{currentLabel}}</div>
            <div class="select-icon"><Icon name="angle-down" /></div>            
        </div>
        <div v-if="items" class="select-options">
            <div                
                v-for="(option, index) in items"                
                @click="setCurrentOption(index)"   
                :data-option="option.value"             
                :key="option.value"
                :class="{'active': index == current}"
                class="select-item">
                    {{option.label}}
                </div>
        </div>
        <div v-if="error" class="error-message">{{error}}</div>

    </div>
</template>

<script>
import Icon from '../Icon.vue'
    import { mixin as clickaway } from 'vue-clickaway';
    
    export default {
    components: { Icon },
        name: "SelectField",   
        mixins: [ clickaway ],     
        props: {
            options: Array,
            value: [String, Number],
            error: String,
            layout: String,
            data: [Object, Array],
            firstAsDefault: Boolean,
            disabled: Boolean,
            width: String
        },
        
        data() {
            return {
                currentValue: null,
                active: false,
                current: null,
            }
        },

        mounted () {
            this.addScrollifNeed()
            this.selectDefault()
        },

        computed: {
            currentLabel() {
                if(this.current === null) return "Selecione"
                else return this.items[this.current].label
            },

            items(){
                if(this.data) return this.convertObjToOptions(this.data.items)
                else if(this.options[0]) return this.addValueAndLabel(this.options)
                else return null
            }

        },

        watch: {
            active() {
                if(this.active) this.openOptions()
                else this.closeOptions()                
            },

            value(){
                this.selectDefault()
            }
        },

        methods: {

            addValueAndLabel(array){
                if(array[0].hasOwnProperty('label') == false) return this.convertObjToOptions(array)
                else return array
            },

            convertObjToOptions(data){
                let optionsList = [];
                data.forEach(item => {
                    if(item.hasOwnProperty('label')) optionsList.push({label: item.label, value: item.label})
                    else optionsList.push({label: item, value: item})
                });

                return optionsList;
            },

            toggleStatus() {
                if(this.active) this.active = false
                else this.active = true
            },

            openOptions(){
                this.active = true;
                this.$el.setAttribute('data-active', true)
            },
            closeOptions(){
                this.active = false;
                const options = this.$el.querySelector('.select-options')
                options.scrollTop = 0
                this.$el.setAttribute('data-active', false)
            },

            setCurrentOption(index){
                this.current = index;
                this.currentValue = this.items[index].value;
                this.active = false;
                this.$emit('input', this.currentValue)
                this.$emit('update:value', this.currentValue)
                this.$emit('input-focus')
            },
            
            addScrollifNeed(){
                if(this.items && this.items.length > 6)
                    this.$el.setAttribute("data-scroll", true)
            },

            selectDefault(){
                if(this.items){
                    for (const [index, option] of this.items.entries()) {
                        if(option.value == this.value) this.current = index
                        else{
                            if(this.firstAsDefault && !this.current) this.current = 0
                        } 
                    }
                }
            }

        },
    }
</script>

<style lang="sass" scoped>
.error-message
  margin-top: $mgXs
  color: $cNegative
  font-size: $fzXs

.field-container
    position: relative
    user-select: none

.select-container
    cursor: pointer
    border: 1px solid $cG3
    border-radius: 4px
    padding: ($mgXs * 1.15) $mgSm
    display: flex
    justify-content: space-between;
    align-items: center;


    &:hover
        border-color: rgba($cBrandA, .3)
        background: rgba($cBrandA, .1)

    .select-icon
        display: flex
        align-items: center
        justify-content: center

.select-label
    white-space: nowrap
    margin-right: $mgSm
    overflow: hidden

.select-options
    background: #fff
    display: none
    margin-top: 4px
    width: 100%
    border: 1px solid $cG3
    border-radius: 4px
    box-shadow: 0 0 10px rgba(0,0,0, .15)
    z-index: 100

    [data-active="true"] > &
        display: block
        position: absolute
    
    [data-scroll="true"] > &
        max-height: 200px
        overflow-y: scroll

    .select-item
        cursor: pointer
        padding: 10px 12px

        &:hover
            background: $cG1
            color: $cBrand

        &.active
            color: $cBrand
            font-weight: bold


[data-layout='line']
    .select-container
        border: 0
        border-bottom: 2px solid $cG4
        border-radius: 0
        padding-left: 0

        &:hover,
        &:focus
            color: $cPrimary
            border-color: $cPrimary


[data-layout='big']
    .select-container
        border-color: rgba($cFormQuestion, 1)
        font-size: $fzMd
        color: rgba($cFormQuestion, 1)
        height: 42px


    .select-options
        background: $cFormBackground
        color: rgba($cFormQuestion, 1)
        border-color: rgba($cFormQuestion, 1)

        .select-item
            &:hover,
            &:focus
                color: rgba($cFormQuestion, 1)
                background: rgba($cFormQuestion, .2)

            &.active
                background: rgba($cFormQuestion, .3)
                color: rgba($cFormQuestion, 1)
                

[disabled="disabled"].field-container
    opacity: .5 !important    
    cursor: not-allowed !important

    .select-container
        pointer-events: none !important

</style>