<template>
    <div v-on-clickaway="close" class="context-menu-comp">
        <div @click="toggle" class="context-button"><Icon name="caret-down" /></div>
        <ul v-show="active" class="context-list">
            <li v-for="option in options" :key="option.value" @click="select(option)" class="context-item">{{option.label}}</li>
        </ul>
    </div>
</template>

<script>
import Icon from '../Icon.vue'
import { mixin as clickaway } from 'vue-clickaway';

    export default {
    components: { Icon },
        name: "ContextMenu",
        mixins: [clickaway],
        props: {
            options: Array,
        },

        data() {
            return {
                active: false
            }
        },
        methods: {
            select(item) {
                this.$emit("context:action", item.value)
                this.close()
            },

            open(){
                this.active = true
            },
            
            toggle(){
                this.active = !this.active
            },

            close(){
                this.active = false
            }
        },
        
    }
</script>

<style lang="sass" scoped>
.context-menu-comp
    position: relative

    .context-button
        height: 30px
        width: 20px
        position: absolute
        right: 0
        top: 0
        font-size: $fzMd
        color: $cBody
        padding: $mgXxs
        display: flex
        align-items: center
        justify-content: center
        border-radius: 4px
        z-index: 0

        &:hover
            background: rgba($cPrimary, .15)


    .context-list
        background: $cWhite
        border-radius: 4px
        box-shadow: 0 0 6px rgba(0,0,0,.15)
        position: absolute
        top: 20px
        right: 0
        width: 150px
        overflow: hidden
        z-index: 100

        .context-item
            padding: $mgXs $mgXs * 1.2
            user-select: none

            &:hover
                background: $cG1

            &:last-child
                margin-bottom: 0

</style>