<template>
  <div class="field-group" :data-layout="layout" :data-size="size" :data-hidearrows="hideArrows" :class="{error}">
      <input        
        data-hj-allow
        v-model="currentValue"
        :ref="slugValue"
        @input="updateValue($event.target.value)"
        @focus="$emit('input-focus', $event.target.value)"        
        class="field-text"
        :type="type"
        :disabled="disabled"        
        :readonly="readonly"  
        :placeholder="placeholderValue">
        <div v-if="error" class="error-message">{{error}}</div>
  </div>

</template>

<script>
export default {
  name: 'NumberField',
  props: {
    data: Object,
    value: [String, Number],
    layout: String,
    slug: String,
    placeholder: String,
    hideArrows: Boolean,
    error: String,
    size: String,
    disabled: Boolean,
    readonly: Boolean,
    isFocus: Boolean
  },

  data() {
      return {
          currentValue: this.value
      }
  },


  computed: {


    type() {
        return "number"
    },

    placeholderValue() {
      if(this.placeholder) return this.placeholder
      else if(this.data.placeholder) return this.data.placeholder
      else return 'Sua resposta...'
    },

    slugValue() {
      if(this.slug) return this.slug
      else if(this.data.slug) return this.data.slug
      else return "x" + Math.random()
    }
    
  },  

    methods: {
        updateValue() {
            let value
            if(this.currentValue) value = Number(this.currentValue)
            if(this.currentValue && this.data?.options?.onlyInteger) value = Math.round(this.currentValue)
            if(!this.currentValue) value = ''
            this.currentValue = value
            this.$emit('input', this.currentValue)
            this.$emit('update:value', this.currentValue)
        }
    },
}
</script>

<style scoped lang="sass">

.field-group
  width: 100%

.field-text
  font-size: $fzSm
  padding: 8px 16px
  border: 1px solid $cG3
  border-radius: 4px
  width: 100%
  color: #000

  &:focus
    outline: none
    border: 1px solid $cBrand

  .error &
    border-color: $cNegative

.error-message
  margin-top: $mgXs
  color: $cNegative
  font-size: $fzXs

[data-size='sm']
  .field-text
    font-size: $fzXs
    padding: 6px 10px



[data-layout='line']
  .field-text
    border: 0
    border-bottom: 2px solid $cG5
    border-radius: 0
    padding-left: 0

    &:hover,
    &:focus
      background: rgba($cPrimary, .10)
      color: $cPrimary
      border-color: $cPrimary


[data-layout='big']

  .field-text
    font-size: 22px
    padding: 8px 0
    border: 0
    border-bottom: 1px solid rgba($cFormQuestion, 1)
    width: 100%
    color: rgba($cFormQuestion, 1)
    background: transparent
    border-radius: 0

    &:focus
      outline: 0
      border-color: rgba($cFormQuestion, 1)

    &::placeholder
      color: rgba($cFormQuestion, .6)
      
  &.error
    .field-text
        border-color: $cNegative!important



[data-hidearrows="true"]
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button 
        -webkit-appearance: none
        margin: 0
    

    /* Firefox */
    input[type=number]
        -moz-appearance: textfield   



</style>
