<template>
  <div class="field-group" :data-layout="layout" :data-brandColor="brandColor" :data-size="size" :class="{error}" :data-showLabel="showLabel" :data-disabled="disabled">  
      <input
        data-hj-allow
        :ref="slugValue"
        :id="slugValue + '-input'"
        @click.prevent=""
        v-bind:value="value"
        v-on:input="$emit('input',  getValue($event.target.value))"
        v-on:change="$emit('update:value',  getValue($event.target.value))"
        v-on:focus="$emit('input-focus',  getValue($event.target.value))"
        class="field-text"
        :type="typeValue"
        :disabled="disabled"        
        :readonly="readonly"  
        :placeholder="placeholderValue">
        <label class="field-fly-label" :for="slugValue + '-input'">{{placeholderValue}}</label>        
        <div v-if="error" class="error-message">{{error}}</div>
  </div>

</template>

<script>
export default {
  name: 'TextField',
  props: {
    data: Object,
    value: [String, Number],
    layout: String,
    slug: String,
    placeholder: String,
    type: String,
    error: String,
    size: String,
    disabled: Boolean,
    readonly: Boolean,
    isFocus: Boolean,
    brandColor: Boolean,
  },

  computed: {
    placeholderValue() {
      if(this.placeholder) return this.placeholder
      else if(this.data.placeholder) return this.data.placeholder
      else return 'Sua resposta...'
    },
    typeValue() {
      if(this.type) return this.type
      else if(this.data.type) return this.data.type
      else return 'text'
    },
    slugValue() {
      if(this.slug) return this.slug
      else if(this.data.slug) return this.data.slug
      else return "x" + Math.random()
    },

    showLabel(){  
      this.value    
      if(this.value != '' && this.value != null && this.type != 'date') return true
    }
  },  

  methods: {
    getValue(value) {
      if(this.type == 'number') return Number(value)
      return value      
    }
  },

}
</script>

<style scoped lang="sass">

.field-group
  width: 100%
  position: relative

  &[data-size='sm']
    .field-text
      font-size: $fzXs
      padding: 6px 10px

  &[data-size='lg']
    .field-text
      font-size: $fzMd
      padding: 6px 10px

.field-fly-label
  display: none  



.field-text
  font-size: $fzSm
  padding: 8px 16px
  border: 1px solid $cG3
  border-radius: 4px
  width: 100%
  color: #000
  

  &:focus
    outline: none
    border: 1px solid $cBrand

  .error &
    border-color: $cNegative


.error-message
  margin-top: 0
  color: #fff
  padding: $mgXxs $mgXs
  border-radius: 0 0 5px 5px
  font-size: $fzXxs
  background: $cNegative
  display: inline-block



[data-layout='line']

  .field-text
    border: 0
    border-bottom: 2px solid $cG5
    border-radius: 0
    padding-left: 0
    background: transparent
    position: relative
    padding-top: 6px + 8px

    &:hover,
    &:focus
      background: rgba($cPrimary, .10)
      color: $cPrimary
      border-color: $cPrimary

  .field-fly-label
    display: block
    font-size: $fzXxs
    position: absolute
    top: 10px
    left: 0
    pointer-events: none
    opacity: 0
    transition: all .2s

  &[data-showLabel="true"]
    .field-fly-label
      opacity: 1
      top: 0px

  &[data-brandColor="true"]
      .field-text
        color: rgba($cFormQuestion, 1)
        border-color: rgba($cFormQuestion, 1)

        &:hover,
        &:focus
          background: rgba($cFormQuestion, .10)
          color: rgba($cFormQuestion, 1)
          border-color: rgba($cFormQuestion, 1)

        &::placeholder
          color: rgba($cFormQuestion, .45)



[data-layout='ghost']
  .field-text
    border: 0
    padding-left: 0

    &:hover
      background: $cG1



[data-layout='big']

  .field-text
    font-size: 22px
    padding: 8px 0
    border: 0
    border-bottom: 1px solid rgba($cFormQuestion, 1)
    width: 100%
    color: rgba($cFormQuestion, 1)
    background: transparent
    border-radius: 0

    &:focus
      outline: 0
      border-color: rgba($cFormQuestion, 1)
      opacity: 1

    &::placeholder
      color: rgba($cFormQuestion, .45)
      
  &.error
    .field-text
        border-color: $cNegative!important

[data-disabled="true"]
    opacity: .6
    cursor: not-allowed

    .field-text
      pointer-events: none











</style>
