<script>
    export default {
        name: "AvatarIcon",
        props: {
            name: String,
            size: String,
            color: String,
        },    
        computed: {
            letters() {
                return this.name.substring(0,2)
            }
        },    
    }
</script>

<template>
    <div class="user-avatar" :data-size="size" :style="{background: color}"><span class="user-name">{{letters}}</span></div>
</template>

<style lang="sass" scoped>
.user-avatar
    width: 30px
    height: 30px
    border-radius: 30px
    background: #FF8A65
    color: $cWhite
    display: flex
    align-items: center
    justify-content: center
    font-size: $fzXxs * 1.1
    user-select: none
    
    .user-name
        text-transform: lowercase

        &::first-letter
            text-transform: uppercase

    &[data-size="sm"]
        width: 22px
        height: 22px
        border-radius: 22px
        font-size: $fzXxs * .8

</style>