<template>
    <div class="field-group">
        <div class="date-field-group">
            <input type="text" :ref="slugValue + '-day'" @input="updateValue('day', $event.target)" @focus="$emit('input-focus', $event.target.value)" maxlength="2" v-model="day" class="field-text date-field day" placeholder="DD">
            <span class="divider">/</span>
            <input type="text" :ref="slugValue + '-month'" @input="updateValue('month', $event.target)" @focus="$emit('input-focus', $event.target.value)" maxlength="2" v-model="month" class="date-field month" placeholder="MM">
            <span class="divider">/</span>
            <input type="text" :ref="slugValue + '-year'" @input="updateValue('year', $event.target)" @focus="$emit('input-focus', $event.target.value)" maxlength="4" v-model="year" class="date-field year" placeholder="AAAA">
        </div>
        <div v-if="error" class="error-message">{{error}}</div>
    </div>
</template>

<script>
    export default {
        name: "DateField",
        props: {
            data: Object,
            value: [String, Number],
            error: [String]
        },
        data() {
            return {
                day: null,
                month: null,
                year: null,
                valid: {
                    day: false,
                    month: false,
                    year: false,
                }
            }
        },

        computed: {
            slugValue() {
                if(this.slug) return this.slug
                else if(this.data.slug) return this.data.slug
                else return "x" + Math.random()
            },
            dateValue() {  
                if(this.day && this.month && this.year){
                    const finalDate = this.day + "/" + this.month + "/" + this.year
                    return finalDate
                }                
            }
        },

        methods: {           

            updateValue(type, target){
                if(this.testDate(type, target.value)) this.$emit('update:value', this.dateValue)
                else{
                    if(this.day || this.month || this.year) this.$emit('update:value', "incomplete")
                    else this.$emit('update:value', "")
                }
                 
            },

            testDate(type, value){ 
                if(type == "day") this.valid.day = this.checkDay(value)               
                if(type == "month") this.valid.month = this.checkMonth(value)                
                if(type == "year") this.valid.year = this.checkYear(value)
                if(this.valid.day && this.valid.month && this.valid.year) return true                
                return false
            },

            checkDay(value){
                const day = value.replace(/[^\d,]/g,'')
                this.day = day
                if(day > 31 || day < 1) return false
                if(day.length > 1) this.setFoucs("month") 
                return true
            },

            checkMonth(value){
                const month = value.replace(/[^\d,]/g,'')
                this.month = month
                if(month > 12 || month < 1) return false    
                if(month.length > 1) this.setFoucs("year")     
                return true
            },

            checkYear(value){
                const year = value.replace(/[^\d,]/g,'')
                this.year = year
                if(year < 1 || year.length < 4) return false
                return true
            },

            setFoucs(date){
                if(date == "month"){
                    const element = this.slugValue + '-month'
                    this.$refs[element].focus()
                    this.$refs[element].select()
                }
                if(date == "year"){
                    const element = this.slugValue + '-year'
                    this.$refs[element].focus()
                    this.$refs[element].select()
                }
            }
        },
        
        
    }
</script>

<style lang="sass" scoped>
.date-field-group
    width: 180px
    display: flex
    padding-bottom: $mgXs
    border-bottom: 1px solid rgba($cFormQuestion, 1)
    align-items: center

    .date-field
        width: 100%
        max-width: 45px
        font-size: $fzMd
        padding: $mgXxs
        letter-spacing: 1px
        border: none
        border-radius: 4px
        text-align: center
        background-color: transparent
        color: rgba($cFormQuestion, 1)

        &.year
            min-width: 70px
            max-width: 70px

        &:focus, &:hover
            outline: 0
            background: rgba($cFormQuestion, .15)

        &::placeholder
            color: rgba($cFormQuestion, .6)


    .divider
        font-size: $fzMd
        margin: 0 $mgXxs
        color: rgba($cFormQuestion, .6)
    

    &.error
        .date-field
            border-color: $cNegative!important

.error-message
  margin-top: $mgXs
  color: $cNegative
  font-size: $fzXs

</style>