<template>
    <div class="address-field-comp">
        <div class="step-1">
            <TextField v-if="edit" ref="cep" type="number" class="cep-field" layout="big" :data="{}" placeholder="CEP" :disabled="true"/>
            <TextField v-if="country == 'BR' && !edit" class="cep-field" layout="big" :data="{}" v-model="cep" placeholder="CEP" :error="validation.cep"  />
            <SelectField v-if="country != 'BR'" layout="big" class="coutries-select-field mt8" :v-model="country" :options="countries" v-on:update:value="updateCountry" />
            <Btn v-if="country == 'BR'" @click.native="dontKnowCep" :brandColor="true" href="https://buscacepinter.correios.com.br/" class="search-cep ml8" layout="icon" size="xs"><Icon name="search" /> Não sei meu CEP</Btn>
            <Btn v-if="country == 'BR'" @click.native="notBrazil" :brandColor="true" class="global-address ml8" layout="icon" size="xs"><Icon name="globe-americas" /> Endereço internacional</Btn>
            <Btn v-else @click.native="isBrazil" :brandColor="true" class="global-address ml8" layout="icon" size="xs"><Icon name="globe-americas" /> Selecionar Brasil</Btn>
        </div>

        <div v-if="country == 'BR'" class="full-address mt32" :data-active="state && !loading">
            <div class="step-2 mb32">            
                <TextField :disabled="true" :brandColor="true" layout="line" class="city-field mr16" :data="{}" v-model="city" placeholder="Cidade" />
                <TextField :disabled="true" :brandColor="true" layout="line" class="state-field mr16" :data="{}" v-model="state" placeholder="Estado" />
                <TextField ref="neighborhood" layout="line" :brandColor="true" class="neighborhood-field" :data="{}" v-model="neighborhood" placeholder="Bairro" />
            </div>

            <div class="step-3">
                <TextField layout="line" :brandColor="true" class="street-field mr16" :data="{}" v-model="streetName" placeholder="Rua/Avenida" :error="validation.street" />
                <TextField layout="line" :brandColor="true" class="number-field mr16" :data="{}" v-model="number" placeholder="Número" :error="validation.number" />
                <TextField layout="line" :brandColor="true" class="apt-field mr16" :data="{}" v-model="addressComp" placeholder="Complemento" />
            </div>
        </div>

        <div v-if="country != 'BR' && country" class="global-full-address mt32" :data-active="state">
            <TextField :brandColor="true" layout="line" class="global-address-line1 mr16 mb16" :data="{}" v-model="globalLine1" v-on:input="updateGlobalAddress" placeholder="Linha 1 do endereço" />
            <TextField :brandColor="true" layout="line" class="global-address-line2 mr16" :data="{}" v-model="globalLine2" v-on:input="updateGlobalAddress" placeholder="Linha 2 do endereço" />
        </div>

        <div v-if="loading" class="loading mt32">
            <LoadingIcon :brandColor="true" />
        </div> 

        <div v-if="error" class="error-message">{{error}}</div>       

    </div>
</template>

<script>
import LoadingIcon from '../LoadingIcon.vue'
import SelectField from './SelectField.vue'
import Btn from './Btn.vue'
import TextField from './TextField.vue'
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin.js';

    export default {
        components: { TextField, Btn, SelectField, LoadingIcon },
        mixins: [UtilitiesMixin],
        name: "AddressField",
        props: {
            data: Object,
            edit: Boolean,
            error: String
        },

        data() {
            return {
                firstTry: true,
                country: "BR",
                cep: null,
                lastTest: null,
                state: null,
                city: null,
                neighborhood: null,
                street: null,
                number: null,
                addressComp: null,
                globalLine1: null,
                globalLine2: null,
                value: null,
                loading: null,
                validation: {
                    cep: null,
                    country: null,
                    state: null,
                    city: null,
                    neighborhood: null,
                    street: null,
                    number: null,
                }
                
            }
        },

        computed: {
            streetName: {

                get: function (){
                    if(this.street){
                        const streetName = this.street.split(" -")
                        return streetName[0]
                        }
                    return null
                },

                set: function(value){
                    this.street = value
                }
            },

            isValid(){
                if(this.cep && this.cep.lenght == 8 && this.state && this.city && this.neighborhood && this.street && this.number) return true
                return false
            },

            updateAnyValue(){
                return `${this.cep}-${this.state}-${this.city}-${this.neighborhood}-${this.street}-${this.number}-${this.addressComp}`
            },

            countries(){
                return this.getListOfCountries()
            }
        },


        watch: {
            cep(value, old) {
                // this.error = null
                // if(value.length >= 8) this.cep = this.formatCep(value)
                this.cep = this.formatCep(value)
                if(this.edit) return
                if(this.cep.length == 8 && this.cep != this.lastTest) this.getCepData(this.cep)  
            },  

            updateAnyValue(){                
                this.value = this.addressInJson()
                this.$emit('input-focus')
                const isValid = this.validateFields()

                if(isValid){
                    this.$emit('update:value', this.value)
                    this.$emit('input', this.value)
                }

                else{
                    this.$emit('update:value', null)
                    this.$emit('input', null)
                }
            }

        },

        methods: {

            dontKnowCep(){
                this.$trackEvent.mixpanel("Do not know CEP", {distinct_id: this.$parent?.form?.cus, form_id: this.$parent.form.slug})
            },

            updateGlobalAddress(event){
                if(!this.country || this.globalLine1 == '' || this.globalLine1 == null){
                    this.$emit('update:value', null)
                    this.$emit('input', null)
                } 

                else{
                    const address = {
                        country: this.country,
                        line1: this.globalLine1,
                        line2: this.globalLine2,
                    }
    
                    this.value = JSON.stringify(address)
                    this.$emit('update:value', this.value)
                    this.$emit('input', this.value)
                }


            },

            validateFields(){
                if(this.validation.cep != "Informe um CEP válido") this.validation.cep = null
                this.validation.state = null
                this.validation.city = null
                this.validation.neighborhood = null
                this.validation.street = null
                this.validation.number = null
                if(!this.cep) this.validation.cep = "Obrigatório"
                if(!this.state) this.validation.state = "Obrigatório"
                if(!this.city) this.validation.city = "Obrigatório"
                if(!this.neighborhood) this.validation.neighborhood = "Obrigatório"
                if(!this.street) this.validation.street = "Obrigatório"
                if(!this.number) this.validation.number = "Obrigatório"

                if(!this.validation.cep && !this.validation.state && !this.validation.city && !this.validation.neighborhood && !this.validation.street && !this.validation.number) return true
                return false                
            },

            updateCountry(value){
                this.country = value
                this.updateGlobalAddress()
                this.$emit('input-focus')
                this.$trackEvent.mixpanel("Use international address", {distinct_id: this.$parent?.form?.cus, form_id: this.$parent.form.slug, country: value})
            },

            notBrazil(){                
                if(this.edit) return null
                this.value = null
                this.$emit('update:value', null)
                this.country = null
            },
            isBrazil(){
                this.value = null
                this.$emit('update:value', null)
                this.country = 'BR'
            },
            
            getCepData(cep) {
                this.loading = true
                this.number = null
                this.addressComp = null

                this.$cep(cep)
                .then( (response) => {
                    this.loading = false
                    // this.error = false
                    this.lastTest = this.cep                  
                    this.state = response.state,                                   
                    this.city = response.city,
                    this.neighborhood = response.neighborhood,
                    this.street = response.street
                    this.value = this.addressInJson()
                    this.validation.cep = null
                    this.setFocus()
                    this.$trackEvent.mixpanel("Search CEP", {distinct_id: this.$parent?.form?.cus, form_id: this.$parent.form.slug, city: response.city, region: response.state, country: 'BR'})

                })
                .catch(response => {
                    this.loading = false
                    this.lastTest = this.cep 
                    this.validation.cep = "Informe um CEP válido"  
                    this.state = null
                    this.city = null
                    this.neighborhood = null
                    this.street = null
                    this.$trackEvent.mixpanel("Search invalid CEP", {distinct_id: this.$parent?.form?.cus, form_id: this.$parent.form.slug, value: this.cep})
                })     
            },

            setFocus(){
                const el = this.$refs.neighborhood.$el.querySelector('input')
                el.focus()
            },

            addressInJson(){
                const fullAddress =  {
                    country: this.country,
                    cep: this.cep,
                    city: this.city,
                    state: this.state,
                    neighborhood: this.neighborhood,
                    street: this.streetName,
                    number: this.number,
                    addressComp: this.addressComp,
                }

                return JSON.stringify(fullAddress)
            },

            formatCep(cep){
                const cepNumbers = cep.replace(/[^\d]/,'').substring(0,8)              
                return cepNumbers
            }
        },
        
    }
</script>

<style lang="sass" scoped>

.error-message
  margin-top: 0
  color: #fff
  padding: $mgXxs $mgXs
  border-radius: 0 0 5px 5px
  font-size: $fzXxs
  background: $cNegative
  display: inline-block

.loading
    // background: rgba($cFormQuestion, .1)
    width: 100%
    height: 240px
    border-radius: 5px
    display: flex
    align-items: center
    justify-content: center


.global-address,
.search-cep
    margin-top: $mgXs
    margin-left: -$mgXs

.global-address::v-deep .btn,
.search-cep::v-deep .btn
    color: rgba($cFormText, .6)

    &:hover
        color: rgba($cFormText, 1)
        background-color: rgba($cFormText, .1)


.full-address
    opacity: 0
    height: 0.1px
    transition: all .5s
    pointer-events: none

    &[data-active]
        opacity: 1
        height: auto
        pointer-events: auto

[class*="step-"]
    display: flex
    flex-wrap: wrap
    box-sizing: border-box

[class*="-field"]
    flex: 1 1 33% 

    @media (max-width: 800px)
        margin-top: $mgSm 

.address-field-comp,
.cep-field
    margin-top: 0

.step-1
    align-items: center

.cep-field,
    min-width: 200px
    max-width: 200px

.coutries-select-field
    max-width: 250px
    min-width: 250px


.state-field
    width: 100%
    min-width: 100px
    max-width: 100px

.city-field
    min-width: 200px

.neighborhood-field
    min-width: 200px

.street-field
    min-width: 200px

.number-field
    min-width: 100px
    max-width: 100px

.apt-field
    min-width: 200px

.step-3
    background: rgba($cFormQuestion, .1)
    padding: $mgSm
    border-radius: 5px

</style>