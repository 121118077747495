<template>
    <div class="document-field-comp field-group">
        <!-- <TextField v-model.trim="value" :layout="layout" v-mask="'###.###.###-##'" :data="data" v-on:update:value="updateValue" v-on:input-focus="isActive" :error="validateMessage" /> -->
        <TextField v-if="format == 'cpf'" v-model.trim="fieldValue" layout="big" v-mask="'###.###.###-##'" :data="data" @input="updateValue" v-on:input-focus="$emit('input-focus')" :error="error" placeholder="000.000.000-00" />
        <TextField v-if="format == 'cnpj'" v-model.trim="fieldValue" layout="big" v-mask="'##.###.###/####-##'" :data="data" @input="updateValue" v-on:input-focus="$emit('input-focus')" :error="error" placeholder="00.000.000/0000-00" />
    </div>
</template>

<script>
import TextField from './TextField.vue'
    export default {
    components: { TextField },
        name: "DocumentField",
        props: {
            data: Object,
            format: String,
            value: [String, Number],
            layout: String,
            error: [String]
        },

        data() {
            return {
                fieldValue: this.value
            }
        },

        methods: {
            removeMask(value) {
                return value.replace(/[^\d,]/g,'')                
            },

            updateValue(){
                this.$emit("update:value", this.removeMask(this.fieldValue))
            }
        },


        
        
    }
</script>

<style lang="sass" scoped>
.document-field-group


</style>