<template>
    <div class="image-radio-field-comp">
        <div class="options-list">
            <div v-for="(option, index) in options" @click="select(index)" :key="option.key" :data-active="current.includes(index)" class="option-item" :data-value="option.label">
                <div class="image-container">
                    <img v-if="option.image" class="image" :src="$bucket + option.image.path" alt="">
                    <span v-else class="no-image"><Icon name="image"/></span>
                </div>
                <div class="option-label"><IconOption class="letter-icon mr8" :active="current.includes(index)" :position="index" /> {{option.label}}</div>
            </div>
        </div>
        <div v-if="error" class="error">{{error}}</div>
    </div>
</template>

<script>
import IconOption from './IconOption.vue'
    export default {
    components: { IconOption },
        name: "ImageRadioField",
        props: {
            data: Array,
            error: String,
            multi: Boolean,
            showOthers: Boolean
        },


        data() {
            return {
                current: [],
                currentItems: [],
                currentKeys: [],
                value: null,
                options: this.data
            }
        },

        computed: {
            submitOnClick() {
                return ! this.multi
                return this.multi ? false : true
            },
            test(){
                return "testing 123"
            }
        },

        methods: {
            updateCurrentItems(item){
                this.currentKeys = item.map(item => item.key)
            },

            select(index) {
                if(this.current.includes(index)) this.unsetValue(index)
                else this.setValue(index) 
                this.updateCurrentItems(this.currentItems)
                this.updateValue(index)
            },

            setValue(index){
                if(this.multi){
                    this.current.push(index)
                    this.currentItems.push(this.options[index])
                } 
                else{
                    this.current = [index]
                    this.currentItems = [this.options[index]]
                } 
            },

            unsetValue(index){
                const indexOnCurrentItems = this.current.indexOf(index)
                this.current.splice(indexOnCurrentItems,1)
                this.currentItems.splice(indexOnCurrentItems,1)
                this.value = null
            },

            updateValue(){
                if(this.current == null || this.current == '') this.$emit('update:value', '', this.submitOnClick)
                else this.$emit('update:value', this.getValues(this.current), this.currentKeys, this.submitOnClick)
                this.$emit('input-focus')        
            },


            getValues(values){
                if(values == '' || values.length == 0) return ''
                let finalValues = []
                values.forEach(item => {
                    finalValues.push(this.options[item])
                });
                
                return JSON.stringify(finalValues)
            }
        },
    }
</script>

<style lang="sass" scoped>
.image-radio-field-comp
    .options-list
        display: flex       
        flex-wrap: wrap

    .option-item
        flex: 0 0
        background: transparent
        border: 1px solid rgba($cFormQuestion, 1)
        margin-right: $mgSm
        margin-bottom: $mgSm
        border-radius: 4px
        position: relative
        cursor: pointer

        &[data-active="true"]
            outline: 3px solid rgba($cFormQuestion, 1)
            background: rgba($cFormQuestion, .1)

    .image-container
        width: 180px
        height: 160px
        padding: $mgXs

        .image
            width: 100%
            height: 100%
            object-fit: contain

    .option-label
        display: flex
        align-items: center
        padding: $mgXs
        font-size: $fzSm

    .no-image
        color: rgba($cFormQuestion, .5)
        font-size: $fzXl
        display: flex
        align-items: center
        justify-content: center
        height: 100%

@media (max-width: 480px)
    .options-list
        justify-content: center

        .option-item
            margin-right: 0


.error
    margin-top: 0
    color: #fff
    padding: $mgXxs $mgXs
    border-radius: 0 0 5px 5px
    font-size: $fzXxs
    background: $cNegative
    display: inline-block



</style>