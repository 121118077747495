<template>
  <div class="text-field-editor" :data-layout="layout" :data-align="align" :data-size="size" :class="{error: error}">    
    <div v-if="mentionTip" class="legend mb8">Mencione outra pergunta com @</div>
    <vue-tribute :options="tributeOptions">
        <div
        data-hj-allow
        contenteditable="true"
        ref="editorField"
        @keydown.enter.exact.prevent
        v-on:input="changeValue($event.target)"        
        v-on:focus="$emit('input-focus', $event.target.value)" 
        class="textarea-field"
        :placeholder="placeholderValue"></div>
    </vue-tribute>
    <div v-if="error" class="error-message">{{error}}</div>
  </div>
</template>

<script>
import VueTribute from 'vue-tribute'
import Vue from 'vue'

export default {
  name: 'TextFieldEditor',
  props: {
    data: Object,
    form: Object,
    value: String,
    placeholder: String,
    layout: String,
    slug: String,
    error: String,
    noResize: Boolean,
    mentionTip: Boolean,
    size: String,
    align: String
  },
  components: {
    VueTribute
  },

  data () {
    return {
      text: '',     
      currentValue: null, 
      mentions: [],
      tributeOptions: {  
        trigger: '@',  
        iframe: null,  
        selectClass: 'highlight',  
        containerClass: 'tribute-container',  
        itemClass: '', 
        selectTemplate: function (item) {
          const template = getPopTemplate(item.original.label, item.original.value)
          Vue.prototype.$trackEvent.mixpanel("Use Feature::Mentions", {distinct_id: Vue.prototype.$user.id})         
          return template
        },
        menuItemTemplate: function (item) {
          if(item.original.label == null) return null
          return item.original.label.replace(/\{\{[^\}]+\}\}/g, `____`);
        },
        noMatchTemplate: '<span>no match</span>',
        menuContainer: this.$refs.menuContainer,
        lookup: 'label',  
        fillAttr: 'slug',  
        values: [],  
        loadingItemTemplate: null,  
        requireLeadingSpace: false,  
        allowSpaces: true,
        replaceTextSuffix: '\n',
        positionMenu: true,
        spaceSelectsMatch: false,
        autocompleteMode: false,
        searchOpts: {
          pre: '<span>',
          post: '</span>',
          skip: false 
        },
        menuItemLimit: 250,
        menuShowMinLength: 0
      },

    }
  },
    
  computed: {
    placeholderValue() {
      if(this.placeholder) return this.placeholder
      else if(this.data.placeholder) return this.data.placeholder
      else return 'Sua resposta...'
    },

    fields(){
      return Object.assign({}, this.form.fields)
    }


  },


  mounted () {
      this.insertVariablesTemplateOnTitle()
  },  

  methods: {

    insertVariablesTemplateOnTitle(){
      const possibleMentions = this.getMentions(this.form.fields)
      this.tributeOptions.values = possibleMentions

      let newValue = this.value
      possibleMentions.forEach((mention) => {
        const template = getPopTemplate(mention.label, mention.value, mention.index)
        const replace = this.findReplaceString(newValue, "{{" + mention.value + "}}", template)
        if(replace) newValue = replace
      })
      
      if(newValue) this.$refs['editorField'].innerHTML = newValue
      else this.$refs['editorField'].innerHTML = this.value
      this.tributeOptions.menuContainer = this.$refs.menuContainer;

    },

    getMentions(fields){
      let mentions = []
      let index = 0
      fields.forEach((field) =>{
        index++
        if(field.type == "welcome" || field.type == "thankyou" || field.value == "" || field.slug == this.slug) return
        const mention = {
          key: field.value,
          label: field.value,
          value: field.slug,
          index: index
        }
        mentions.push(mention)
      })
      return mentions
    },

    findReplaceString(string, find, replace){        
        if ((/[a-zA-Z\_]+/g).test(string)) {
          const work = string.replaceAll(find, replace);
          if(string !== work) return work
          return null
        }          
        return string
        
    },

    resizeTextarea: function(element) {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
        if(element.scrollHeight > 100 && this.noResize == true){
            element.style.fontSize = "16px";
        }
    },

    changeValue: function(element){        
        this.currentValue = this.convertHtmlToBraces(element)        
        this.$emit('input', this.currentValue)
        this.$emit('update:value', this.currentValue)
        this.resizeTextarea(element);
    },

    convertHtmlToBraces(element){
        let value =  element.cloneNode(true)
        const mentions = value.querySelectorAll('.mentionLabel')
        
        mentions.forEach((label) => {
          label.remove()
        })

        const result = value.innerText.replace(/\s+/g, " ").replace(/^\s|\s$/g, ""); 
        return this.fixSpecialCharacteres(result)  

    },

    fixSpecialCharacteres(string){
      const list = ["?", ",", ":", ";", "!", ".", "/"]
      let newString = string
      list.forEach((symbol) => newString = newString.replaceAll(" " + symbol, symbol))
      return newString
    },


  }

}

function getPopTemplate(label, slug, index){
      if(!label) return null
      const labelWithMention = label.replace(/\{\{[^\}]+\}\}/g, `<span class="mentionOnMention">Resposta</span>`)
      let question;
      if(index) question = index + '. '
      else question = ''
      return `
      <span class="mention-pop" contenteditable="false">
        <span class="mentionLabel">${question}${labelWithMention}</span>
        <span class="mentionValue hide">{{${slug}}}</span>
      </span>`
    }

</script>

<style scoped lang="sass">
.text-field-editor
    width: 100%  
    
    .legend
      font-size: $fzXs
      color: rgba($cFormText, .6)      
      opacity: 0
      transition: opacity .3s

    &:hover
      .legend
        opacity: 1



    .textarea-field
        color: rgba($cFormText, 1)
        font-family: $sans
        font-size: $fzLg
        padding: $mgXxs 0
        width: 100%
        border: none
        line-height: 140%
        vertical-align: center

        &:focus, &:hover
            outline: 0        

        &:hover
            background: rgba($cFormText, .1)
            cursor: text

[data-align="center"]
  .textarea-field
    text-align: center

[data-size="sm"]
  .textarea-field
    font-size: $fzSm      



</style>
<style lang="scss">

.mention-pop{
  display: inline-block;
  vertical-align: middle;
  //background: rgba($cPrimary, .1);
  border-bottom: 2px solid rgba($cFormQuestion, 1);
  color: rgba($cFormQuestion, 1);
  padding: 2px 2px 4px 2px;
  // border-radius: 5px;
  font-size: $fzSm;
  font-family: monospace;
  line-height: 140%;

}

.mentionOnMention{
  display: inline-block;  
  border: 1px solid rgba($cFormQuestion, 1);
  font-size: $fzXxs;
  color: rgba($cFormQuestion, 1);
  padding: 2px 4px;
  border-radius: 2px;
  line-height: 100%;
}


.mention-item {  
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
}


.v-tribute {
  width: 100%;
  position: relative;
}



.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 100;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(#000, 0.13);
  font-family: $sans
}

.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(#000, 0.13);
  background-clip: padding-box;
  overflow: hidden;
}
.tribute-container li {
  color: $cG5;
  padding: $mgXs $mgSm;
  cursor: pointer;
  font-size: $fzSm;
}

.tribute-container li.highlight,
.tribute-container li:hover {
  background: $cG2;
  color: rgba($cFormText, .1);
}

.tribute-container li span {
  font-weight: bold;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  display: block; 
  opacity: .5;
}

</style>