<template>
    <div class="image-radio-field-comp mt16">
        <div class="options-list">              
        
            <div v-for="(option, index) in options" :key="option.key" :data-active="current.includes(index)" class="option-item">
                <div class="close-icon"><Btn @click.native="removeFile(index)" size="xs" layout="remove"></Btn></div>
                <div class="image-container">
                    <img v-if="option.image" class="image" :src="$bucket + option.image.path" alt="">
                    <div v-else class="upload-item">
                        <UploadField :form="form" :file="option.image" v-on:upload="updateFile($event, index)" layout="box" />
                    </div>
                </div>
                <div class="option-label">
                    <IconOption class="letter-icon mr8" :active="current.includes(index)" :position="index" />
                    <input class="label-input" type="text" v-model="option.label" placeholder="Legenda">
                </div>
            </div>

            <div class="option-item upload-item">
                <div class="image-container">
                    <UploadField :form="form" v-on:upload="uploadFile($event)" layout="box" />
                </div>
            </div>
        </div>

        <!-- <div class="add-file">
            <UploadField :form="form" v-on:upload="uploadFile($event)" class="demo" />
        </div> -->

    </div>
</template>

<script>
import Btn from './Btn.vue'
import UploadField from './UploadField.vue'
import IconOption from './IconOption.vue'
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin'
import { FormMixin } from '@/mixins/FormMixin'
// import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';

    export default {
        components: { IconOption, UploadField, Btn },
        mixins: [ UtilitiesMixin, FormMixin ],

        name: "ImageRadioFieldEditor",
        props: {
            data: Array,
        },

        mounted () {
            this.options = this.convertOptionsToFormat2(this.data)
        },

        data() {
            return {
                current: [],
                value: null,
                multiple: true,  
                options: null
            }
        },
        

        methods: {
            convertOptionsToFormat2(options){
                let optionsList = []
                options.forEach(item => {
                    if(!item.label && !item.key) optionsList.push({label: item, key:this.createRandId(), image: null})
                    else optionsList.push(item)
                })
                return optionsList
            },

            uploadFile(event) {
                const item = {
                    label: "Opção " + (this.options.length + 1),
                    key: this.createRandId(),
                    image: event
                }
                this.options.push(item)
                this.updateField()
            },    
            
            updateFile(event, index){
                const options = this.options[index]
                options.image = event
                this.$set(this.options, index, options)
                this.updateField()
            },
            
            removeFile(index){
                this.$axios
                    .delete(process.env.VUE_APP_API_URL + 'file/' + this.options[index].image.id)
                    .then(response => {
                        this.options.splice(index, 1)
                        this.updateField()                    
                    })
                    .catch(error => {
                        if(error.response.status == 404){
                            this.options.splice(index, 1)
                            this.updateField()           
                        }
                        console.log(error.response)
                    })            
            },

            updateField(){    
                this.$emit('input', this.options)            
                this.$emit('update:value', this.options)
                this.$emit('save-form')
            }
        },
        
    }
</script>

<style lang="sass" scoped>
.image-radio-field-comp
    .options-list
        display: flex       
        flex-wrap: wrap

    .option-item
        flex: 0 0
        background: transparent
        border: 1px solid rgba($cFormQuestion, 1)
        margin-right: $mgSm
        margin-bottom: $mgSm
        border-radius: 4px
        position: relative

        &[data-active="true"]
            outline: 2px solid $cFormQuestion
            background: rgba($cFormQuestion, .1)

        .close-icon
            position: absolute
            top: $mgXxs
            right: $mgXxs
            z-index: 100

    .upload-item
        .image-container
            min-height: 164px


    .image-container
        width: 180px *.8
        height: 160px *.8
        padding: $mgXs

        .upload-item
            height: 100%

        .image
            width: 100%
            height: 100%
            object-fit: contain

    .option-label
        display: flex
        align-items: center
        padding: $mgXs
        font-size: $fzSm

        .letter-icon
            color: rgba($cFormQuestion, 1)

        .label-input
            outline: 0
            border: 0
            padding: 2px
            max-width: 100px
            background: transparent
            color: rgba($cFormQuestion, 1)

            &:hover
                +glassStyle($cFormQuestion)
                color: rgba($cFormQuestion, 1)

    // .upload-item
    //     height: 100%
    //     width: 100%



</style>