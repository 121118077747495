<template>
    <div @click="changeStatus" class="switch" :data-active="activeField" :data-disabled="disabled" :data-layout="layout" :data-size="size">
        <div class="slider"></div>
        <Icon name="check" class="icon" />
    </div>

</template>

<script>
    export default {
        name: 'SwitchField',
        props: {
            active: Boolean,
            disabled: Boolean,
            layout: String,
            size: String
        },
        data() {
            return {
                activeField: this.active
            }
        },
        watch: {
            active(value) {
                this.activeField = value
            }
        },        
        methods: {
            changeStatus() {
                this.activeField = !this.activeField;                
                this.$emit('input', this.activeField)
            }
        },
        
    }
</script>

<style lang="sass" scoped>

[data-disabled="true"]
    opacity: .5
    pointer-events: none

    .slider
        cursor: not-allowed

.switch
    background: $cG3
    width: 40px
    height: 20px
    border-radius: 20px
    display: inline-block
    position: relative;
    transition: all .3s
    position: relative

    &:hover
        cursor: pointer

    
    .icon
        font-size: $fzXxs * .8
        color: $cWhite
        opacity: 0
        position: absolute
        left: 8px
        top: 5px    
               

    &[data-active='true']
        background: rgba($cPrimary, .15)
        background: $cPrimary

        .slider
            left: 20px
            border-color: $cPrimary 

        .icon
            opacity: .4

        

    .slider
        transition: all .3s
        left: 0
        height: 20px
        width: 20px
        border: 3px solid $cG3
        border-radius: 20px
        background: #fff
        position: absolute

    .switch-input
        display: none

[data-layout="status"]
    background: rgba($cNegative, .15)
    background: $cNegative

    .slider
        border-color: $cNegative        

    &[data-active='true']
        background: rgba($cPositive, .15)
        background: $cPositive

        .slider
            border-color: $cPositive 


[data-size="sm"]     
    width: 34px
    height: 17px

    .slider
        border-width: 2px
        width: 17px
        height: 17px

    &[data-active="true"]
        .slider
            left: 17px
        
        .icon
            display: none





</style>