<script>
    import TagLabel from './TagLabel.vue'
    export default {
        name: "ActionRow",
        components: { TagLabel },
        props: {
            title: [String, Number],
            description: [String, Number],
            pro: Boolean,
            showBody: Boolean
        },
    }
</script>

<template>
    <div class="action-row-comp">
        <div class="row">
            <div class="content mr8">
            <div class="title">{{title}} <TagLabel v-if="pro" type="new" class="plan-tag">PRO</TagLabel></div>
            <div class="description mt4">{{description}}</div>
            </div>

            <div class="action">
                <slot name="default"></slot>  
            </div>
        </div>

        <div v-if="showBody" class="body mt16">
            <slot name="body"></slot>
        </div>
    </div>
</template>


<style lang="sass" scoped>
.action-row-comp
    display: flex
    border-bottom: 1px solid $cG2
    padding-bottom: $mgSm
    flex-direction: column
    // flex-wrap: wrap

    &[last]
        border-bottom: 0
        padding-bottom: 0

    .row
        display: flex


    .content
        .title
            font-size: $fzSm

        .description
            font-size: $fzXs
            color: $cG5
            line-height: 120%

    .action
        min-width: 150px
        display: flex
        align-items: center
        justify-content: flex-end
        margin-left: auto
            

    .body
        width: 100%
        display: block

</style>