<template>
    <header class="top-header">
        <div class="logo-container">
            <div v-if="nav == 'Editor'" class="editor-header">
                <Btn :link="{name: 'Dashboard'}" layout="icon" >
                    <Icon name="arrow-left" />
                </Btn>
                <span class="form-title">{{ limit(data.name, 30) }}</span>
            </div>

            <div v-else class="default-logo">            
                <router-link :to="{name: 'Dashboard'}" tag="div" class="logo">
                    <img src="@/assets/img/respondi-logo.png" alt="Respondi Logo">            
                </router-link>
                <div v-if="$user.subscriptions.plan_name == 'pro'" class="plan-badge ml8">PRO</div>
                <div v-if="$user.subscriptions.plan_name == 'solo'" class="plan-badge ml8">SOLO</div>
                <div v-if="$user.subscriptions.plan_name == 'business'" class="plan-badge ml8">Empresa</div>
            </div>

            <div v-if="$user.current_team" class="team-name ml8">{{$user.current_team.name}}</div>
        </div>
        
        <nav v-if="navItems" @click="autosave" class="top-nav">
            <router-link v-for="item in navItems" :to="{ name: item.route.name, params: { slug: item.route.slug }}" :key="item.route.name" class="nav-item"> {{item.label}} </router-link>
        </nav>
        
        <!-- Actions -->
        <div class="actions">
            <slot>
            <!-- <div v-if="this.$route.name == 'Dashboard'" class="user-menu"> -->
            <div class="user-menu">

                <Btn v-if="showUpgradeButton" :link="{name: 'Upgrade'}" type="positive" size="xs"><Icon name="star" type="solid"/> Faça upgrade!</Btn>
                <!-- <Btn @click.native="logout" type="secondary" size="xs">Sair</Btn> -->
                <UserMenu />
                <!-- <router-link :to="{name: 'userSettings'}" tag="div" class="settings-button">
                    <Icon name="cog" />
                </router-link> -->
            </div>
            </slot>
        </div>

        
    </header>
</template>

<script>
import UserMenu from './UserMenu.vue'
import Icon from './Icon.vue'
import Btn from '@/components/form/Btn.vue';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin'
import { FormMixin } from '@/mixins/FormMixin'
const axios = require('axios').default;


export default {
    name: 'TopHeader',
    mixins: [UtilitiesMixin, FormMixin],
    components: {
        Btn, Icon, UserMenu
    },
    props: {
        nav: String,
        data: Object,
    },

    data: function(){
        return {
            navItems: null,
        }
    },

    computed: {
        defaultAvatar() {
            return this.$user.name.substring(0,2)
        },

        showUpgradeButton(){
            if(this.$user.plan_level < 2 && this.$user.current_team_id == null) return true
            return false
        }
    },

    mounted: function(){
        
        if(this.nav == "Editor"){
            this.navItems = [
            {
                "route": {"name": "Editor", "slug": this.$route.params.slug},
                "label": "Editor"
            },
            {
                "route": {"name": "EditorSettings", "slug": this.$route.params.slug},
                "label": "Opções"
            },
            {
                "route": {"name": "EditorShare", "slug": this.$route.params.slug},
                "label": "Compartilhar"
            },
            {
                "route": {"name": "EditorAnswers", "slug": this.$route.params.slug},
                "label": "Respostas"
            }
        
          ]

        };

        // Current Route
        this.setCurrentRouteAlias()


    },

    methods: {

        autosave(){
            if(this.nav == "Editor") this.updateForm(this.$parent.form)            
        },

        setCurrentRouteAlias(){
            if(this.$route.name == 'Editor' || this.$route.name == 'EditorAnswers'){
                this.currentRoute = 'Editor';
            }
        },

        // logout(){
        //     axios        
        //         .post(process.env.VUE_APP_API_URL + 'auth/logout')
        //         .then(response => {
        //             if(response.data.message === "Successfully logged out"){
        //                 localStorage.removeItem("access_token");
        //                 localStorage.removeItem("login_at");
        //                 this.$router.push({ name: 'Login' });                    
        //             }
        //         })  
        // }
    }
}
</script>

<style scoped lang="sass">
.top-header
    align-items: center
    display: flex
    justify-content: space-between
    height: 60px
    min-height: 60px
    background: $cWhite
    border-bottom: 1px solid $cG2
    padding: 0 $mgSm

    .actions,
    .top-nav,
    .logo-container
        flex: 1

    .default-logo
        display: flex
        align-items: center

    .user-menu
        display: flex;
        align-items: center
        display: flex
        justify-content: space-between
        height: 60px
        min-height: 60px
        background: $cWhite
        border-bottom: 1px solid $cG2
        padding: 0 $mgSm

    .logo-container
        display: flex
        align-items: center

        .team-name
            font-size: $fzXs
            font-style: italic
            color: $cG5

    .logo
        max-width: 110px

    .settings-button
        font-size: 22px
        color: $cG5
        cursor: pointer
        padding: 6px
        border-radius: 3px

        &:hover
            color: $cPrimary
            background: rgba($cPrimary, .15)
    
    .plan-badge
        user-select: none
        padding: $mgXxs
        border-radius: 4px
        background: #FBC02D
        font-size: 11px
        color: #fff
        display: inline-block
        height: 18px

.top-nav
    display: inline-flex
    font-size: $fzXs
    justify-content: center

    .nav-item
        margin-right: 20px
        padding-bottom: 8px

        &.router-link-active
            border-bottom: 2px solid $cBody

        &:last-of-type
            margin: 0

.actions
    display: flex
    align-items: center
    justify-content: flex-end

    .user-menu
        display: flex

    .btn-container
        margin-right: $mgSm

        &:last-of-type
            margin-right: 0

.avatar
    width: 30px
    height: 30px
    overflow: hidden
    border-radius: 30px

    &.default
        background: $cPrimary
        color: #fff
        display: flex;
        justify-content: center
        align-items: center
        font-size: $fzXs

</style>