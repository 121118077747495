<template>
    <div class="question-title" :data-size="size">
        {{getValueField(value)}}
    </div>
</template>

<script>
    import { UtilitiesMixin } from '@/mixins/UtilitiesMixin.js';

    export default {
        name: "QuestionTitle",
        mixins: [UtilitiesMixin],
        props: {
            value: String,
            variables: Object,
            size: String,
        },
        data() {
            return {
                title: null
            }
        },
        methods: {
            replaceVariables (str, replaceList) {

                if(!str || !replaceList) return null

                const result = str.split(/\{\{|\}\}/).map((item, index) => {
                        if(index % 2 === 0) return item
                        if(!replaceList[item]) return "____"
                        return this.formatAnswerText({value: replaceList[item], field_type: this.getMentionType(item)})
                    })

                return result.join('')

            },

            getValueField(value) {
                return this.replaceVariables(value, this.variables)
            },
            getMentionType(slug){
                const index = this.$parent.form.fields.findIndex(item => item.slug === slug)
                if(index < 0) return null
                return this.$parent.form.fields[index].type
            }
        },
    }
</script>

<style lang="sass" scoped>
.question-title
    white-space: pre-line
    margin-top: 0
    line-height: 120%

    &[data-size="lg"]
        font-size: $fzXl
        font-weight: bold


</style>